import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import styles from "./CustomTabButton.module.css"; // Import the CSS module

const CustomTabButton = ({ variant, children, ...props }) => {
  const { t } = useTranslation();
  const customClass = variant ? styles[`${variant}Custom`] : "";

  return (
    <Button className={`${styles.CustomTabButton} ${customClass}`} {...props}>
      {children}
    </Button>
  );
};

export const CustomImgButton = ({
  variant,
  onClick,
  src,
  alt,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  // Determine the custom button class based on the variant prop
  const customClass = variant ? styles[`${variant}Custom`] : "";

  return (
    <Button
      variant={variant}
      onClick={onClick}
      {...props}
      className={styles.imageButton}
    >
      {src && <img src={src} alt={alt} />}
      {children}
    </Button>
  );
};

export const CustomButton = ({
  variant,
  onClick,
  opacity,
  children,
  ...props
}) => {
  return (
    <Button
      className={styles[variant]}
      {...props}
      style={{ opacity: opacity }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default CustomTabButton;
