import styles from "./SignUp.module.css";
import styleVerify from "./Verification.module.css";
import logoImage from "../Assests/Images/logo.png";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader/Loader";
import { IoIosArrowRoundBack } from "react-icons/io";

function VerificationDone() {
    const { handleSubmit, reset, formState, control } = useForm({
        mode: "onChange",
    });

    const navigate = useNavigate();
    const { t } = useTranslation();


    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className={styles.bgSection}>
            {isLoading && <Loader />}
            <div className={styles.verifySection}>
                <div className={styles.logoImg}>
                    <img src={logoImage}></img>
                </div>

                <div className={styles.loginForm}>
                    <div className={styles.verifyHeading}>
                        <h1>
                            {t("VERIFIY")}
                        </h1>
                        <p>{t("DONE_VERIFICATION")}</p>
                    </div>
                    <div className={styles.loginBtn}>
                        <button onClick={() => {
                            navigate("/login");
                        }}>
                            <p>{t("LOGIN_NOW")}</p>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default VerificationDone;
