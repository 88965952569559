import twitter from "../../Assests/Images/Shape.svg";
import axios from "axios";
import Loader from "../../Common/Loader/Loader";
import { useState } from "react";

//---------function for twitter social api call----------
function TwitterLogin({ twitterLoginFrom }) {
  const [isLoading, setIsLoading] = useState(false);

  //======required common header for each api calling========
  const commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };
  const handleTwitterAuth = async () => {
    setIsLoading(true);
    try {
      const requestTokenResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/brand/twitterRequestToken`,
        { headers: commonHeaders }
      );
      const oauthToken = requestTokenResponse.data.data.oauthToken;
      const oauth_token_secret =
        requestTokenResponse.data.data.oauthTokenSecret;
      localStorage.setItem("oauth_token_secret", oauth_token_secret);

      localStorage.setItem("twitterLoginFrom", twitterLoginFrom);
      // Redirect user to Twitter for authentication
      window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}`;
    } catch (error) {
      console.error("Error obtaining request token:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="socialButton">
        <button onClick={handleTwitterAuth}>
          <img src={twitter} alt="Twitter Login" />
        </button>
      </div>
    </>
  );
}
export default TwitterLogin;
