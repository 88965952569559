import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect } from "react";
import GoogleLogo from "../../Assests/Images/GoogleLogo.svg";
import { setAppLogo, socialSignup } from "../../redux/slices/userSlice";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Common/Toster/Toster";
import { CONSTANT } from "../../Utils/Constant";
import { STATUS_MSG } from "../../Utils/StatusMsg";

//---------function for google social api call----------
function GoogleLogin() {
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Social Login with google
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          responseGoogle(res.data);
        })
        .catch((err) => err);
    }
  }, [user]);

  // Social Login with Google
  const responseGoogle = async (response) => {
    let userData = response;

    if (userData) {
      let requestData = new FormData();
      requestData.append("email", userData.email ? userData.email.trim() : "");
      requestData.append("socialId", userData.id ? userData.id.trim() : "");
      requestData.append("socialType", CONSTANT.GOOGLE);
      requestData.append("clubName", userData.name ? userData.name.trim() : "");
      dispatch(socialSignup(requestData)).then((signresponsejson) => {
        const response1 = signresponsejson.payload;
        const ResponseCode = STATUS_MSG[response1?.data?.code];
        if (response1 && response1.code === STATUS_CODE.SUCCESS) {
          navigate("/");
        } else if (response1?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response1?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      });
    }
  };

  return (
    <>
      <div>
        <img src={GoogleLogo} alt="google" onClick={() => login()} />
      </div>
    </>
  );
}
export default GoogleLogin;
