import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import { Col, Row } from "react-bootstrap";
import { GRIS_DATA } from "../../Utils/MockData";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Toster from "../../Common/Toster/Toster";
import TrainzaApi from "../../Helpers/Api";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Loader from "../../Common/Loader/Loader";

// import moment from "moment-timezone";

function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();

  const [dashboard, setDashboard] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timeZoneCountryMapping = {
  //     "Asia/Calcutta": {
  //       country: "IN",
  //       code: "91",
  //     },
  //     "America/New_York": "US",
  //     "Europe/London": "GB",
  //     "Asia/Tokyo": "JP",
  //     "Australia/Sydney": "AU",
  //     // Add more mappings as needed
  //   };

  //   const timeZone = moment.tz.guess();

  //   const countryCode = timeZoneCountryMapping[timeZone];
  // }, []);

  // const detectOperatingSystem = () => {
  //   const userAgent = window.navigator.userAgent.toLowerCase();

  //   if (userAgent.includes("windows")) {
  //     return "Windows";
  //   } else if (userAgent.includes("linux")) {
  //     return "Linux";
  //   } else if (userAgent.includes("mac")) {
  //     return "Mac OS";
  //   } else if (userAgent.includes("ios")) {
  //     return "iOS";
  //   } else if (userAgent.includes("android")) {
  //     return "Android";
  //   } else {
  //     return "Unknown";
  //   }
  // };

  // // Example usage:
  // const operatingSystem = detectOperatingSystem();
  // console.log(`Operating System: ${operatingSystem}`);

  const dashboardData = () => {
    const response = TrainzaApi.getDashboardData(isAuthenticated);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setDashboard(response?.data);
      }
    });
  };

  useEffect(() => {
    dashboardData();
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      <p className={styles.heading}>{t("WELCOME")}</p>
      <div className={styles.devider}></div>
      <Row>
        <Col lg={4} md={4} sm={4} sx={6}>
          <div
            className={styles.gridView}
            onClick={() => {
              navigate("/members");
            }}
          >
            <p>MEMBERS</p>
            <h1>{dashboard?.memberCount ? dashboard?.memberCount : 0}</h1>
          </div>
        </Col>

        <Col lg={4} md={4} sm={4} sx={6}>
          <div
            className={styles.gridView}
            onClick={() => {
              navigate("/groups");
            }}
          >
            <p>GROUPS</p>
            <h1>{dashboard?.groupCount ? dashboard?.groupCount : 0}</h1>
          </div>
        </Col>

        <Col lg={4} md={4} sm={4} sx={6}>
          <div
            className={styles.gridView}
            onClick={() => {
              navigate("/training", { state: { isWorkout: true } });
            }}
          >
            <p>WORKOUTS</p>
            <h1>{dashboard?.workoutCount ? dashboard?.workoutCount : 0}</h1>
          </div>
        </Col>

        <Col lg={4} md={4} sm={4} sx={6}>
          <div
            className={styles.gridView}
            onClick={() => {
              navigate("/news");
            }}
          >
            <p>NEWS</p>
            <h1>{dashboard?.newsCount ? dashboard?.newsCount : 0}</h1>
          </div>
        </Col>

        <Col lg={4} md={4} sm={4} sx={6}>
          <div
            className={styles.gridView}
            onClick={() => {
              navigate("/events");
            }}
          >
            <p>EVENTS</p>
            <h1>{dashboard?.eventCount ? dashboard?.eventCount : 0}</h1>
          </div>
        </Col>

        <Col lg={4} md={4} sm={4} sx={6}>
          <div className={styles.gridView}>
            <p>RESULTS</p>
            <h1>{dashboard?.resultCount ? dashboard?.resultCount : 0}</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <div
            className={styles.addThings}
            onClick={() => {
              navigate("/news", { state: { isAdded: true } });
            }}
          >
            <p>{t("ADD_NEWS")}</p>
          </div>
        </Col>
        <Col lg={4}>
          <div
            className={styles.addThings}
            onClick={() => {
              navigate("/events", { state: { isAdded: true } });
            }}
          >
            <p>{t("ADD_EVENT")}</p>
          </div>
        </Col>
        <Col lg={4}>
          <div
            className={styles.addThings}
            onClick={() => {
              navigate("/members", { state: { isAdded: true } });
            }}
          >
            <p>{t("ADD_MEMBER")}</p>
          </div>
        </Col>
      </Row>

      <h1 className={styles.limit}>
        {dashboard?.memberLimit ? dashboard?.memberLimit : 0}{" "}
        {t("MEMBER_LIMIT")}
      </h1>
      <p className={styles.upgrade}>{t("UPGRADE_CANCEL")}</p>
      <p className={styles.manage}>{t("MANAGE_SUBSCRIPTION_TO_MANAGE")}. </p>
      <button
        className={styles.subscribeBtn}
        onClick={() => window.open("http://www.trainza.co.za/")}
      >
        <p>
          {t("MANAGE")} <span>{t("SUBSCRIPTION")}</span>
        </p>
      </button>
    </div>
  );
}

export default Dashboard;
