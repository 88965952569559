import React, { useEffect, useRef, useState } from "react";
import styles from "./AddWorkout.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import SelectImg from "../../../Assests/Images/SelectImg.svg";
import { TraningCustomSelect } from "../../../ReactBootstrap/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import TrainzaApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Common/Toster/Toster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiVideoFill } from "react-icons/ri";
import AddWorkout from "../WorkOutTab/AddWorkout";
import { CONSTANT } from "../../../Utils/Constant";
import DOMPurify from "dompurify";
import AddWorkoutManually from "./WorkoutManually/AddWorkoutManually";
import Loader from "../../../Common/Loader/Loader";

function WorkoutRequired({
  workoutList,
  setWorkoutType,
  workoutType,
  handleWorkout,
  workOutId,
  setWorkOutId,
  trainingObj,
  workoutDetail,
  setWorkoutDetail,
  selectedImage,
  setSelectedImage,
  setIsAddManually,
  idForUpdate,
  setIdForUpdate,
  setRemovedImg,
  removedImg,
  whichScreen,
  setWhichScreen,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const { t } = useTranslation();
  const imageInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [addManually, setAddManually] = useState(false);

  const [workoutTypeList, setWorkoutTypeList] = useState([]);

  const [workoutTypeName, setWorkoutTypeName] = useState("");

  //   function for handle validation
  const { register, formState, control, setValue } = useForm({
    mode: "onChange",
  });

  //===== function for calling workout type list api====
  const handleWorkoutDetail = (id) => {
    const response = TrainzaApi.getWorkoutDetail(isAuthenticated, id);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setWorkoutDetail(response?.data);
        const predefault_Image = response?.data?.images?.map(
          (item) => item.image
        );
        setSelectedImage(predefault_Image);
      }
    });
  };

  // Function for showing workout detail
  const handlePlusClick = () => {
    setWhichScreen(CONSTANT.GET_DETAIL);
  };

  useEffect(() => {
    setValue("WORKOUT_TYPES", workOutId);
  }, [workOutId]);

  useEffect(() => {
    if (trainingObj) {
      handleWorkoutDetail(trainingObj?.workoutId);
    }
  }, [trainingObj]);

  //===== function for calling workout type list api====
  const handleWorkoutTypeList = (search) => {
    const response = TrainzaApi.getWorkoutTypeList(isAuthenticated, search);
    response.then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setWorkoutTypeList(response?.data?.workoutTypeList);

        const result = response?.data?.workoutTypeList?.find(
          (item) => item.id === workoutDetail?.typeId
        );
        const name = result ? result.name : null;

        setWorkoutTypeName(name);
      }
    });
  };

  useEffect(() => {
    handleWorkoutTypeList("");
  }, [workoutDetail]);

  useEffect(() => {
    if (idForUpdate) {
      setWhichScreen(CONSTANT.GET_DETAIL);
    }
  }, [idForUpdate]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.workoutHeader}>
        <div className={styles.partName}>
          <p>2</p>
        </div>
        <h1>
          {t("WORKOUT ")}
          <span>({t("REQUIRED")})</span>
        </h1>
      </div>

      {whichScreen === CONSTANT.SELECT_THROUGH_OPTION ? (
        <div className={styles.savedWorkoutBox} style={{ marginTop: "-10px" }}>
          <div className={styles.selectWorkout}>
            <TraningCustomSelect
              Label={
                <p>
                  {t("SAVED")} <span>{t("WORKOUT")}</span>
                </p>
              }
              defaultKey="Select Saved Workout"
              styleName="selectTraning"
              RegisterName={"WORKOUT_TYPES"}
              defaultValue=""
              register={register}
              formState={formState}
              onChangeHandler={(e) => {
                handleWorkout(e);
                handleWorkoutDetail(e.target.value);
                setWorkOutId(e.target.value);
              }}
              arrayData={workoutList}
              disabled={workoutList?.length === 0}
            ></TraningCustomSelect>

            <div
              className={styles.addBtnBox}
              onClick={() => {
                if (workoutType) {
                  handlePlusClick();
                  // setIsAddManually(true);
                }
              }}
              style={{ cursor: "pointer", opacity: workoutType ? "" : "25%" }}
            >
              <div className={styles.iconHolder}>
                <FaPlus color="#ff4300" />
              </div>
            </div>
            <img src={SelectImg} alt="SelectImg"></img>
          </div>

          <div className={styles.workoutNoteThat}>
            <p>
              {t("QUICKLY_ADD_IN_A")} <span>{t("SAVED_WORKOUT_ABOVE")}</span> or{" "}
              <span>{t("ADD_MANUALLY_BELOW")}</span>
            </p>
          </div>

          <button
            type="button"
            onClick={() => {
              setAddManually(true);
              setWhichScreen(CONSTANT.ADD_MANUALLY);
              setWorkoutDetail([]);
              setIsAddManually(true);
            }}
          >
            <p>
              {t("ADD")} <span>{t("MANUALLY")}</span>
            </p>
          </button>
        </div>
      ) : whichScreen === CONSTANT.GET_DETAIL ? (
        <div style={{ marginTop: "-10px" }}>
          <div className={styles.savedWorkoutBoxManually}>
            <div className={styles.workoutDetail}>
              <h1>{workoutTypeName}</h1>
              <div
                className={styles.textEditorText}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(workoutDetail.details),
                }}
              ></div>
              {/* <p>{stripHtmlTags(workoutDetail.details)}</p> */}
            </div>
            <div className={styles.detailImage}>
              {workoutDetail.images?.map((imageObj) =>
                imageObj.image ? (
                  <img
                    key={imageObj.id}
                    src={imageObj.image}
                    alt="workout Image"
                  />
                ) : (
                  ""
                )
              )}
            </div>

            {workoutDetail?.videoLink ? (
              <React.Fragment>
                <div className={styles.devider}></div>
                <div
                  className={styles.vedioShow}
                  onClick={() => {
                    if (workoutDetail?.videoLink) {
                      window.open(workoutDetail?.videoLink, "_blank");
                    }
                  }}
                >
                  <RiVideoFill
                    size={30}
                    style={{
                      cursor: "pointer",
                      marginLeft: "34px",
                      marginTop: "14px",
                    }}
                  />
                  <p>{t("VIEW")}</p>
                  <h1>{t("VIDEO")}</h1>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
          <div className={styles.cancelDeleteSave}>
            <div className={styles.cancelSave}>
              <button
                type="button"
                onClick={() => {
                  setWhichScreen(CONSTANT.SELECT_THROUGH_OPTION);
                  setWorkOutId("");
                  setWorkoutType("");
                  setValue("WORKOUT_TYPES", "");
                  setWorkoutDetail([]);
                  setIsAddManually(false);
                }}
              >
                {t("REMOVE")}
              </button>
            </div>
            <button
              className={styles.saveWorkout}
              type="button"
              onClick={() => {
                setWhichScreen(CONSTANT.ADD_MANUALLY);
                setIsAddManually(true);
              }}
            >
              <p>
                {t("EDIT")} <span>{t("WORKOUT")}</span>
              </p>
            </button>
          </div>
        </div>
      ) : whichScreen === CONSTANT.ADD_MANUALLY ? (
        <AddWorkoutManually
          // workOutId={workOutId}
          setWorkOutId={setWorkOutId}
          setWorkoutType123={setWorkoutType}
          addManually={addManually}
          setAddManually={setAddManually}
          workoutList123={workoutList}
          setWhichScreen={setWhichScreen}
          handleWorkoutDetailToShow={handleWorkoutDetail}
          workoutDetail={workoutDetail}
          setWorkoutDetail={setWorkoutDetail}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          whichScreen={whichScreen}
          setIsAddManually={setIsAddManually}
          setWorkoutList={setWorkoutTypeList}
          workoutList={workoutTypeList}
          setRemovedImg={setRemovedImg}
          removedImg={removedImg}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default WorkoutRequired;
