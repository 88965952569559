import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "../AppComponent/Dashboard/Dashboard";
import CommonLayout from "../Common/CommonLayout/CommonLayout";
import SignUp from "../AuthComponent/SignUp";
import MembersPage from "../AppComponent/Members/MembersPage";
import MemberDetailLayout from "../AppComponent/Members/MemberDetail/MemberDetailLayout";
import GroupLayout from "../AppComponent/Groups/GroupLayout";
import Login from "../AuthComponent/Login";
import TrainingLayout from "../AppComponent/Training/TrainingLayout";
import { useSelector } from "react-redux";
import Verification from "../AuthComponent/Verification";
import BrandLayout from "../AppComponent/Brand/BrandLayout";
import SaveChangesModal from "../AppComponent/Brand/Branding/SaveChangesModal";
import EventLayout from "../AppComponent/Event/EventLayout/EventLayout";
import AddEvent from "../AppComponent/Event/AddEvent/AddEvent";
import EventDetailLayout from "../AppComponent/Event/EventDetail/EventDetailLayout/EventDetailLayout";
import RoutesPage from "../AppComponent/Routes/RoutesPage";
import NewsLayout from "../AppComponent/News/NewsLayout/NewsLayout";
import AddNews from "../AppComponent/News/AddNews/AddNews";
import NewsDetailLayout from "../AppComponent/News/NewsDetail/NewsDetailLayout/NewsDetailLayout";
import AddWorkoutType from "../AppComponent/Training/TrainingTab/AddWorkoutType";
import TrainingTypes from "../AppComponent/Training/TrainingTab/TrainingTypes";
import AddWorkout from "../AppComponent/Training/WorkOutTab/AddWorkout";
import AddTrainingDateTime from "../AppComponent/Training/TrainingTab/AddTrainingDateTime";
import ForgotPassword from "../AuthComponent/ForgotPassword";
import VerificationDone from "../AuthComponent/VerificationDone";
import Toster from "../Common/Toster/Toster";
import { STATUS_CODE } from "../Utils/StatusCode";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CONSTANT } from "../Utils/Constant";
import { socialSignup } from "../redux/slices/userSlice";
import { STATUS_MSG } from "../Utils/StatusMsg";
import WaitingComp from "../Common/WaitingComp/WaitingComp";

function TwitterAuthPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  //===== function for handle twitter login===
  const handleCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const denied = params.get("denied");
    if (denied) {
      if (localStorage.getItem("twitterLoginFrom") == "sign-up") {
        navigate("/login");
      } else {
        navigate("/sign-up");
      }
      return false;
    }
    const oauthToken = params.get("oauth_token");
    const oauthVerifier = params.get("oauth_verifier");
    const oauthTokenSecret = localStorage.getItem("oauth_token_secret");
    setLoader(true);
    const formData = new FormData();
    formData.append("oauthToken", oauthToken);
    formData.append("oauthVerifier", oauthVerifier);
    formData.append("oauthTokenSecret", oauthTokenSecret);

    if (oauthToken && oauthVerifier) {
      const commonHeaders = {
        accept: "application/json",
        "api-key": process.env.REACT_APP_API_KEY_PAIR,
        "device-token": localStorage.getItem(
          process.env.REACT_APP_DEVICE_TOKEN_KEY
        ),
        "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
        "device-type": process.env.REACT_APP_DEVICE_TYPE,
      };
      try {
        const accessTokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/brand/twitterAccessToken`,
          formData,
          { headers: commonHeaders }
        );
        setLoader(false);
        const userDataRes = accessTokenResponse.data;
        const userData = userDataRes.data.results;
        if (userData) {
          let requestData = new FormData();
          requestData.append(
            "email",
            userData.email_address ? userData.email_address : ""
          );
          requestData.append(
            "socialId",
            userData.user_id ? userData.user_id : ""
          );
          requestData.append("socialType", CONSTANT.TWITTER);
          requestData.append(
            "clubName",
            userData.screen_name ? userData.screen_name.trim() : ""
          );
          setLoader(true);
          dispatch(socialSignup(requestData)).then((signresponsejson) => {
            const response = signresponsejson.payload;
            const ResponseCode = STATUS_MSG[response?.data?.code];
            setLoader(false);
            if (response && response.code === STATUS_CODE.SUCCESS) {
              navigate("/dashboard");
            } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
              Toster(response?.data?.message, "error");
              navigate(`/${localStorage.getItem("twitterLoginFrom")}`);
            } else {
              navigate(`/${localStorage.getItem("twitterLoginFrom")}`);
              Toster(t(ResponseCode), "error");
            }
          });
        }
      } catch (error) {
        console.error("Error obtaining access token:", error);
      }
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);
  return <>{loader ? <WaitingComp /> : ""}</>;
}

export default function Router() {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <CommonLayout />
            ) : (
              <Navigate to="/login" replace={true} />
            )
          }
        >
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="members" element={<MembersPage />} />
          <Route
            path="members/member-detail"
            element={<MemberDetailLayout />}
          />
          <Route path="groups" element={<GroupLayout />} />
          <Route path="training" element={<TrainingLayout />} />
          <Route path="add-training" element={<AddTrainingDateTime />} />
          <Route path="events" element={<EventLayout />} />
          <Route path="events/events-update" element={<AddEvent />} />
          <Route path="events/event-detail" element={<EventDetailLayout />} />
          <Route path="brand" element={<BrandLayout />} />
          <Route path="routes" element={<RoutesPage />} />

          <Route path="news" element={<NewsLayout />} />
          <Route path="news/news-update" element={<AddNews />} />
          <Route path="news/news-detail" element={<NewsDetailLayout />} />
          <Route path="training-types" element={<TrainingTypes />} />
          <Route path="training/training-types" element={<TrainingTypes />} />
          <Route
            path="training/add-workout-type"
            element={<AddWorkoutType />}
          />
          <Route path="training/add-workout" element={<AddWorkout />} />
        </Route>

        {!isAuthenticated && <Route path="/login" element={<Login />} />}
        <Route path="*" element={<Navigate to="/dashboard" />} />

        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/verified" element={<VerificationDone />} />
        <Route path="/twitter-callback" element={<TwitterAuthPage />} />
      </Routes>
      <Routes></Routes>
      <SaveChangesModal />
    </React.Fragment>
  );
}
