import styles from "./TrainingTypes.module.css";
import CrossImg from "../../../Assests/Images/Cross.svg";
import EditImg from "../../../Assests/Images/Edit.svg";
import TrainzaApi from "../../../Helpers/Api";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import Toster from "../../../Common/Toster/Toster";
import Loader from "../../../Common/Loader/Loader";
import DeleteWorkoutTypes from "./DeleteWorkoutType";

function TrainingTypes({
  searchKeyword,
  setShowAddWorkoutType,
  setTrainingTypeId,
  setWorkOutType,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [workoutList, setWorkoutList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [workoutId, setWorkoutId] = useState();

  //===== function for calling workout type list api====
  const handleWorkoutTypeList = (search) => {
    const response = TrainzaApi.getWorkoutTypeList(isAuthenticated, search);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setWorkoutList(response?.data?.workoutTypeList);
        setTotalCount(response?.data?.count);
      }
    });
  };

  useEffect(() => {
    handleWorkoutTypeList(searchKeyword);
  }, [searchKeyword]);

  // Function to handle workout type selection
  const handleWorkoutTypeSelect = (id) => {
    setWorkoutId(id);
  };

  //  ====== function for delete workout types=====
  const handleDeleteWorkout = () => {
    const response = TrainzaApi.DeleteWorkoutType(isAuthenticated, workoutId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else if (response?.code === STATUS_CODE.SUCCESS) {
        Toster(t("WORKOUT_TYPE_DELETE_SUCCESSFULLY"), "success");
        handleWorkoutTypeList("");
        setShowDelete(false);
      } else if (response?.data.code === STATUS_CODE.VALIDATION_ERROR) {
        Toster(
          t(
            "THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDelete(false);
      } else if (
        response?.data.code ===
        STATUS_CODE.THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION
      ) {
        Toster(
          t(
            "THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDelete(false);
      } else {
        setShowDelete(false);
      }
    });
  };

  return (
    <div className={styles.typesWrapper}>
      {isLoading && <Loader />}
      {!isLoading && searchKeyword && workoutList.length === 0 && (
        <div className={styles.noDataFound}>
          <p>
            {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
          </p>
        </div>
      )}

      {workoutList?.map((item, index) => {
        return (
          <div
            className={styles.workoutSection}
            key={item.id}
            onClick={() => handleWorkoutTypeSelect(item.id)}
          >
            <p>
              {item.name} <span>({item.linked} linked) </span>
            </p>
            <div className={styles.iconHolder}>
              <img
                src={EditImg}
                alt="EditImg"
                onClick={() => {
                  setShowAddWorkoutType(true);
                  setTrainingTypeId(item.id);
                  setWorkOutType(item.name);
                }}
              ></img>
              <img
                src={CrossImg}
                alt="CrossImg"
                onClick={() => setShowDelete(true)}
              ></img>
            </div>
          </div>
        );
      })}

      {!isLoading && !searchKeyword && workoutList?.length === 0 && (
        <div className={styles.noRecordFound}>
          <p>
            {t("NO_WORKOUT_TYPE")} - {""}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowAddWorkoutType(true);
              }}
            >
              {t("ADD_TYPE")}
            </span>
          </p>
        </div>
      )}
      <div className={styles.btnBox}>
        <p>
          {totalCount}{" "}
          <span>
            {workoutList?.length > 1 ? t("WORKOUT_TYPES") : t("WORKOUT_TYPE")}{" "}
          </span>
        </p>
        <button
          onClick={() => {
            setShowAddWorkoutType(true);
          }}
        >
          <p>
            Add <span>Type</span>
          </p>
        </button>
      </div>
      <DeleteWorkoutTypes
        show={showDelete}
        setShowDelete={setShowDelete}
        handleWorkoutDelete={handleDeleteWorkout}
      />
    </div>
  );
}

export default TrainingTypes;
