// ScrollContext.js
import React, { createContext, useState, useContext } from 'react';

const ScrollContext = createContext();

export const useScroll = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
  const [lastEditIndex, setLastEditIndex] = useState(null);

  const scrollToEditedItem = (index) => {
    setLastEditIndex(index);
  };

  return (
    <ScrollContext.Provider value={{ lastEditIndex, scrollToEditedItem }}>
      {children}
    </ScrollContext.Provider>
  );
};
