import React, { useEffect, useState } from "react";
import styles from "./NewsLayout.module.css";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../../Assests/Images/PlusIcon.svg";
import PlusFillIcon from "../../../Assests/Images/PlusfillIcon.svg";
import CalenderActive from "../../../Assests/Images/CalenderActive.svg";
import CalenderInActive from "../../../Assests/Images/CalenderInactive.svg";

import CustomTabButton, {
  CustomImgButton,
} from "../../../ReactBootstrap/CustomTabButton";
import SearchBar from "../../../ReactBootstrap/SearchBar";
import AddNews from "../AddNews/AddNews";
import NewsEventList from "../NewsEventList/NewsEventList";
import NewsListByCalender from "../NewsEventList/NewsListByCalender";
import { useLocation } from "react-router-dom";

function NewsLayout() {
  const { t } = useTranslation();

  const location = useLocation();

  const isAdded = location?.state?.isAdded;

  useEffect(() => {
    if (isAdded) {
      setShowtab(3);
    }
  }, [isAdded]);

  const [showtab, setShowtab] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 3 ? t("NEWS_ADD") : t("NEWS")}
      </p>

      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => setShowtab(1)}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("NEWS_")}
          </CustomTabButton>
          {showtab === 2 ? (
            <div
              className={styles.calenderActive}
              onClick={() => setShowtab(2)}
            >
              <img
                src={CalenderActive}
                alt="calender"
                width="19px"
                height="19px"
              ></img>
            </div>
          ) : (
            <div
              className={styles.calenderInActive}
              onClick={() => setShowtab(2)}
            >
              <img
                src={CalenderInActive}
                alt="calender"
                width="19px"
                height="19px"
              ></img>
            </div>
          )}
          {showtab === 3 ? (
            <CustomImgButton
              onClick={() => {
                setShowtab(3);
                setSearchKeyword("");
              }}
              src={PlusFillIcon} // Replace with your icon path
              alt="PlusIcon"
            ></CustomImgButton>
          ) : (
            <CustomImgButton
              onClick={() => {
                setShowtab(3);
                setSearchKeyword("");
              }}
              src={PlusIcon} // Replace with your icon path
              alt="PlusIcon"
            ></CustomImgButton>
          )}
        </div>

        {showtab === 1 && (
          <SearchBar
            placeholder={t("SEARCH_")}
            setSearchKeyword={setSearchKeyword}
          ></SearchBar>
        )}
      </div>

      <div className={styles.devider}></div>

      {showtab === 1 ? (
        <NewsEventList
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          setShowtab={setShowtab}
        />
      ) : showtab === 2 ? (
        <NewsListByCalender setShowtab={setShowtab} />
      ) : showtab === 3 ? (
        <AddNews setShowtab={setShowtab} />
      ) : (
        ""
      )}
    </div>
  );
}

export default NewsLayout;
