import { useTranslation } from "react-i18next";
import styles from "./CustomColorHolder.module.css";

export const CustomColorHolder = ({ customColor, onClick }) => {
  return (
    <div
      className={styles.prefillColor}
      style={{ backgroundColor: customColor }}
      onClick={onClick}
    ></div>
  );
};

export const CustomColorHolderButton = ({
  Label,
  color,
  paragraph,
  bgColor,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.exBtn}>
      <div className={styles.btnLabel}>{Label}</div>
      <div className={styles.colorBtn}>
        <button style={{ backgroundColor: bgColor }} type="button">
          <p style={{ color: color }}>
            {t("COLOURED")}
            <span style={{ color: color }}>{t("TEXT")}</span>
          </p>
        </button>
      </div>
      <p className={styles.exPara}>{paragraph}</p>
    </div>
  );
};
