import { Col, Form, Row } from "react-bootstrap";
import styles from "../../WorkOutTab/Workout.module.css";
import { useTranslation } from "react-i18next";
import { useForm, useController } from "react-hook-form";
import { CustomInputField } from "../../../../ReactBootstrap/CustomInputField/CustomInputField";
import { CustomButton } from "../../../../ReactBootstrap/CustomTabButton";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomSelect } from "../../../../ReactBootstrap/CustomSelect/CustomSelect";
import { useEffect, useRef, useState } from "react";
import TrainzaApi from "../../../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../../Utils/StatusCode";
import Toster from "../../../../Common/Toster/Toster";
import ReactQuill from "react-quill";
import { RiVideoFill } from "react-icons/ri";
import { FaImage } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { STATUS_MSG } from "../../../../Utils/StatusMsg";
import Loader from "../../../../Common/Loader/Loader";
import CrossImg from "../../../../Assests/Images/Cross.svg";
import { CONSTANT } from "../../../../Utils/Constant";

function AddWorkoutManually({
  setShowAdd,
  workOutId,
  setWorkOutId,
  setWorkoutType123,
  addManually,
  setAddManually,
  workoutList123,
  setWhichScreen,
  handleWorkoutDetailToShow,
  workoutDetail,
  setWorkoutDetail,
  selectedImage,
  setSelectedImage,
  whichScreen,
  setIsAddManually,
  workoutList,
  setWorkoutList,
  setRemovedImg,
  removedImg,
  idForUpdate,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [workoutList, setWorkoutList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  //   const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [activeWorkout, setActiveWorkout] = useState(0);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [workoutTitle, setWorkoutTitle] = useState("");
  const [workoutType, setWorkoutType] = useState("");

  //   function for handle validation
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    trigger,
    watch,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  // =====  handle textarea=====
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "WORKOUT_DETAIL",
    control,
    rules: {
      required: {
        value: true,
        message: t("Please enter workout detail"),
      },
      validate: (value) => {
        const strippedValue = stripHtmlTags(value);
        return strippedValue !== "" || t("Please enter workout detail");
      },
    },
  });

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const stripHtmlTags = (input) => input?.replace(/(<([^>]+)>)/gi, "").trim();

  // Watch the input value to detect changes
  const watchedValue = watch("WORKOUT_DETAIL");

  useEffect(() => {
    // If the stripped content is empty, trigger validation
    if (stripHtmlTags(watchedValue) === "") {
      trigger("WORKOUT_DETAIL");
    }
  }, [watchedValue, trigger]);

  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((el) => {
      el.style.color = ""; // Remove any color styling
    });

    return doc.body.innerHTML;
  };

  const handleChange = (value, delta, source, editor) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
    if (source === "user") {
      const cleanContent = cleanPastedContent(sanitizedValue);
      onChange(cleanContent);
    } else {
      onChange(sanitizedValue);
    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const handleVideoSelect = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      setSelectedVideo(file);
    } else {
      setSelectedVideo(null);
    }
  };

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    const newImagesCount = (selectedImage?.length || 0) + files.length;
    if (newImagesCount > 3) {
      Toster("You cannot upload more than 3 images", "error");
      return;
    }
    const validImages = files.filter((file) => file.type.startsWith("image/"));

    setSelectedImage((prevImages) => {
      const updatedImages = prevImages
        ? [...prevImages, ...validImages]
        : [...validImages];
      return updatedImages.slice(0, 3);
    });

    const previewUrls = validImages.map((file) => URL.createObjectURL(file));

    setImagePreview((prevPreviews) => {
      const updatedPreviews = prevPreviews
        ? [...prevPreviews, ...previewUrls]
        : [...previewUrls];
      return updatedPreviews.slice(0, 3);
    });

    e.target.value = null;
  };

  function determineDataType(data) {
    if (typeof data === "string") {
      return "string";
    }

    if (
      data instanceof ArrayBuffer ||
      data instanceof Blob ||
      data instanceof Uint8Array
    ) {
      return "binary";
    }

    return "unknown";
  }

  const handleRemoveImage = (index, preview) => {
    const isBinary = determineDataType(preview);
    if (isBinary !== "binary") {
      setRemovedImg([...removedImg, preview]);
    }

    const newSelectedImages = selectedImage.filter((_, i) => i !== index);
    const newImagePreviews = imagePreview.filter((_, i) => i !== index);
    setSelectedImage(newSelectedImages);
    setImagePreview(newImagePreviews);
  };

  // =====function for select workout type======
  const handleWorkout = (e) => {
    setValue("WORKOUT_TYPES", e.target.value);
    setWorkoutType(e.target.value);
  };

  
  // ======  function for calling save workout =====
  const OnSubmits = (formdata) => {
    const Images_rescord = imagePreview?.map((item) => ({
      id: "",
      image: item,
    }));
    setWorkoutDetail({
      title: workoutTitle,
      typeId: workoutType,
      details: value,
      videoLink: formdata.ADD_VEDIO ? formdata.ADD_VEDIO : "",
      status: activeWorkout ? 1 : 0,
      images: Images_rescord,
    });
    setWhichScreen(CONSTANT.GET_DETAIL);
    setIsAddManually(false);
  };

  useEffect(() => {
    if (workoutDetail) {
      setWorkoutTitle(workoutDetail.title);
      setValue("WORKOUT_TITLE", workoutDetail?.title);

      setTimeout(() => {
        setValue("WORKOUT_TYPES", workoutDetail?.typeId);
      }, 1000);
      setWorkoutType(workoutDetail?.typeId);

      setValue("WORKOUT_DETAIL", workoutDetail?.details);

      setValue("ADD_VEDIO", workoutDetail?.videoLink);

      setActiveWorkout(workoutDetail?.status);

      const predefault_Image = workoutDetail?.images?.map((item) => item.image);

      setSelectedImage(predefault_Image);
      setImagePreview(predefault_Image);
    }
  }, [workoutDetail, whichScreen]);

  return (
    <div>
      {isLoading && <Loader />}
      <div
        className={styles.addForm}
        style={{ marginTop: "-10px", borderRadius: "0px 0px 4px 4px" }}
      >
        <Form onSubmit={handleSubmit(OnSubmits)}>
          <div className={styles.addSection}>
            <div style={{ marginTop: "-20px" }}>
              <CustomSelect
                Label={
                  <p>
                    {t("WORKOUT_TITLE_TEXT")} <span>{t("TYPE_")}</span>
                    {/* ({t("REQUIRED_")}) */}
                  </p>
                }
                styleName="selectOptionWorkout"
                RegisterName={"WORKOUT_TYPES"}
                register={register}
                formState={formState}
                onChangeHandler={handleWorkout}
                defaultKey="Select Workout Type"
                arrayData={workoutList}
                disabled={workoutList?.length === 0}
                defaultValue=""
              ></CustomSelect>
            </div>

            <Form.Group className={styles.formInput}>
              <Form.Label>
                <p>
                  {t("WORKOUT_TITLE_TEXT")} <span>{t("DETAILS")}</span>{" "}
                  {/* {t("LABEL_REQUIRED")} */}
                </p>
              </Form.Label>
              <ReactQuill
                theme="snow"
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                placeholder={t("DETAILS_OF_WORKOUT")}
                className="brandTextEditor"
                ref={ref}
                modules={modules}
                formats={formats}
              />
              <div className="validateError">
                {error && <p className="error-message">{error.message}</p>}
              </div>
            </Form.Group>

            <Form.Group className={styles.formInputVideo}>
              <Form.Label>
                <p>
                  <RiVideoFill
                    size={20}
                    style={{ cursor: "pointer" }}
                    // onClick={() => fileInputRef.current.click()}
                  />
                  <span>{t("VIDEO_")}</span>
                </p>
              </Form.Label>
              <h4>{t("ADD_OPTIONAL_VIDEO_FOR_YOUR_WORKOUT")}</h4>
              <div className={styles.videoUpload}>
                <input
                  type="file"
                  accept="video/*"
                  ref={fileInputRef}
                  onChange={handleVideoSelect}
                  style={{ display: "none" }}
                />
                {selectedVideo && (
                  <div className={styles.videoPreview}>
                    <span>{selectedVideo.name}</span>
                  </div>
                )}
              </div>
            </Form.Group>

            <CustomInputField
              styleName="inputTextForAddWork"
              type="text"
              PlaceHolder={t("ADD_VIDEO_LINK")}
              RegisterName={"ADD_VEDIO"}
              register={register}
              formState={formState}
            >
              <p>
                {" "}
                {t("LINK_TO")} {""} {""}
                <span style={{ fontWeight: "500" }}>{t("VIDEO_")}</span>{" "}
              </p>
            </CustomInputField>

            <Form.Group className={styles.formInputVideo}>
              <Form.Label>
                <p>
                  <FaImage
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => imageInputRef.current.click()}
                  />
                  <span>{t("IMAGE_UPLOAD")}</span>
                </p>
              </Form.Label>

              <h4>{t("ADD_OPTIONAL_IMAGE_FOR_YOUR_WORKOUT")}</h4>

              <div className={styles.imageUpload}>
                <input
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  onChange={handleImageSelect}
                  style={{ display: "none" }}
                  multiple
                />
                {selectedImage && selectedImage?.length > 0 && (
                  <>
                    <Row>
                      {imagePreview?.map((preview, index) => (
                        <Col lg={4} md={3} xs={12}>
                          <div key={index} className={styles.showImagePreview}>
                            <img
                              src={preview}
                              alt={`Selected ${index}`}
                              className={styles.workoutIMg}
                            />

                            <img
                              src={CrossImg}
                              alt="CrossImg"
                              className={styles.CrossImg}
                              onClick={() => handleRemoveImage(index, preview)}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </>
                )}

                <CustomButton
                  variant="footerBtnCustom"
                  type="button"
                  onClick={() => imageInputRef.current.click()}
                >
                  <p>
                    {t("UPLOAD_IMAGE")} <span>{t("IMAGE")}</span>
                  </p>
                </CustomButton>
              </div>
            </Form.Group>

            {/* <div className={styles.devider}></div> */}

            {/* <div className={styles.switchCase}>
              <div className={styles.switchCaseInfo}>
                <p>
                  {t("ACTIVE_INACTIVE")} <span>{t("WORKOUT")}</span>
                </p>
                <h3>{t("SWITCH_HEADING")}</h3>
              </div>

              <Form.Check
                type="switch"
                id="custom-switch"
                className={styles.switchButton}
                checked={activeWorkout}
                onChange={() => {
                  setActiveWorkout(!activeWorkout);
                }}
              />
            </div> */}

            {/* <div className={styles.devider}></div> */}
          </div>

          <div className={styles.addWorkoutBtn}>
            <div className={styles.bottomBtn}>
              <div style={{ display: "flex" }}>
                <CustomButton
                  variant="cancelBtn"
                  type="button"
                  onClick={() => {
                    if (workoutDetail?.typeId) {
                      setWhichScreen(CONSTANT.GET_DETAIL);
                      setIsAddManually(false);
                    } else {
                      setWhichScreen(CONSTANT.SELECT_THROUGH_OPTION);
                      setWorkoutDetail([]);
                      setImagePreview();
                      setSelectedImage();
                      setWorkOutId();
                      setWorkoutType();
                      setWorkoutType123();
                      setValue("WORKOUT_TYPES", "");
                      setIsAddManually(false);
                    }
                  }}
                >
                  {t("CENCEL")}
                </CustomButton>
                {workoutDetail?.typeId ? (
                  <button
                    className={styles.deleteButton}
                    onClick={() => {
                      setWhichScreen(CONSTANT.SELECT_THROUGH_OPTION);
                      setWorkoutDetail([]);
                      setImagePreview();
                      setSelectedImage();
                      setWorkOutId();
                      setWorkoutType();
                      setWorkoutType123();
                      setValue("WORKOUT_TYPES", "");
                      setIsAddManually(false);
                    }}
                  >
                    <RiDeleteBin6Line
                      size="20"
                      style={{ marginRight: "5px" }}
                      color="#000000"
                    />
                    <p> {t("DELETE")}</p>
                  </button>
                ) : (
                  ""
                )}
              </div>
              <CustomButton
                variant="footerBtnCustom"
                onClick={handleSubmit(OnSubmits)}
                type="submit"
              >
                <p>
                  {t("SAVE")} <span>{t("WORKOUT")}</span>
                </p>
              </CustomButton>{" "}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddWorkoutManually;
