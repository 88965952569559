export const MASKING = {
  93: "...-......", // Afghanistan
  355: "..-...-....", // Albania
  213: "...-...-....", // Algeria
  376: "...-...", // Andorra
  244: "...-...-...", // Angola
  54: "...-...-....", // Argentina
  374: "..-...-...", // Armenia
  61: "..-....-....", // Australia
  43: "...-...-....", // Austria
  994: "..-...-..-..", // Azerbaijan
  973: "....-....", // Bahrain
  880: "...-...-...", // Bangladesh
  375: "..-...-..-..", // Belarus
  32: "...-...-...", // Belgium
  501: "...-....", // Belize
  229: "..-...-...", // Benin
  975: "..-...-...", // Bhutan
  591: ".-...-....", // Bolivia
  387: "..-...-...", // Bosnia and Herzegovina
  267: "..-...-...", // Botswana
  55: "..-....-....", // Brazil
  673: "...-....", // Brunei
  359: "...-...-...", // Bulgaria
  226: "..-..-....", // Burkina Faso
  95: "..-...-...", // Myanmar (Burma)
  257: "..-..-....", // Burundi
  855: "..-...-...", // Cambodia
  237: "....-....", // Cameroon
  1: "...-...-....", // Canada
  238: "...-....", // Cape Verde
  236: "..-..-....", // Central African Republic
  235: "..-..-..-..", // Chad
  56: ".-....-....", // Chile
  86: "..-....-....", // China
  57: "...-...-....", // Colombia
  269: "..-.....", // Comoros
  243: "..-...-....", // Congo (DRC)
  242: "..-...-....", // Congo (Republic)
  682: "..-...", // Cook Islands
  506: "....-....", // Costa Rica
  385: "..-...-...", // Croatia
  53: "...-......", // Cuba
  357: "..-...-...", // Cyprus
  420: "...-...-...", // Czech Republic
  45: "..-..-..-..", // Denmark
  253: "..-..-..-..", // Djibouti
  "1-767": "...-....", // Dominica
  "1-809": "...-....", // Dominican Republic
  670: "...-....", // East Timor
  593: "..-...-....", // Ecuador
  20: "..-....-....", // Egypt
  503: "..-..-....", // El Salvador
  240: "..-...-....", // Equatorial Guinea
  291: "..-...-...", // Eritrea
  372: "....-....", // Estonia
  251: "..-...-....", // Ethiopia
  679: "..-.....", // Fiji
  358: "..-...-....", // Finland
  33: ".-..-..-..-..", // France
  241: "..-..-..-..", // Gabon
  220: "...-....", // Gambia
  995: "...-...-...", // Georgia
  49: "...-...-....", // Germany
  233: "..-...-....", // Ghana
  30: "..-...-....", // Greece
  299: "..-..-..", // Greenland
  502: ".-...-....", // Guatemala
  224: "..-...-...", // Guinea
  245: "..-...-...", // Guinea Bissau
  592: "...-....", // Guyana
  509: "..-..-....", // Haiti
  504: "....-....", // Honduras
  852: "....-....", // Hong Kong
  36: "..-...-...", // Hungary
  354: "...-....", // Iceland
  91: ".....-.....", // India
  62: "..-...-...", // Indonesia
  98: "...-...-....", // Iran
  964: "...-...-....", // Iraq
  353: "...-...-...", // Ireland
  972: "..-...-....", // Israel
  39: "...-...-....", // Italy
  225: "..-...-...", // Ivory Coast
  81: "..-....-....", // Japan
  962: "..-....-....", // Jordan
  7: "...-...-..-..", // Kazakhstan
  254: "..-......", // Kenya
  686: "..-...", // Kiribati
  383: "..-...-...", // Kosovo
  965: "....-....", // Kuwait
  996: "...-...-...", // Kyrgyzstan
  856: "..-...-...", // Laos
  371: "..-...-...", // Latvia
  961: "..-...-...", // Lebanon
  266: "..-...-...", // Lesotho
  231: "..-...-...", // Liberia
  218: "..-...-...", // Libya
  423: "...-....", // Liechtenstein
  370: "...-..-...", // Lithuania
  352: "...-...-...", // Luxembourg
  853: "....-....", // Macau
  389: "..-...-...", // Macedonia
  261: "..-..-.....", // Madagascar
  265: "...-...-...", // Malawi
  60: "..-...-...", // Malaysia
  960: "...-....", // Maldives
  223: "..-..-....", // Mali
  356: "....-....", // Malta
  692: "...-....", // Marshall Islands
  222: "..-..-....", // Mauritania
  230: "...-....", // Mauritius
  52: "...-...-....", // Mexico
  691: "...-....", // Micronesia
  373: "...-..-...", // Moldova
  377: "..-...-...", // Monaco
  976: "..-..-....", // Mongolia
  382: "..-...-...", // Montenegro
  212: "..-....-...", // Morocco
  258: "..-...-...", // Mozambique
  264: "..-...-...", // Namibia
  674: "...-....", // Nauru
  977: "..-...-...", // Nepal
  31: "..-...-....", // Netherlands
  687: "..-....", // New Caledonia
  64: "..-...-....", // New Zealand
  505: "....-....", // Nicaragua
  227: "..-..-....", // Niger
  234: "..-...-...", // Nigeria
  47: "...-..-...", // Norway
  968: "..-...-...", // Oman
  92: "...-.......", // Pakistan
  680: "...-....", // Palau
  970: "..-...-...", // Palestine
  507: "...-....", // Panama
  675: "...-....", // Papua New Guinea
  595: "...-...-...", // Paraguay
  51: "...-...-...", // Peru
  63: "..-...-....", // Philippines
  48: "..-...-...", // Poland
  351: "..-...-....", // Portugal
  974: "....-....", // Qatar
  242: "..-...-....", // Republic of the Congo
  40: "..-...-....", // Romania
  7: "...-...-..-..", // Russia
  250: "...-...-...", // Rwanda
  685: "..-...", // Samoa
  378: "..-....-....", // San Marino
  966: "..-...-....", // Saudi Arabia
  221: "..-...-....", // Senegal
  381: "..-...-....", // Serbia
  248: "...-....", // Seychelles
  232: "..-.....", // Sierra Leone
  65: "....-....", // Singapore
  421: "..-...-...", // Slovakia
  386: "..-...-...", // Slovenia
  677: "...-....", // Solomon Islands
  252: "..-...-...", // Somalia
  27: "..-...-....", // South Africa
  82: "..-...-....", // South Korea
  34: "...-...-...", // Spain
  94: "..-...-....", // Sri Lanka
  249: "..-...-...", // Sudan
  597: "...-....", // Suriname
  268: "..-..-....", // Swaziland
  46: "..-...-....", // Sweden
  41: "..-...-....", // Switzerland
  963: "..-...-....", // Syria
  886: "...-...-...", // Taiwan
  992: "..-...-....", // Tajikistan
  255: "..-...-...", // Tanzania
  66: "..-...-....", // Thailand
  228: "..-...-...", // Togo
  690: "...-....", // Tokelau
  676: "..-.....", // Tonga
  "1-868": "...-....", // Trinidad and Tobago
  216: "..-...-...", // Tunisia
  90: "...-...-....", // Turkey
  993: "..-...-....", // Turkmenistan
  688: ".....", // Tuvalu
  256: "...-...-...", // Uganda
  380: "..-...-....", // Ukraine
  971: "..-...-....", // United Arab Emirates
  44: "....-......", // United Kingdom
  1: "...-...-....", // United States
  598: "...-...-...", // Uruguay
  998: "..-...-....", // Uzbekistan
  678: "..-.....", // Vanuatu
  58: "...-...-....", // Venezuela
  84: "..-...-...", // Vietnam
  967: "..-...-...", // Yemen
  260: "..-...-...", // Zambia
  263: "..-...-...", // Zimbabwe
};
