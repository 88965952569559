import React, { useEffect, useRef, useState } from "react";
import styles from "./Training.module.css";
import CalenderComp from "../../Members/MemberDetail/CalenderComp";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { useTranslation } from "react-i18next";
import EditImg from "../../../Assests/Images/Edit.svg";
import CrossImg from "../../../Assests/Images/Cross.svg";
import AddTrainingDateTime from "./AddTrainingDateTime";
import TrainzaApi from "../../../Helpers/Api";
import { useSelector } from "react-redux";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Common/Toster/Toster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import DeleteWorkoutTypes from "./DeleteWorkoutType";
import { CONSTANT } from "../../../Utils/Constant";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../../Utils/MockData";
import { MONTH_CODE } from "../../../Utils/MonthCode";

function Training({
  addTraning,
  setAddTraning,
  searchKeyword,
  setTraningId,
  traningId,
  setIdForUpdate,
}) {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const previousRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nextRef = useRef();
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [traningList, setTraningList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [showDeleteTraning, setShowDeleteTraning] = useState(false);

  const [isDataPresent, setIsDataPresent] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  const currentDate = new Date();

  //===== handle time formating=====
  const formatTime = (time) => {
    if (!time) return "Anytime";
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setWeekday(DAYS_OF_WEEK[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(MONTH_OF_YEAR[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, [addTraning]);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };

  //===== function for calling workout  list api====
  const handleTrainingList = (search, trainingDate) => {
    const response = TrainzaApi.getTrainingList(
      isAuthenticated,
      search,
      trainingDate
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setTraningList(response?.data?.trainingList);

        if (!searchKeyword) {
          setIsDataPresent(response?.data?.count == 0);
        }

        setTotalCount(response?.data?.count);
      }
    });
  };

  useEffect(() => {
    if (selectedDate) {
      handleTrainingList(searchKeyword, selectedDate);
    }
  }, [searchKeyword, selectedDate]);

  //======   function for delete workout list======
  const handleDeleteTraning = () => {
    const response = TrainzaApi.deleteTraningList(isAuthenticated, traningId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else if (response?.code === STATUS_CODE.SUCCESS) {
        Toster(t("WORKOUT_DELETE_SUCCESSFULLY"), "success");
        handleTrainingList(searchKeyword, selectedDate);
        setShowDeleteTraning(false);
      } else if (
        response?.data.code ===
        STATUS_CODE.THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION
      ) {
        Toster(
          t(
            "THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDeleteTraning(false);
      } else {
        setShowDeleteTraning(false);
      }
    });
  };

  useEffect(() => {
    var daySelected = day;

    if (day.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }
    const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;
    if (day && year && month && weekday) {
      setSelectedDate(rightFormatOfDate);
    }
  }, [day, year, month, weekday]);

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && searchKeyword && traningList.length === 0 && (
        <div className={styles.noDataFound}>
          <p>
            {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
          </p>
        </div>
      )}

      {/* OVERALL NO DATA FOUND */}
      {!searchKeyword && isDataPresent ? (
        <React.Fragment>
          <div className={styles.noRecordFound}>
            <p>
              {t("CURRENTLY_NO_TRAINING")} -{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setAddTraning(true)}
              >
                {t("ADD_TRAINING_")}
              </span>
            </p>
          </div>
          <div className={styles.devider}></div>
        </React.Fragment>
      ) : (
        ""
      )}

      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div className={styles.iconHolder} onClick={callgoToPreviousMonth}>
            <FaLessThan size="15" color="#000000" />
          </div>
          <div className={styles.scheduleDate}>
            <p>
              <span>{weekday}</span> {day} {month} {year}
            </p>
          </div>
          <div className={styles.iconHolder} onClick={callgoToNextMonth}>
            <FaGreaterThan size="15" color="#000000" />
          </div>
        </div>
        <MdOutlineCalendarMonth
          size="24"
          color="#FFFFFF"
          onClick={() => handleShowDate()}
        />
      </div>

      <div className={styles.trainingLists}>
        {traningList?.map((training) => (
          <div key={training.id} className={styles.trainingDetailBox}>
            <div className={styles.trainingInfo}>
              <h1>
                {/* {training.isAnytime
                ? "Anytime"
                : formatTime(training.trainingTime)} */}

                {formatTime(training.trainingTime)}

                <span> - {training.workoutType}</span>
              </h1>
              <p>
                {training.routeName || "NO ROUTE"}{" "}
                {training.distance ? <>- {training.distance} </> : ""} {""}
                {training.distance
                  ? training.distanceUnit == 1
                    ? "KM"
                    : "MI"
                  : ""}
              </p>
              <h2>
                {training?.groups?.map((group, index) => (
                  <span key={index} className={styles.grpName}>
                    {group.name}
                    {index < training.groups.length - 1 && (
                      <span className={styles.separation}> | </span>
                    )}
                  </span>
                ))}
              </h2>
            </div>
            <div className={styles.trainingOption}>
              <img
                src={CrossImg}
                alt="crossimg"
                onClick={() => {
                  setShowDeleteTraning(true);
                  setTraningId(training.id);
                }}
              />
              <img
                src={EditImg}
                alt="EditImg"
                onClick={() => {
                  // setTraningId(training.id);
                  setAddTraning(true);
                  setIdForUpdate(training.id);
                  // setTrainingObj(training);
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.addWorkoutBox}>
        <p>
          {totalCount}{" "}
          <span>{traningList?.length > 1 ? t("Workouts") : t("Workout")} </span>
        </p>

        <CustomButton
          variant="footerBtnCustom"
          type="button"
          onClick={() => setAddTraning(true)}
        >
          <p>
            {t("ADD")}
            <span> {t("Training")}</span>
          </p>
        </CustomButton>
      </div>

      <CalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
        eventData={CONSTANT.ALLOW_PREVIOUS}
        selectedDate={selectedDate}
      />
      <DeleteWorkoutTypes
        show={showDeleteTraning}
        setShowDelete={setShowDeleteTraning}
        handleWorkoutDelete={handleDeleteTraning}
      />
    </div>
  );
}

export default Training;
