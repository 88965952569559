import React, { useEffect, useState } from "react";
import styles from "./EventLayout.module.css";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../../Assests/Images/PlusIcon.svg";
import PlusFillIcon from "../../../Assests/Images/PlusfillIcon.svg";
import CalenderActive from "../../../Assests/Images/CalenderActive.svg";
import CalenderInActive from "../../../Assests/Images/CalenderInactive.svg";

import CustomTabButton, {
  CustomImgButton,
} from "../../../ReactBootstrap/CustomTabButton";
import EventList from "../EventList/EventList";
import EventListByCalender from "../EventList/EventListByCalender";
import AddEvent from "../AddEvent/AddEvent";
import SearchBar from "../../../ReactBootstrap/SearchBar";
import { useLocation } from "react-router-dom";

function EventLayout() {
  const { t } = useTranslation();

  const location = useLocation();

  const isAdded = location?.state?.isAdded;

  useEffect(() => {
    if (isAdded) {
      setShowtab(3);
    }
  }, [isAdded]);

  const [showtab, setShowtab] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 3 ? t("RACES_ADD_EVENT") : t("REACES_EVENT")}
      </p>

      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => setShowtab(1)}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("Events")}
          </CustomTabButton>
          {showtab === 2 ? (
            <div
              className={styles.calenderActive}
              onClick={() => {
                setShowtab(2);
                setSearchKeyword("");
              }}
            >
              <img
                src={CalenderActive}
                alt="calender"
                width="19px"
                height="19px"
              ></img>
            </div>
          ) : (
            <div
              className={styles.calenderInActive}
              onClick={() => {
                setShowtab(2);
                setSearchKeyword("");
              }}
            >
              <img
                src={CalenderInActive}
                alt="calender"
                width="19px"
                height="19px"
              ></img>
            </div>
          )}
          {showtab === 3 ? (
            <CustomImgButton
              onClick={() => {
                setShowtab(3);
                setSearchKeyword("");
              }}
              src={PlusFillIcon} // Replace with your icon path
              alt="PlusIcon"
            ></CustomImgButton>
          ) : (
            <CustomImgButton
              onClick={() => {
                setShowtab(3);
                setSearchKeyword("");
              }}
              src={PlusIcon} 
              alt="PlusIcon"
            ></CustomImgButton>
          )}
        </div>

        {showtab === 1 && (
          <SearchBar
            placeholder={t("SEARCH_")}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          ></SearchBar>
        )}
      </div>

      <div className={styles.devider}></div>

      {showtab === 1 ? (
        <EventList
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          setShowtab={setShowtab}
        />
      ) : showtab === 2 ? (
        <EventListByCalender setShowtab={setShowtab} />
      ) : showtab === 3 ? (
        <AddEvent setShowtab={setShowtab} />
      ) : (
        ""
      )}
    </div>
  );
}

export default EventLayout;
