import "./Common/Style/Common.css";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import Router from "./Routes/Routes";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { ContextProvider } from "./Context/UserContext";
import { ScrollProvider } from "./Context/ScrollerContext";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ContextProvider>
          <ScrollProvider>
          <I18nextProvider i18n={i18n}>
            <Router />
          </I18nextProvider>
          </ScrollProvider>
        </ContextProvider>
      </BrowserRouter>
      <ToastContainer transition={Slide} />
    </React.Fragment>
  );
}

export default App;
