import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TrainzaApi from "../../Helpers/Api";

const initialState = {
  currentUser: {},
  userId: null,
  isLoading: false,
  userTokenTrainzaBrand: null,
  success: false,
  errorCode: null,
  error: null,
  login_email_brand: null,
  login_password_brand: null,
  remember_me_brand: null,
  open_modal: false,
  is_any_change: false,
  app_logo: null,
  signupType:null,
};

// Thunk for user login
export const userSignUp = createAsyncThunk(
  "user/userSignUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TrainzaApi.signUp(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for social signup
export const socialSignup = createAsyncThunk(
  "user/socialSignUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TrainzaApi.socialLogin(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TrainzaApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state) => {
      state.currentUser = {};
      state.isLoading = false;
      state.userTokenTrainzaBrand = null;
      state.success = false;
      state.signupType = null;
    },
    setUserToken: (state, action) => {
      state.userTokenTrainzaBrand = action.payload;
    },
    setLoginCredential: (state, action) => {
      state.login_email_brand = action.payload.email;
      state.login_password_brand = action.payload.password;
      state.remember_me_brand = action.payload.remember;
    },

    is_modal_open: (state) => {
      state.open_modal = true;
    },
    is_modal_close: (state) => {
      state.open_modal = false;
    },
    isAnyChange: (state, action) => {
      state.is_any_change = true;
    },
    setAppLogo: (state, action) => {
      state.app_logo = action.payload.logo;
    },
  },
  extraReducers: (builder) => {
    // user signup=========================================
    builder.addCase(userSignUp.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userSignUp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
    });

    builder.addCase(userSignUp.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user social  signup=========================================
    builder.addCase(socialSignup.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(socialSignup.fulfilled, (state, action) => {
      const response = action.payload;
      state.signupType = response?.data.signupType
      state.isLoading = false;
      state.userTokenTrainzaBrand = response?.data.token;
      state.app_logo = response?.data?.appLogo;
      state.success = true;
      state.userId = response?.data?.id;
    });

    builder.addCase(socialSignup.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user login=========================================
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      state.signupType = response?.data.signupType
      state.userTokenTrainzaBrand = response?.data.token;
      state.app_logo = response?.data?.appLogo;
      state.success = true;
      state.userId = response?.data?.id;
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  userLogoutClear,
  setUserToken,
  setLoginCredential,
  is_modal_close,
  is_modal_open,
  isAnyChange,
  setAppLogo,
} = userSlice.actions;
export default userSlice.reducer;
