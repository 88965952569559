import React, { useState } from "react";
import styles from "./NewsDetailLayout.module.css";
import { useTranslation } from "react-i18next";
import CustomTabButton from "../../../../ReactBootstrap/CustomTabButton";

import { useLocation } from "react-router-dom";
import NewsGallery from "../NewsGallery/NewsGallery";
import NewsPoll from "../NewsPoll/NewsPoll";
import NewsDetail from "../NewsDetail/NewsDetail";

function NewsDetailLayout() {
  const { t } = useTranslation();

  const location = useLocation();

  const eventId = location?.state?.event_id;
  const eventTitle = location?.state?.event_title;
  const eventImage = location?.state?.event_img;

  const [showtab, setShowtab] = useState(1);

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 1 ? t("NEWS_DETAIL") : showtab === 2 ? t("NEWS_GALLERY") : t("NEWS_POLL")}
      </p>
      <div className={styles.devider}></div>

      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => setShowtab(1)}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("DETAILS")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(2)}
            variant={showtab === 2 ? "primary" : "secondary"}
          >
            {t("GALLERY")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(3)}
            variant={showtab === 3 ? "primary" : "secondary"}
          >
            {t("POLL")}
          </CustomTabButton>
        </div>
      </div>
      <div className={styles.devider}></div>

      {showtab === 1 ? (
        <NewsDetail
          eventId={eventId}
          eventTitle={eventTitle}
          eventImage={eventImage}
        />
      ) : showtab === 2 ? (
        <NewsGallery
          eventId={eventId}
          eventTitle={eventTitle}
          eventImage={eventImage}
        />
      ) : showtab === 3 ? (
        <NewsPoll
          eventId={eventId}
          eventTitle={eventTitle}
          eventImage={eventImage}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default NewsDetailLayout;
