export const cleanData = (value) => {
  if (value === undefined || value === null) {
    return "";
  }
  return value;
};

export const removePlusSign = (value) => {
  // Ensure value is a string
  const str = typeof value === "number" ? value?.toString() : value;

  // Remove the plus sign if present
  return str.startsWith("+") ? str.slice(1) : str;
};

export function removeExtraPlus(inputString) {
  let newStr = "+" + inputString;
  return newStr.replace(/\++/g, "+");
}

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const b64toBlob = (cropData, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  var byteCharacters = window.atob(cropData); //decode string
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getRandom = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000;
};

export const transformTimeArray = (dataArray) => {
  return dataArray?.map((item) => {
    const hours = parseInt(item.hours) || 0;
    const mins = parseInt(item.minutes) || 0; // Rename minutes to mins
    const seconds = parseInt(item.seconds) || 0;

    const time = `${hours < 10 ? "0" : ""}${hours}:${
      mins < 10 ? "0" : ""
    }${mins}:${seconds < 10 ? "0" : ""}${seconds}`;

    const date = new Date(item.createdAt);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours24 = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const secs = ("0" + date.getSeconds()).slice(-2);
    const createdAt = `${year}-${month}-${day} ${hours24}:${minutes}:${secs}`;

    return {
      id: item.id,
      time,
      createdAt,
    };
  });
};

export const formatPhoneNumber = (number) => {
  const firstPart = number?.substring(0, 3);

  const secondPart = number?.substring(3, 6);

  const remainingPart = number?.substring(6);

  return `${firstPart}-${secondPart}-${remainingPart}`;
};

export const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date?.toLocaleDateString("en-GB", options);
};


export const formatTimeNews = (timeString) => {
  const time = new Date(`1970-01-01T${timeString}Z`);
  return time.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });
};



export const convertServerDataInOurArray = (data) => {
  return data?.map((item) => {
    const { distanceId, bestTime } = item;
    const [hours, minutes, seconds] = bestTime
      .split(":")
      .map((val) => parseInt(val));
    const createdAt = new Date().toISOString();
    return {
      id: distanceId,
      hours,
      createdAt,
      minutes,
      seconds: seconds || "0",
    };
  });
};

export const formatDateToTime = (dateString) => {
  const dateObj = new Date(dateString);

  const hours = dateObj.getHours().toString().padStart(2, "0");
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");
  const seconds = dateObj.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export const correctFormat = (inputDate) => {
  const padToTwoDigits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (regex.test(inputDate)) {
    return inputDate;
  } else {
    const parts = inputDate?.split("-");
    if (parts?.length === 3) {
      const [year, month, day] = parts;

      const formattedMonth = padToTwoDigits(month);
      const formattedDay = padToTwoDigits(day);

      return `${year}-${formattedMonth}-${formattedDay}`;
    } else {
      return "";
    }
  }
};

export const resetTimeToMidnight = (dateString) => {
  const originalDate = new Date(dateString);

  // Set the time part to midnight (00:00:00)
  const newDate = new Date(
    originalDate.getFullYear(),
    originalDate.getMonth(),
    originalDate.getDate(),
    0, // Hours
    0, // Minutes
    0 // Seconds
  );

  return newDate;
};

export const calculatePace = (time, distance) => {
  const [hours = 0, minutes = 0, seconds = 0] = time.split(":").map(Number);

  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

  const numericDistance = parseFloat(distance);

  const paceInSecondsPerKm = totalTimeInSeconds / numericDistance;

  var paceMinutes = Math.floor(paceInSecondsPerKm / 60);
  var paceSeconds = Math.round(paceInSecondsPerKm % 60);

  if (paceSeconds == 60) {
    paceSeconds = "00";
    paceMinutes = paceMinutes + 1;
  }

  const formattedPace = `${paceMinutes}:${String(paceSeconds).padStart(
    2,
    "0"
  )}`;

  return formattedPace;
};

export const formatTimeInCorrectFormat = (time) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const manipulatePlus = (digit) => {
  if (typeof digit !== "string") {
    digit = digit?.toString(); // Convert digit to string if it's not already
  }

  // Check if '+' is already present at the beginning of the string
  if (!digit?.startsWith("+")) {
    digit = `+${digit}`; // Prepend '+' if it's not already there
  }

  return digit;
};

export const modifySingularPlural = (baseString, conditionString) => {
  return baseString + (conditionString > 1 ? "s" : "");
};

export const getTotalResultCount = (eventResultList) => {
  let totalCount = 0;

  eventResultList.forEach((event) => {
    totalCount += event.results.length;
  });

  return totalCount;
};

export const getDayOfWeek = (dateString) => {
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const eventDate = new Date(dateString);
  const dayOfWeek = eventDate.getDay();
  return daysOfWeek[dayOfWeek];
};

export const formatTime = (isoString) => {
  // Parse the ISO 8601 string to a Date object
  const date = new Date(isoString);

  // Extract hours and minutes
  const hours = date.getUTCHours().toString().padStart(2, "0"); // Format hours to 2 digits
  const minutes = date.getUTCMinutes().toString().padStart(2, "0"); // Format minutes to 2 digits

  // Return time in hh:mm format
  return `${hours}:${minutes}`;
};

export const fetchImageAsFile = async (imageUrl, fileName = "image.jpg") => {
  const response = await fetch(imageUrl);

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

export const determineDataType = (data) => {
  if (typeof data === "string") {
    return "string";
  }

  if (
    data instanceof ArrayBuffer ||
    data instanceof Blob ||
    data instanceof Uint8Array
  ) {
    return "binary";
  }

  return "unknown";
};

export const removeLeadingZeros = (num) => {
  // Convert number to string
  const numStr = num?.toString();

  // Remove leading zeros if length > 2
  const cleanedStr = numStr?.length > 2 ? numStr?.replace(/^0+/, "") : numStr;

  // Convert back to number and return
  return Number(cleanedStr);
};

export const isValidDate = (dateString) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/; // Check format YYYY-MM-DD
  if (!dateString.match(regex)) return false;

  const date = new Date(dateString);
  const [year, month, day] = dateString.split("-").map(Number);

  return (
    date.getFullYear() === year &&
    date.getMonth() + 1 === month &&
    date.getDate() === day
  );
};
