import React from "react";
import ReactQuill from "react-quill";

function TextEditor({ placeholder, value, onChange, onBlur, ref, error }) {
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  // Function to clean up the HTML content
  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((el) => {
      el.style.color = ""; // Remove any color styling
    });

    return doc.body.innerHTML;
  };

  const handleChange = (content, delta, source, editor) => {
    if (source === "user") {
      const cleanContent = cleanPastedContent(content);
      onChange(cleanContent);
    } else {
      onChange(content);
    }
  };

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className="brandTextEditor"
        ref={ref}
        modules={modules}
        formats={formats}
      />
      <div className="validateError">
        {error && <p className="error-message">{error.message}</p>}
      </div>
    </div>
  );
}

export default TextEditor;
