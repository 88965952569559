import { useState } from "react";
import styles from "./Login.module.css";
import logoImage from "../Assests/Images/logo.png";
import Loader from "../Common/Loader/Loader";
import { CustomInputField } from "../ReactBootstrap/CustomInputField/CustomInputField";
import { MdOutlineMailOutline } from "react-icons/md";
import { useForm } from "react-hook-form";
import TrainzaApi from "../Helpers/Api";
import { STATUS_MSG } from "../Utils/StatusMsg";
import { STATUS_CODE } from "../Utils/StatusCode";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Toster from "../Common/Toster/Toster";
import { Form } from "react-bootstrap";

function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });

  // ========  function for calling forgot password api===========
  const onSubmit = async (formdata) => {
    setIsLoading(true);
    const PostData = new FormData();
    PostData.append("email", formdata.EMAIL);
    let res = TrainzaApi.forgotPassword(PostData);
    res.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        Toster("Password sent to your email account", "success");
        navigate("/login");
        reset();
      } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  return (
    <div>
      <div className={styles.bgSection}>
        {isLoading && <Loader />}
        <div className={styles.formSection}>
          <div className={styles.logoImg}>
            <img src={logoImage}></img>
          </div>

          <div className={styles.loginForm}>
            <div className={styles.heading}>
              <p>
                {t("PASSWORD_TEXT")}
                {""}
                <span> {t("REMINDER")} </span>{" "}
              </p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <CustomInputField
                styleName="signUpTabInput"
                type="type"
                PlaceHolder={t("EMAIL")}
                RegisterName="EMAIL"
                register={register}
                formState={formState}
                icon={<MdOutlineMailOutline style={{ top: "34px" }} />}
              ></CustomInputField>

              <div className={styles.loginBtn}>
                <button>
                  <p>{t("SUBMIT")}</p>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
