import Modal from "react-bootstrap/Modal";
import styles from "../LimitOrDeleteModal.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";

function MemberShipStatus({
  showModal,
  setShowModal,
  handleStatus,
  memberStatus,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                {t("CONFIRM")}{" "}
                <span>
                  {" "}
                  {memberStatus === 1
                    ? "inactive membership"
                    : "active membership"}
                </span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>
              Are you sure ? {}
              <span>
                want to{" "}
                {memberStatus === 1
                  ? "inactive membership"
                  : "active membership"}{" "}
                for this member?
              </span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F5F4F4" }}>
          <div className={styles.btnSection}>
            <CustomButton
              variant={"cancelBtn"}
              type="button"
              onClick={() => setShowModal(false)}
            >
              {t("CANCEL")}
            </CustomButton>

            <CustomButton
              variant="activeButton"
              type="button"
              onClick={handleStatus}
            >
              <p>
                {t("CONFIRM")}{" "}
                <span>
                  {" "}
                  {memberStatus === 1
                    ? "Inactive Membership"
                    : "Active Membership"}
                </span>
              </p>
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MemberShipStatus;
