import React, { useEffect, useState } from "react";
import styles from "./MembersPage.module.css";
import Person from "../../Assests/Images/Person.svg";
import Cross from "../../Assests/Images/Cross.svg";
import Edit from "../../Assests/Images/Edit.svg";
import LimitReached from "./LimitReached";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  convertServerDataInOurArray,
  formatDate,
  modifySingularPlural,
} from "../../Utils/CommonFunction";
import {
  CustomButton,
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import TrainzaApi from "../../Helpers/Api";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Common/Toster/Toster";
import Loader from "../../Common/Loader/Loader";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { CONSTANT } from "../../Utils/Constant";
import MemberShipStatus from "./MemberDetail/MemberStatusChange";

function Members({
  searchKeyword,
  isDataPresent,
  setIsDataPresent,
  memberLimit,
  setMemberLimit,
  setShowtab,
  totalCount,
  setTotalCount,
}) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const [memberList, setMemberList] = useState([]);
  const [showLimit, setShowLimit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [memberStatus, setMemberStatus] = useState();
  const [sorting, setSorting] = useState(CONSTANT.NAME);

  const handleCloseLimit = () => setShowLimit(false);

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
  }

  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const escapedKeyWord = escapeRegExp(keyWord);
      const parts = paragraph.split(new RegExp(`(${escapedKeyWord})`, "gi"));
      return parts.map((part, index) =>
        part.toLowerCase() === keyWord.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "15px",
              letterSpacing: "0.3px",
              background: "#CEF56E",
              margin: "0px",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <p style={{ margin: "0px" }}>{renderHighlightedParagraph()}</p>;
  }

  const handleGetMember = (page, search, sorting) => {
    const response = TrainzaApi.GetMember(
      isAuthenticated,
      page,
      search,
      sorting
    );

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (page == 1) {
          setMemberList(response?.data?.memberList);
        } else {
          // setMemberList([...memberList, ...response?.data?.memberList]);
          setMemberList((prevList) => [
            ...prevList,
            ...response?.data?.memberList,
          ]);
        }

        if (!search) {
          setIsDataPresent(response?.data?.count === 0);
        }
        setIsLoadMore(response?.data?.loadMore);
        setTotalCount(response?.data?.totalMember);
        setMemberLimit(response?.data?.memberLimit);
        setPageNo(page + 1);
      }
    });
  };

  useEffect(() => {
    if (!searchKeyword) {
      handleGetMember(1, "", sorting);
    }
  }, [searchKeyword, setShowtab, sorting]);

  useEffect(() => {
    if (searchKeyword) {
      handleGetMember(1, searchKeyword, sorting);
    }
  }, [searchKeyword, sorting]);

  function HandleChangeStatus(activeStatus) {
    const PostData = new FormData();

    PostData.append(
      "status",
      memberStatus ? CONSTANT?.INACTIVE : CONSTANT?.ACTIVE
    );

    setIsLoading(true);

    TrainzaApi.ActiveInActiveMember(isAuthenticated, selectedId, PostData).then(
      (response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            if (memberStatus == 0) {
              Toster(t("ACTIVE_MEMBERSHIP_STATUS"), "success");
            } else {
              Toster(t("INACTIVE_MEMBERSHIP_STATUS"), "success");
            }
            setShowModal(false);
            toggleMembershipActive(selectedId);
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  }

  const toggleMembershipActive = (id) => {
    const updatedMemberList = memberList.map((member) => {
      if (member.id === id) {
        return {
          ...member,
          isMembershipActive: member.isMembershipActive === 1 ? 0 : 1,
        };
      }
      return member;
    });
    setMemberList(updatedMemberList); // Update state with updatedMemberList
  };

  return (
    <div>
      {isLoading && <Loader />}

      <div className={styles.summary}>
        <div className={styles.personalDetail}>
          <p className={styles.personalDetailOff}>{t("LIST_BY")} -</p>
          <p
            className={
              sorting === 1 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.NAME);
            }}
          >
            {t("NAME")}
          </p>
          <p
            className={
              sorting === 2 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.SURNAME);
            }}
          >
            - {t("SURNAME")}
          </p>
          <p
            className={
              sorting === 3 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.AGE);
            }}
          >
            - {t("AGE")}
          </p>
          <p
            className={
              sorting === 4 ? styles.personalDetailOn : styles.personalDetailOff
            }
            onClick={() => {
              setSorting(CONSTANT.EXPIRY_DATE);
            }}
          >
            - {t("EXPIRY_DATE")}
          </p>
        </div>
        <h1 className={styles.memberLimit}>
          {t("MEMBER_LIMIT")} - {totalCount}/{memberLimit ? memberLimit : 0}
        </h1>
      </div>
      <div className={styles.devider}></div>
      {memberList?.map((info, index) => {
        return (
          <div
            className={styles.detail}
            key={index}
            style={{
              background:
                info?.isMembershipActive === CONSTANT.INACTIVE
                  ? CONSTANT.INACTIVE_COLOR
                  : "",
            }}
          >
            <div className={styles.info} key={index}>
              <img
                src={info?.profilePicture ? info.profilePicture : Person}
                alt="Person"
                onClick={() =>
                  navigate("/members/member-detail", {
                    state: { member_id: info.id, memberLimit: memberLimit },
                  })
                }
              ></img>
              <div className={styles.infoOfMember}>
                <p
                  className={styles.name}
                  onClick={() =>
                    navigate("/members/member-detail", {
                      state: {
                        member_id: info.id,
                        is_edit: true,
                        memberLimit: memberLimit,
                      },
                    })
                  }
                >
                  {" "}
                  <HighlightKeyInParagraph
                    keyWord={searchKeyword}
                    paragraph={`${capitalizeFirstLetter(
                      info?.firstName
                    )} ${capitalizeFirstLetter(
                      info?.lastName ? info?.lastName : ""
                    )}`}
                  />
                </p>
                <p className={styles.gender}>
                  {info.gender === 1
                    ? t("MALE")
                    : info.gender === 2
                    ? t("FEMALE")
                    : t("N/A")}{" "}
                  - {info.age ? info.age : 0}
                </p>
                <p className={styles.date}>
                  {formatDate(info.membershipValidUntil)}
                </p>
              </div>
            </div>
            <div className={styles.editCross}>
              <CustomImgButton
                variant="primary"
                // onClick={() => {
                //   HandleChangeStatus(info.isMembershipActive, info.id);
                // }}
                onClick={() => {
                  setSelectedId(info.id);
                  setShowModal(true);
                  setMemberStatus(info.isMembershipActive);
                }}
                src={Cross}
                alt="cross"
              ></CustomImgButton>
              <CustomImgButton
                variant="primary"
                src={Edit}
                alt="edit"
                onClick={() =>
                  navigate("/members/member-detail", {
                    state: {
                      member_id: info.id,
                      is_edit: true,
                      memberLimit: memberLimit,
                    },
                  })
                }
              ></CustomImgButton>
            </div>
          </div>
        );
      })}

      {/* MO RESULT FOUND FOR SEARCHING */}
      {searchKeyword && memberList?.length === 0 ? (
        <React.Fragment>
          <div className={styles.noDataFound}>
            <p>
              {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
            </p>
          </div>
          <div className={styles.devider}></div>
        </React.Fragment>
      ) : (
        ""
      )}

      {/* OVERALL NO DATA FOUND */}
      {!searchKeyword && isDataPresent ? (
        <React.Fragment>
          <div className={styles.noRecordFound}>
            <p>
              {t("CURRENTLY_NO_MEMBER")} -{" "}
              <span onClick={() => setShowtab(2)} style={{ cursor: "pointer" }}>
                {t("INVITE_MEMBER")}
              </span>
            </p>
          </div>
          <div className={styles.devider}></div>
        </React.Fragment>
      ) : (
        ""
      )}

      {/* INVITE AND LOAD MORE SECTION */}
      {!searchKeyword ? (
        <div className={styles.inviteormore}>
          <div className={styles.membersCount}>
            <CustomButton
              variant="footerBtnCustom"
              onClick={() => setShowtab(2)}
              // disabled={totalCount >= memberLimit}
            >
              {" "}
              <p>
                {t("INVITE")} <span>{t("MEMBER___")}</span>
              </p>
            </CustomButton>

            <p>
              {totalCount}{" "}
              <span>
                {modifySingularPlural("Member", memberList?.length)}
                {/* {memberList?.length > 1 ? t("Members") : t("Member")}{" "} */}
              </span>
            </p>
            <CustomButton
              variant="footerBtnCustom"
              onClick={() => {
                if (isLoadMore) {
                  handleGetMember(pageNo, searchKeyword, sorting);
                }
              }}
              opacity={isLoadMore ? "" : CONSTANT.OPACITY_RATIO}
            >
              {" "}
              <p>
                {t("LOAD")} <span>{t("MORE")}</span>
              </p>
            </CustomButton>
          </div>
        </div>
      ) : (
        ""
      )}

      <LimitReached handleClose={handleCloseLimit} show={showLimit} />

      <MemberShipStatus
        showModal={showModal}
        setShowModal={setShowModal}
        handleStatus={HandleChangeStatus}
        memberStatus={memberStatus}
      />
    </div>
  );
}

export default Members;
