import { useTranslation } from "react-i18next";
import styles from "./AddNews.module.css";
import { useController } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdTime } from "react-icons/io";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import CrossImg from "../../../Assests/Images/Cross.svg";
import Calender from "../../../Assests/Images/Calender.svg";
import bell from "../../../Assests/Images/bell.svg";
import TextEditor from "../../../Common/TextEditor/TextEditor";
import CropImg from "../../../Common/Crop/CropImg";
import { CONSTANT } from "../../../Utils/Constant";
import TrainzaApi from "../../../Helpers/Api";
import CalenderComp from "../../Members/MemberDetail/CalenderComp";
import { MONTH_CODE } from "../../../Utils/MonthCode";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import Toster from "../../../Common/Toster/Toster";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/Loader/Loader";
import {
  b64toBlob,
  cleanData,
  correctFormat,
  determineDataType,
  fetchImageAsFile,
  formatDate,
  formatDateToTime,
  formatTime,
  getRandom,
  isValidDate,
} from "../../../Utils/CommonFunction";
import mime from "mime";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteResult from "../../Members/MemberDetail/DeleteResult";
import PushNotification from "./PushNotification";

function AddNews({ setShowtab, eventId }) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    getValues,
    trigger,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      BRAND_DETAIL: "",
    },
  });

  const { t } = useTranslation();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "BRAND_DETAIL",
    control,
    rules: {
      required: {
        value: true,
        message: t("ENTER_CONTENT"),
      },
      validate: (value) => {
        const strippedValue = stripHtmlTags(value);
        return strippedValue !== "" || t("ENTER_EVENT_DETAIL");
      },
    },

    // rules: {
    //   required: t("ENTER_CONTENT"),
    //   validate: (value) => {
    //     if (value.length > 65535) {
    //       return t("CHARACTER_LIMIT_EXCEEDED");
    //     }
    //     return true;
    //   },
    // },
  });

  const location = useLocation();

  const news_id = location?.state?.id || eventId;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );

  const [idForUpdate, setIdForUpdate] = useState();

  const [startTime, setStartTime] = useState(null);

  const [showCrop, setShowCrop] = useState(false);
  const [previewMembershipImg, setPreviewMembershipImg] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [orgImg, setOrgImg] = useState(null);
  const [orgImgBinary, setOrgImgBinary] = useState(null);
  const [isCropper, setIsCropper] = useState(false);

  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);

  const [isBtnAdded, setIsBtnAdded] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [whichDate, setWhichDate] = useState();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [notificationMsg, setNotificationMsg] = useState("");

  const [notificationList, setNotificationList] = useState([]);

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  const [showModal, setShowModal] = useState(false);

  const fileInputRef = useRef(null);

  const stripHtmlTags = (input) => input.replace(/(<([^>]+)>)/gi, "").trim();

  const handleChange = (value) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
    // Apply max character limit
    if (sanitizedValue.length > 65535) {
      // Trim the value if it exceeds the limit
      onChange(sanitizedValue.slice(0, 65535));
    } else {
      onChange(sanitizedValue);
    }
  };

  const handleCloseCrop = () => {
    setShowCrop(false);
    fileInputRef.current.value = "";
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (!startDate) {
      setStartDate(new Date().toISOString().split("T")[0]);
    }
  }, [startDate]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIsCropper(true);

    setOrgImg(file);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewMembershipImg(fileUrl);
      setImageSrc(fileUrl);
      setOrgImgBinary(fileUrl);
    } else {
      setPreviewMembershipImg(null);
    }
  };

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  useEffect(() => {
    if (isCropper == true) {
      setShowCrop(true);
    }
  }, [imageSrc]);

  useEffect(() => {
    var dayOfCalender = day;
    if (String(dayOfCalender).length < 2) {
      dayOfCalender = `${0}${day}`;
    }
    if (whichDate == CONSTANT.START_DATE) {
      setStartDate(`${year}-${MONTH_CODE[month]}-${dayOfCalender}`);
    } else if (whichDate == CONSTANT.END_DATE) {
      setEndDate(`${year}-${MONTH_CODE[month]}-${dayOfCalender}`);
    }
  }, [weekday, day]);

  const fetchImageAsBinary = async (url) => {
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");

      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const getFile = async () => {
    const isBinary = determineDataType(orgImg);

    if (isBinary !== "binary") {
      try {
        const imageFile = await fetchImageAsBinary(
          `${process.env.REACT_APP_BASE_URL}/proxy?url=${orgImg}`,
          "123.jpg"
        );

        setOrgImg(imageFile);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    } else {
      setOrgImg(orgImg);
    }
  };

  useEffect(() => {
    if (orgImg) {
      getFile();
    }
  }, [orgImg]);

  const OnSubmit = async (data) => {
    const PostData = new FormData();

    if (idForUpdate) {
      PostData.append("newsId", idForUpdate);
    }
    PostData.append("title", data.NEWS_TITLE);

    PostData.append("publicationDate", cleanData(correctFormat(startDate)));

    PostData.append("publicationTime", formatDateToTime(startTime));

    PostData.append("content", data.BRAND_DETAIL);

    if (isCropper) {
      setIsLoading(true);
      const imageFile = await fetchImageAsBinary(previewMembershipImg);
      PostData.append("newsImg", imageFile);
      setIsLoading(false);
    } else {
      if (imageSrc) {
        setIsLoading(true);

        const imageFile = imageSrc
          ? await fetchImageAsBinary(
              `${process.env.REACT_APP_BASE_URL}/proxy?url=${imageSrc}`
            )
          : "";
        PostData.append("newsImg", imageFile);
        setIsLoading(false);
      } else {
        PostData.append("newsImg", "");
      }
    }

    PostData.append("newsOriginalImg", imageSrc ? orgImg : "");

    PostData.append(
      "removeNewsImg",
      imageSrc ? CONSTANT.INACTIVE : CONSTANT.ACTIVE
    );

    PostData.append(
      "isBtnAdded",
      isBtnAdded ? CONSTANT.ACTIVE : CONSTANT.INACTIVE
    );
    PostData.append("btnLbl", isBtnAdded ? data.BUTTON_LABEL : "");
    PostData.append("btnLink", isBtnAdded ? data.BUTTON_Link : "");

    PostData.append("isActive", isActive ? CONSTANT.ACTIVE : 0);

    setIsLoading(true);

    TrainzaApi.AddNews(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          if (idForUpdate) {
            Toster(t("NEWS_UPDATED_SUCCESSFULLY"), "success");
            navigate("/news");
          } else {
            Toster(t("NEWS_ADDED_SUCCESSFULLY"), "success");
            if (news_id) {
              navigate("/news");
            } else {
              setShowtab(CONSTANT.ACTIVE);
            }
          }
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleGetNewsDetail = () => {
    setIsLoading(true);
    const response = TrainzaApi.GetNewsDetail(isAuthenticated, news_id);

    response.then((response) => {
      const newsInfo = response?.data?.newsInfo;
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setOrgImg(newsInfo?.featureOriginalImage);
        setOrgImgBinary(newsInfo?.featureOriginalImage);
        setNotificationList(response?.data?.newsNotification);
        setNotificationMsg(newsInfo?.title);
        setValue("NEWS_TITLE", newsInfo?.title);

        if (newsInfo?.publicationTime) {
          const defaultStartTime = new Date(
            `Mon Jun 10 2024 ${newsInfo?.publicationTime} GMT+0530 (India Standard Time)`
          );
          setStartTime(defaultStartTime);
          setValue("NEWS_START_TIME", defaultStartTime);
        }

        setStartDate(newsInfo?.publicationDate);

        setValue("BRAND_DETAIL", newsInfo?.content);
        setShowCrop(false);
        setPreviewMembershipImg(newsInfo?.featureImage);
        setImageSrc(newsInfo?.featureImage);
        setIsCropper(false);
        setIsBtnAdded(newsInfo?.isButtonAdded);
        setValue("BUTTON_LABEL", newsInfo?.buttonLable);
        setValue("BUTTON_Link", newsInfo?.buttonLink);
        setIsActive(newsInfo?.status);
      }
    });
  };

  useEffect(() => {
    if (news_id) {
      handleGetNewsDetail();
    }
  }, [news_id]);

  const ScrollRef = useRef(null);

  const handleMakeDuplicate = () => {
    ScrollRef.current?.scrollIntoView({ behavior: "smooth" });

    setIdForUpdate();

    setValue("NEWS_TITLE", `${getValues("NEWS_TITLE")} copy`);

    setStartDate();

    setStartTime();
    setValue("NEWS_START_TIME", "");
  };

  useEffect(() => {
    setIdForUpdate(news_id);
  }, [news_id]);

  var tileDisabledForStartDate = ({ date, view }) => {
    const minDate = new Date().setHours(0, 0, 0, 0);

    return date < minDate;
  };

  const start_Date = new Date(startDate);
  start_Date.setDate(start_Date.getDate() - CONSTANT.ACTIVE); // Subtract 1 day from startDate

  const tileDisabledForEndDate = ({ date }) => {
    return date <= start_Date;
  };

  useEffect(() => {
    if (startDate > endDate) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]);

  const predefautDate = new Date(JSON.stringify(startDate));

  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  const formattedDateStartDate = predefautDate.toLocaleString("en-US", options);

  const handleDeleteNews = () => {
    setIsLoading(true);
    const response = TrainzaApi.DeleteNews(isAuthenticated, news_id);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("NEWS_DELETED"), "success");
          navigate("/news");
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  function HandleChangeStatus(activeStatus, news_id) {
    const PostData = new FormData();

    PostData.append(
      "status",
      activeStatus ? CONSTANT.ACTIVE : CONSTANT.INACTIVE
    );

    setIsLoading(true);

    TrainzaApi.ChangeNewsStatus(isAuthenticated, news_id, PostData).then(
      (response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            if (activeStatus) {
              Toster(t("NEWS_ACTIVATED"), "success");
            } else {
              Toster(t("NEWS_DEACTIVE"), "success");
            }
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    );
  }

  //==== function for sending notification====
  function handleSendNotification() {
    const PostData = new FormData();
    PostData.append("message", notificationMsg);
    if (notificationMsg && notificationMsg?.length < 101) {
      setIsLoading(true);
      TrainzaApi.SendNewsNotification(
        isAuthenticated,
        idForUpdate,
        PostData
      ).then((response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            // Toster(t("NOTIFICATION_SEND_SUCCESSFULLY"), "success");
            setShowModal(true);
            handleGetNewsDetail();
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  }

  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const maxTime = new Date();
  maxTime.setHours(23);
  maxTime.setMinutes(59);
  maxTime.setSeconds(0);
  maxTime.setMilliseconds(0);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const startTimeWrapperRef = useRef(null);
  const deatailRef = useRef(null);

  useEffect(() => {
    if (formState.errors.NEWS_START_TIME) {
      startTimeWrapperRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [
    formState.errors.NEWS_START_TIME,
    formState.errors.END_TIME,
    formState.errors.REPEATING_TIME,
  ]);

  useEffect(() => {
    if (!formState.errors.NEWS_START_TIME && formState.errors.BRAND_DETAIL) {
      deatailRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.BRAND_DETAIL]);

  return (
    <div className={styles.addSection} ref={ScrollRef}>
      {isLoading && <Loader />}
      {!eventId ? (
        <div className={styles.addeventBox}>
          <div className={styles.newEventBox}>
            {idForUpdate ? (
              <p>{t("UPDATE_NEWS")}</p>
            ) : (
              <p>
                {t("NEW_NEWS_ITEM")}{" "}
                <span>- {t("ONCE_SAVED_YOU_CAN_ADD")}</span>
              </p>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <Form onSubmit={handleSubmit(OnSubmit)}>
        <div className={styles.eventTitle}>
          <Form.Group className={styles.inputIcon}>
            <Form.Label className={styles.clublabelText}>
              {t("TITLE")}
            </Form.Label>
            <Form.Control
              className={styles.FormInput}
              type="text"
              placeholder={t("ENTER_TITLE_OF_NEW_ITEM")}
              {...register("NEWS_TITLE", {
                ...ValidationSchema.NEWS_TITLE,
                onChange: (e) => {
                  handleKeyPress(e);
                },
              })}
              isInvalid={!!formState.errors.NEWS_TITLE}
              autoFocus={true}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.NEWS_TITLE?.message)}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className={styles.selectDate} ref={startTimeWrapperRef}>
          <p>{t("DATE_TIME_DETERMINES")}</p>

          <div className={styles.showDate}>
            <div
              className={styles.dateSelection}
              onClick={() => {
                handleShowDate();
                setWhichDate(CONSTANT.ACTIVE);
              }}
            >
              <img src={Calender} alt="calender"></img>
              {showDate ? (
                <p>{t("SELECT_DATE")}</p>
              ) : (
                <p>{startDate ? formatDate(startDate) : t("SELECT_DATE")}</p>
              )}
            </div>

            <div className={styles.addEventDate}>
              <DatePicker
                {...register(
                  "NEWS_START_TIME",
                  ValidationSchema.NEWS_START_TIME
                )}
                selected={startTime}
                onChange={(time) => {
                  setStartTime(time);
                  setValue("NEWS_START_TIME", time);
                }}
                placeholderText={t("SELECT_TIME")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={CONSTANT.TIME_INTERVAL_ONE}
                 dateFormat={CONSTANT.TIME_FORMAT}
                timeFormat="HH:mm aa"
                className={styles.timedatePicker}
                popperPlacement="top-start"
                autoComplete={false}
                onFocus={(e) => (e.target.readOnly = true)}
                minTime={
                  correctFormat(startDate) === getCurrentDate()
                    ? new Date()
                    : ""
                }
                maxTime={
                  correctFormat(startDate) === getCurrentDate() ? maxTime : ""
                }
              />
              <IoMdTime />
              <div className="validateError">
                {!startTime && t(formState.errors.NEWS_START_TIME?.message)}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.infoLabel} ref={deatailRef}>
          <div className="editorBox">
            <h2>{t("CONTENT")}</h2>

            <TextEditor
              placeholder={t("DETAIL_OF_YOUR_NEWS_ITEM")}
              value={value}
              onChange={handleChange}
              onBlur={onBlur}
              ref={ref}
              error={error}
              isInvalid={formState.errors.BRAND_DETAIL}
              autoFocus={true}
            />
          </div>
        </div>

        <div className={styles.eventImage}>
          <h4>{t("FEATURE_IMAGE")}</h4>
          <p>{t("ADD_RECOMMENDED_IMAGE")}</p>
          <div className={styles.uploadBox}>
            <div className={styles.profileSectionOption}>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />

              <CustomButton
                variant="footerBtnCustom"
                type="button"
                onClick={() => {
                  handleButtonClick();
                }}
              >
                <p>
                  {t("SELECT")}
                  <span> {t("IMAGE")}</span>
                </p>
              </CustomButton>
            </div>
            <div className={styles.imageBtn}>
              <input
                type="file"
                id="file-input"
                accept=".jpg, .jpeg, .gif, .png, .webp"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {previewMembershipImg && (
                <div className={styles.previewMembershipImgBox}>
                  <img
                    src={orgImgBinary}
                    alt="previewMembershipImg"
                    className={styles.previewImg}
                  ></img>
                  <img
                    src={CrossImg}
                    alt="CrossImg"
                    className={styles.removeImg}
                    onClick={() => {
                      setPreviewMembershipImg(null);
                      setImageSrc(null);
                      setIsCropper(false);
                      fileInputRef.current.value = "";
                    }}
                  ></img>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.adBtn}>
          <div className="phycicalChek">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={isBtnAdded}
                onChange={() => {
                  setIsBtnAdded(!isBtnAdded);
                }}
              />
              <label class="form-check-label" htmlFor="flexCheckDefault">
                {t("ADD_BUTTON")}
              </label>
            </div>
          </div>
          <p>{t("ADD_OPTIONAL_BUTTON_TO_LINK")}</p>

          {isBtnAdded ? (
            <React.Fragment>
              <Form.Group className={styles.inputIcon}>
                <Form.Label className={styles.labelText}>
                  {t("BUTTON_LABEL")}
                </Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  type="text"
                  placeholder={t("ADD_BUTTON_LABEL")}
                  {...register("BUTTON_LABEL", ValidationSchema.BUTTON_LABEL)}
                  isInvalid={!!formState.errors.BUTTON_LABEL}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formState.errors.BUTTON_LABEL?.message)}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className={styles.inputIcon}>
                <Form.Label className={styles.labelText}>Link</Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  type="text"
                  placeholder={t("ADD_BUTTON_LINK")}
                  {...register("BUTTON_Link", ValidationSchema.BUTTON_Link)}
                  isInvalid={!!formState.errors.BUTTON_Link}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formState.errors.BUTTON_Link?.message)}
                </Form.Control.Feedback>
              </Form.Group>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>

        <div
          className={styles.pushNotification}
          style={{
            opacity: idForUpdate && isActive ? "1" : "50%",
            marginTop: "20px",
          }}
        >
          <h1>{t("PUSH_NOTIFICATION")}</h1>
          <p>{t("SEND_PUSH_NOTIFICATION")}</p>
          <Form.Group className={styles.notifyText}>
            <Form.Label>{t("MSG_LIMIT_TO_100")}</Form.Label>
            <Form.Control
              type="text"
              disabled={idForUpdate && isActive ? false : true}
              value={notificationMsg}
              onChange={(e) => {
                if (e.target.value !== " ") {
                  setNotificationMsg(e.target.value);
                }
              }}
            />

            {idForUpdate && (
              <p style={{ color: "red" }}>
                {!notificationMsg
                  ? t("NOTIFICATION_MSG")
                  : notificationMsg?.length > 100
                  ? t("MESSAGE_MAX_100_CH")
                  : ""}
              </p>
            )}
          </Form.Group>
          <button
            className={styles.notifyButton}
            type="button"
            onClick={() => {
              if (idForUpdate && isActive) {
                handleSendNotification();
              }
            }}
          >
            <img src={bell} alt="bell"></img>
            <p>{t("SEND_PUSH_NOTIFICATION_SEND")}</p>
          </button>
        </div>

        <div className={styles.devider}></div>

        {notificationList?.map((item, index) => {
          return (
            <div className={styles.notificationList} key={index}>
              <p>
                Sent {`>`}{" "}
                <span>
                  {formatDate(item.createdAt)} at {formatTime(item.createdAt)}{" "}
                </span>{" "}
                <strong
                  className={styles.notificationListPtag}
                  style={{ width: "285px" }}
                >
                  {" "}
                  {`>`} {item.message}
                </strong>
              </p>
            </div>
          );
        })}

        {notificationList?.length !== 0 ? (
          <div className={styles.devider}></div>
        ) : (
          ""
        )}

        <div className={styles.activeEventSection}>
          <div className={styles.switchOn}>
            <h3>{t("ACTIVE_INACTIVE_ITEM")}</h3>
            <p>{t("ON_OFF_AS_REQUIRED")}</p>
          </div>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className={styles.switchButton}
            checked={isActive}
            onChange={() => {
              setIsActive(!isActive);
              if (idForUpdate) {
                HandleChangeStatus(!isActive, news_id);
              }
            }}
          />
        </div>

        <div className={styles.eventBtn}>
          <div className={styles.cancel}>
            <CustomButton
              variant="cancelBtn"
              type="button"
              onClick={() => {
                if (news_id) {
                  navigate("/news");
                } else {
                  setShowtab(1);
                }
              }}
            >
              {t("CENCEL")}
            </CustomButton>

            {idForUpdate ? (
              <CustomButton
                variant="cancelBtn"
                onClick={handleDelete}
                type="button"
              >
                {t("DELETE")}
              </CustomButton>
            ) : (
              ""
            )}

            {idForUpdate ? (
              <CustomButton
                variant="cancelBtn"
                type="button"
                onClick={() => {
                  handleMakeDuplicate();
                }}
              >
                {t("DUPLICATE")}
              </CustomButton>
            ) : (
              ""
            )}
          </div>
          <div className={styles.save}>
            <CustomButton variant="footerBtnCustom" type="submit">
              <p>
                <span>{t("SAVE")}</span>
              </p>
            </CustomButton>
          </div>
        </div>
      </Form>
      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewMembershipImg}
        aspectRatio={CONSTANT.ASPECT_RATIO_IN_BRAND_INFO}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
      />

      <CalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        // tileDisabled={
        //   whichDate === CONSTANT.SELECT_DATE
        //     ? tileDisabledForStartDate
        //     : tileDisabledForEndDate
        // }
        selectedDate={whichDate === CONSTANT.SELECT_DATE ? startDate : endDate}
        // dateToDiable={
        //   whichDate === CONSTANT.SELECT_DATE
        //     ? new Date()
        //     : new Date(formattedDateStartDate)
        // }
        eventData={CONSTANT.ALLOW_PREVIOUS}
      />

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteNews}
        isLoading={isLoading}
      />

      <PushNotification showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default AddNews;
