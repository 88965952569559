import { LuLayoutDashboard } from "react-icons/lu";
import { FaRegCircleUser } from "react-icons/fa6";
import { GoGoal } from "react-icons/go";
import { RiCalendarCheckLine } from "react-icons/ri";
import { MdOutlineEventBusy } from "react-icons/md";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { CiRoute } from "react-icons/ci";
import { MdOutlineVerified } from "react-icons/md";

export const SidebarJSON = [
  {
    title: "DASHBOARD",
    navigateTo: "/dashboard",
    icon: <LuLayoutDashboard size={20} className="mx-3" />,
  },
  {
    title: "MEMBER",
    navigateTo: "/members",
    icon: <FaRegCircleUser size={20} className="mx-3" />,
  },
  {
    title: "GROUPS_",
    navigateTo: "/groups",
    icon: <GoGoal size={20} className="mx-3" />,
  },
  {
    title: "TRAINING",
    navigateTo: "/training",
    icon: <RiCalendarCheckLine size={20} className="mx-3" />,
  },

  {
    title: "EVENTS_",
    navigateTo: "/events",
    icon: <MdOutlineEventBusy size={20} className="mx-3" />,
  },
  {
    title: "NEWS_",
    navigateTo: "/news",
    icon: <HiOutlineSpeakerphone size={20} className="mx-3" />,
  },
  {
    title: "ROUTES_DISTANCES",
    navigateTo: "routes",
    icon: <CiRoute size={20} className="mx-3" fontSize={23} />,
  },
  {
    title: "BRAND_INFO",
    navigateTo: "/brand",
    icon: <MdOutlineVerified size={20} className="mx-3" />,
  },
];
