import React, { useEffect, useState, useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-bootstrap/Modal";
import styles from "./Result.module.css";

import { useTranslation } from "react-i18next";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../../Utils/MockData";
import Lessthan from "../../../Assests/Images/Lessthan.png";
import Greatethan from "../../../Assests/Images/Greatethan.svg";
import Calender1 from "../../../Assests/Images/Calender1.png";
import Calender2 from "../../../Assests/Images/Calender2.png";
import { resetTimeToMidnight } from "../../../Utils/CommonFunction";

function CalenderComp({
  show,
  handleClose,
  setWeekday,
  setDay,
  setMonth,
  setYear,
  previousRef,
  nextRef,
  tileDisabled,
  selectedDate,
  dateToDiable,
  eventData,
}) {
  const { t } = useTranslation();
  const currentDate = new Date();
  const dateObject = new Date(selectedDate);

  const [value, onChange] = useState(selectedDate ? dateObject : new Date());
  const [view, setView] = useState("month"); // Track the current view (month, year, etc.)
  const [currentMonthName, setCurrentMonthName] = useState("");

  const handleViewChange = useCallback(({ activeStartDate, view }) => {
    setView(view);
    if (view === "month") {
      const monthName = activeStartDate.toLocaleDateString("en-US", {
        month: "long",
      });
      setCurrentMonthName(monthName);
    }
  }, []);

  const goToPreviousMonth = () => {
    const previousDate = selectedDate ? dateObject : new Date(value);
    previousDate.setDate(previousDate.getDate() - 1);
    const previousDateReset = resetTimeToMidnight(previousDate);
    const dateToDiableReset = resetTimeToMidnight(dateToDiable);

    if (eventData || previousDateReset >= dateToDiableReset) {
      onChange(previousDate);
      const previousDateWeekday = previousDate.toLocaleString("default", {
        weekday: "long",
      });
      const previousDateName = previousDate.toLocaleString("default", {
        month: "long",
      });
      const previousDateYear = previousDate.getFullYear();
      const previousDateDay = previousDate.getDate();

      setWeekday(previousDateWeekday);
      setDay(previousDateDay);
      setMonth(previousDateName);
      setYear(previousDateYear);
    }
  };

  React.useImperativeHandle(previousRef, () => ({
    handleCallPrevious: goToPreviousMonth,
  }));

  const goToNextMonth = () => {
    const nextDate = selectedDate ? dateObject : new Date(value);
    nextDate.setDate(nextDate?.getDate() + 1);
    onChange(nextDate);
    const nextDateWeekday = nextDate.toLocaleString("default", {
      weekday: "long",
    });
    const nextDateName = nextDate.toLocaleString("default", {
      month: "long",
    });
    const nextDateYear = nextDate.getFullYear();
    const nextDateDay = nextDate.getDate();

    setWeekday(nextDateWeekday);
    setDay(nextDateDay);
    setMonth(nextDateName);
    setYear(nextDateYear);
  };

  React.useImperativeHandle(nextRef, () => ({
    handleCallNext: goToNextMonth,
  }));

  const handleDateClick = (date) => {
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();

    setWeekday(weekday);
    setDay(day);
    setMonth(month);
    setYear(year);
    if (weekday && day && month && year) {
      handleClose();
    }
  };

  const goToToday = () => {
    const today = new Date(); // Get the current date
    onChange(today); // Update the state with the current date

    // Set the weekday, day, month, and year
    setWeekday(DAYS_OF_WEEK[today.getDay()]);
    setDay(today.getDate());
    setMonth(MONTH_OF_YEAR[today.getMonth()]);
    setYear(today.getFullYear());

    handleClose(); // Close the modal
  };

  useEffect(() => {
    if (selectedDate) {
      const predefautDate = new Date(selectedDate);
      onChange(predefautDate);
    }
  }, [show]);

  useEffect(() => {
    const monthName = value.toLocaleDateString("en-US", { month: "long" });
    setCurrentMonthName(monthName);
  }, [value, selectedDate]);

  var dateString = selectedDate;
  var month = MONTH_OF_YEAR[Number(dateString?.substring(5, 7)) - 1];

  return (
    <>
      <Modal show={show} onHide={handleClose} className="dateModal">
        <div>
          <Calendar
            onChange={onChange}
            value={value}
            onClickDay={handleDateClick}
            tileDisabled={tileDisabled}
            onActiveStartDateChange={handleViewChange}
          />
          {currentMonthName === month
            ? view === "month" && (
                <div className={styles.modalBtnSection}>
                  <button
                    onClick={goToPreviousMonth}
                    className={styles.previousOrNext}
                  >
                    <img src={Lessthan} alt="lessthan" height="19px"></img>
                    <img
                      src={Calender2}
                      alt="Calender2"
                      className={styles.calenderChange}
                    ></img>
                  </button>
                  <button className={styles.showDay} onClick={goToToday}>
                    {t("TODAY")}
                  </button>
                  <button
                    onClick={goToNextMonth}
                    className={styles.previousOrNext}
                  >
                    <img
                      src={Calender1}
                      alt="Calender2"
                      className={styles.calenderChange}
                    ></img>
                    <img src={Greatethan} alt="greater" height="19px"></img>
                  </button>
                </div>
              )
            : ""}
        </div>
      </Modal>
    </>
  );
}

export default CalenderComp;
