import React, { useContext, useEffect, useState } from "react";
import styles from "./Password.module.css";
import { Form } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import { useTranslation } from "react-i18next";
import Toster from "../../../Common/Toster/Toster";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import TrainzaApi from "../../../Helpers/Api";
import { capitalizeFirstLetter } from "../../../Utils/CommonFunction";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { UserContext } from "../../../Context/UserContext";
import {
  CustomInputField,
  CustomPasswordInputField,
} from "../../../ReactBootstrap/CustomInputField/CustomInputField";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
function Password() {
  const { register, handleSubmit, reset, formState, setValue, getValues } =
    useForm({
      mode: "onChange",
    });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const dispatch = useDispatch();

  const { setIsAnyChange, saveChanges, setSaveChanges } =
    useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [userName, setUserName] = useState("");

  const getBrandDetail = () => {
    const response = TrainzaApi.brandDetail(isAuthenticated);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        const brandInfo = response?.data?.loginDetails;

        setValue("EMAIL", brandInfo?.email);
        setUserName(response?.data?.brandInfo?.brandName);
      }
    });
  };

  useEffect(() => {
    getBrandDetail();
  }, []);

  function OnSubmit() {
    const PostData = new FormData();
    PostData.append("email", getValues("EMAIL"));
    PostData.append("password", getValues("PASSWORD"));

    setIsLoading(true);
    TrainzaApi.updateLogin(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("LOGIN_CREDENTIAL_UPDATED"), "success");
          getBrandDetail();
          setIsAnyChange(false);
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
          setSaveChanges(false);
        } else {
          Toster(t(ResponseCode), "error");
          setSaveChanges(false);
        }
      }
    });
  }

  useEffect(() => {
    if (saveChanges) {
      OnSubmit();
    }
  }, [saveModalOpen]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.brandHead}>
        <p>{capitalizeFirstLetter(userName)}</p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.tabHead}>
        <p>{t("CHANGE_PASSWORD")}</p>
      </div>

      <div className={styles.devider}></div>

      <Form onSubmit={handleSubmit(OnSubmit)}>
        {/* <Form.Group className={styles.formInput}>
          <Form.Label>{t("Email_")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("YOUR_EMAIL_ADDRESS")}
            {...register("EMAIL", ValidationSchema.EMAIL)}
            isInvalid={!!formState.errors.EMAIL}
            onChange={(e) => {
              setIsAnyChange(true);
              setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                shouldValidate: true,
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.EMAIL?.message)}
          </Form.Control.Feedback>
        </Form.Group> */}

        <CustomInputField
          styleName="passwordTabInput"
          type="email"
          PlaceHolder={t("YOUR_EMAIL_ADDRESS")}
          RegisterName="EMAIL"
          register={register}
          formState={formState}
          onChange={(e) => {
            setIsAnyChange(true);
            setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
              shouldValidate: true,
            });
          }}
        >
          {t("Email_")}
        </CustomInputField>

        {/* <Form.Group className={styles.formInput}>
          <Form.Label>{t("PASSWORD")}</Form.Label>
          <Form.Control
            type={isVisible ? "text" : "password"}
            placeholder={t("YOUR_PASSWORD")}
            {...register("PASSWORD", ValidationSchema.PASSWORD)}
            isInvalid={!!formState.errors.PASSWORD}
            onChange={(e) => {
              setIsAnyChange(true);
              setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
                shouldValidate: true,
              });
            }}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.PASSWORD?.message)}
          </Form.Control.Feedback>
          {isVisible ? (
            <FaEye
              color="#727272"
              onClick={() => {
                setIsVisible(false);
              }}
            />
          ) : (
            <FaEyeSlash
              color="#727272"
              onClick={() => {
                setIsVisible(true);
              }}
            />
          )}
        </Form.Group> */}

        <CustomPasswordInputField
          styleName="passwordTabInput"
          Label={t("PASSWORD")}
          PlaceHolder={t("YOUR_PASSWORD")}
          RegisterName="PASSWORD"
          register={register}
          formState={formState}
          onChange={(e) => {
            setIsAnyChange(true);
            setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
              shouldValidate: true,
            });
          }}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        ></CustomPasswordInputField>

        {/* <button className={styles.resetOption} type="submit">
          <p>
            {t("RESET_TEXT")} <span>{t("PASSWORD")}</span>
          </p>
        </button> */}
        <div style={{ marginTop: "35px" }}>
          <CustomButton variant="footerBtnCustom" type="submit">
            <p>
              {t("RESET_TEXT")} <span>{t("PASSWORD")}</span>
            </p>
          </CustomButton>
        </div>
      </Form>
    </div>
  );
}

export default Password;
