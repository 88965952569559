import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TrainzaApi from "../../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import Toster from "../../../Common/Toster/Toster";
import styles from "./TrainingTypes.module.css";
import { Form } from "react-bootstrap";
import { CustomInputField } from "../../../ReactBootstrap/CustomInputField/CustomInputField";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import Loader from "../../../Common/Loader/Loader";

function AddWorkoutType({
  setShowAddWorkoutType,
  trainingTypeId,
  setTrainingTypeId,
  setWorkOutType,
  workOutType,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [workoutType, setWorkoutType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showtab, setShowtab] = useState(3);

  const { register, handleSubmit, formState, reset, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });

  //======= function for show workout type prefilled update form=======
  useEffect(() => {
    if (trainingTypeId) {
      setWorkoutType(workOutType);
      setValue("WORKOUT_TYPES", workOutType);
    }
  }, [trainingTypeId]);

  // ======  function for calling save workout type=====
  const OnSubmit = () => {
    const PostData = new FormData();
    if (trainingTypeId) {
      PostData.append("workoutTypeId", trainingTypeId);
    }
    PostData.append("workoutType", workoutType);
    setIsLoading(true);
    TrainzaApi.saveWorkoutType(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          setShowAddWorkoutType(false);
          setTrainingTypeId("");
          setWorkOutType("");
          setIsLoading(false);
          if (trainingTypeId) {
            Toster(t("UPDATE_WORKOUT_TYPE"), "success");
          } else {
            Toster(t("ADD_WORKOUT_TYPE"), "success");
          }
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    if (formState.errors.WORKOUT_TYPES) {
      clearErrors("WORKOUT_TYPES");
    }
    handleKeyPress(e);
    setWorkoutType(e.target.value);
    setValue("WORKOUT_TYPES", e.target.value);
  };

  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  return (
    <>
      {showtab === 3 && (
        <div className={styles.addEDit}>
          {isLoading && <Loader />}
          <div className={styles.workoutTYpes}>
            <p>
              Add/Edit
              <span> Workout Types </span>
            </p>
          </div>

          <div className={styles.typeInput}>
            <Form onSubmit={handleSubmit(OnSubmit)}>
              <CustomInputField
                styleName="inputTextForTraining"
                type="text"
                PlaceHolder={t("WORKOUT_TYPE_TEXT")}
                RegisterName={"WORKOUT_TYPES"}
                register={register}
                formState={formState}
                value={workoutType}
                onChange={handleInputChange}
              ></CustomInputField>
              <div className={styles.workoutTYpes}>
                <div className={styles.bottomBtn}>
                  <CustomButton
                    variant="cancelBtn"
                    type="button"
                    onClick={() => {
                      setTrainingTypeId("");
                      setWorkOutType("");
                      setShowAddWorkoutType(false);
                    }}
                  >
                    {t("CENCEL")}
                  </CustomButton>
                  <button type="submit">
                    <p>
                      Save <span>Type</span>
                    </p>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
}

export default AddWorkoutType;
