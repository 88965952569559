import React, { useEffect, useState } from "react";
import styles from "./MemberDetail.module.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MemberDetail from "./MemberDetail";
import Result from "./Result";

import LogBook from "./LogBook";
import { useTranslation } from "react-i18next";
import CustomTabButton from "../../../ReactBootstrap/CustomTabButton";
import { useLocation, useNavigate } from "react-router-dom";
import AddDetail from "../AddDetail";
import Toster from "../../../Common/Toster/Toster";
function MemberDetailLayout() {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const member_id = location.state.member_id;
  const is_edit = location.state.is_edit;
  const memberLimit = location.state.memberLimit;

  const [userName, setUserName] = useState("");
  const [showtab, setShowtab] = useState(1);
  const [isMemberUpdate, setIsMemberUpdate] = useState(0);

  const [profileImg, setProfileImg] = useState("");

  const [isMemberValid, setIsMemberValid] = useState(true);

  useEffect(() => {
    if (is_edit) {
      setIsMemberUpdate(true);
    }
  }, [is_edit]);

  useEffect(() => {
    if (!isMemberValid) {
      Toster(t("INVALD_MEMBER"), "error");
      navigate("/members");
    }
  }, [isMemberValid]);
  return (
    <div>
      {/* <p className={styles.pageHead}>
        {t("MEMBERS")} {">"} {t("DETAILS_")}
      </p> */}

      <p className={styles.pageHead}>
        {showtab === 1
          ? t("MEMBERS > DETAILS")
          : showtab === 2
          ? t("MEMBERS  > RESULTS")
          : t("MEMBERS  > LOGBOOK")}
      </p>
      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => {
              setShowtab(1);
              setIsMemberUpdate(0);
            }}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("DETAILS")}
          </CustomTabButton>
          <CustomTabButton
            onClick={() => {
              setShowtab(2);
              setIsMemberUpdate(0);
            }}
            variant={showtab === 2 ? "primary" : "secondary"}
          >
            {t("RESULTS_")}
          </CustomTabButton>
          <CustomTabButton
            onClick={() => {
              setShowtab(3);
              setIsMemberUpdate(0);
            }}
            variant={showtab === 3 ? "primary" : "secondary"}
          >
            {t("LOGBOOK")}
          </CustomTabButton>
        </div>
      </div>
      <div className={styles.devider}></div>

      {showtab === 1 ? (
        isMemberUpdate ? (
          <AddDetail
            member_id={member_id}
            isMemberUpdate={isMemberUpdate}
            setIsMemberUpdate={setIsMemberUpdate}
            setProfileImg={setProfileImg}
            setIsMemberValid={setIsMemberValid}
            memberLimit={memberLimit}
          />
        ) : (
          <MemberDetail
            member_id={member_id}
            setIsMemberUpdate={setIsMemberUpdate}
            setUserName={setUserName}
            setProfileImg={setProfileImg}
            setIsMemberValid={setIsMemberValid}
          />
        )
      ) : showtab === 2 ? (
        <Result
          member_id={member_id}
          userName={userName}
          profileImg={profileImg}
        />
      ) : (
        <LogBook
          userName={userName}
          profileImg={profileImg}
          member_id={member_id}
          setShowtab={setShowtab}
          showtab={showtab}
        />
      )}
    </div>
  );
}

export default MemberDetailLayout;
