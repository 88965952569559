import Modal from "react-bootstrap/Modal";
import styles from "./TermsAndCondition.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import TrainzaApi from "../../Helpers/Api";
import { useEffect, useState } from "react";
import { CONSTANT } from "../../Utils/Constant";
import DOMPurify from "dompurify";

function TermsAndCondition({ show, handleClose }) {
  const { t } = useTranslation();

  const [content, setContent] = useState("");

  const handleGetEvent = () => {
    const response = TrainzaApi.GetTermsAndCondition(CONSTANT.TERMS);

    response.then((response) => {
      setContent(response?.data?.pageContent);
    });
  };

  useEffect(() => {
    handleGetEvent();
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                <span>{t("Terms & Conditions")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <div
              className={styles.textEditorTextRoute}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content),
              }}
            ></div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F5F4F4" }}>
          <div className={styles.btnSection}>
            <CustomButton
              variant={"cancelBtn"}
              type="button"
              onClick={handleClose}
            >
              {t("Close")}
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsAndCondition;
