import { useTranslation } from "react-i18next";
import styles from "./AddEvent.module.css";
import { useController } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdTime } from "react-icons/io";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import CrossImg from "../../../Assests/Images/Cross.svg";
import Calender from "../../../Assests/Images/Calender.svg";
import bell from "../../../Assests/Images/bell.svg";
import { GoogleMapComp } from "../../../GoogleMap/GoogleMapComp";
import { Autocomplete } from "@react-google-maps/api";
import TextEditor from "../../../Common/TextEditor/TextEditor";
import CropImg from "../../../Common/Crop/CropImg";
import { CONSTANT } from "../../../Utils/Constant";
import TrainzaApi from "../../../Helpers/Api";
import { DATE_TYPE, MAP_CENTER, REPEATING_DAYS } from "../../../Utils/MockData";
import CalenderComp from "../../Members/MemberDetail/CalenderComp";
import { MONTH_CODE } from "../../../Utils/MonthCode";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import Toster from "../../../Common/Toster/Toster";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/Loader/Loader";
import {
  b64toBlob,
  capitalizeFirstLetter,
  cleanData,
  correctFormat,
  determineDataType,
  fetchImageAsFile,
  formatDate,
  formatDateToTime,
  formatTime,
  getRandom,
  isValidDate,
} from "../../../Utils/CommonFunction";
import mime from "mime";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteResult from "../../Members/MemberDetail/DeleteResult";
import PushNotificationModal from "./PushNotificationModal";
import EventCalender from "./EventCalender";

function AddEvent({
  setShowtab,
  eventId,
  addOrRead,
  setAddOrRead,
  eventTitle,
  setIsResultAddedForResult,
}) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    getValues,
    clearErrors,
    trigger,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      BRAND_DETAIL: "",
    },
  });

  const { t } = useTranslation();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "BRAND_DETAIL",
    control,
    rules: {
      required: {
        value: true,
        message: t("ENTER_EVENT_DETAIL"),
      },
      validate: (value) => {
        const strippedValue = stripHtmlTags(value);
        return strippedValue !== "" || t("ENTER_EVENT_DETAIL");
      },
    },
  });

  const location = useLocation();

  const event_id = location?.state?.id || eventId;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );

  const [idForUpdate, setIdForUpdate] = useState();
  const [selectDateType, setSelectDateType] = useState(1);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [repeatingTime, setRepeatingTime] = useState(null);

  const [distanceList, setDistanceList] = useState([]);

  const [sourceGeometry, setSourceGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const [showCrop, setShowCrop] = useState(false);
  const [previewMembershipImg, setPreviewMembershipImg] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [orgImg, setOrgImg] = useState(null);
  const [orgImgBinary, setOrgImgBinary] = useState(null);
  const [isCropper, setIsCropper] = useState(false);

  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);

  const [isEventLocation, setIsEventLocation] = useState(0);
  const [isBtnAdded, setIsBtnAdded] = useState(0);
  const [isResultAdded, setIsResultAdded] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [whichDate, setWhichDate] = useState();

  const [showDelete, setShowDelete] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [repeatingDays, setRepeatingDays] = useState();
  const [resultDistance, setResultDistance] = useState([]);

  const [eventName, setEventName] = useState("");

  const [notificationMsg, setNotificationMsg] = useState("");

  const [notificationList, setNotificationList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const stripHtmlTags = (input) => input.replace(/(<([^>]+)>)/gi, "").trim();

  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((el) => {
      el.style.color = ""; // Remove any color styling
    });

    return doc.body.innerHTML;
  };

  const handleChange = (value, delta, source, editor) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
    // onChange(sanitizedValue);

    if (source === "user") {
      const cleanContent = cleanPastedContent(sanitizedValue);
      onChange(cleanContent);
    } else {
      onChange(sanitizedValue);
    }
  };

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  const autocomplete = useRef(null);
  const fileInputRef = useRef(null);

  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

  const center = {
    lat: -30.559482,
    lng: 22.937506,
  };
  const [position, setPosition] = useState(MAP_CENTER);

  const onMarkerDragEnd = (e) => {
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleCloseCrop = () => {
    setShowCrop(false);

    fileInputRef.current.value = "";
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIsCropper(true);
    setOrgImg(file);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewMembershipImg(fileUrl);
      setImageSrc(fileUrl);
      setOrgImgBinary(fileUrl);
    } else {
      setPreviewMembershipImg(null);
    }
  };

  const handlePlaceSelected = (place, type) => {
    if (type == "sourceLocation") {
      setSourceGeometry({
        address: autocomplete?.current?.getPlace()?.formatted_address,
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
      setPosition({
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
      const selectedPlace = autocomplete.current?.getPlace();
      if (selectedPlace) {
        handlePlaceSelected(selectedPlace);
      }
    }
  };

  const fetchLocationName = async (lat, lng) => {
    const geocodingApiKey = placeApiKey; // Use your Google API key here
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${geocodingApiKey}`;

    const response = await fetch(geocodingUrl);
    const data = await response?.json();

    if (data.results && data.results.length > 0) {
      const formattedAddress = data.results[0].formatted_address;
      return formattedAddress;
    } else {
      return null; // No results found
    }
  };

  useEffect(() => {
    const fetchInitialLocationName = async () => {
      const locationName = await fetchLocationName(
        position?.lat,
        position?.lng
      );
      if (locationName) {
        setSourceGeometry({
          address: locationName,
          lat: position?.lat,
          lng: position?.lng,
        });
        setValue("GOOGLE_ADDRESS", locationName);
      }
    };

    fetchInitialLocationName();
  }, [position?.lat, position?.lng]);

  useEffect(() => {
    if (isCropper == true) {
      setShowCrop(true);
    }
  }, [imageSrc]);

  const getMetaDistance = () => {
    const DistanceResponse = TrainzaApi.HeightWidthDistanceMeta("distanceMeta");
    DistanceResponse.then((res) => {
      setDistanceList(res?.data?.distanceMeta);
    });
  };

  useEffect(() => {
    getMetaDistance();
  }, []);

  useEffect(() => {
    var dayOfCalender = day;
    if (String(dayOfCalender).length < 2) {
      dayOfCalender = `${0}${day}`;
    }
    if (whichDate == CONSTANT.START_DATE) {
      setStartDate(`${year}-${MONTH_CODE[month]}-${dayOfCalender}`);
    } else if (whichDate == CONSTANT.END_DATE) {
      setEndDate(`${year}-${MONTH_CODE[month]}-${dayOfCalender}`);
    }
  }, [weekday, day]);

  const handleCheckboxChange = (item) => {
    const index = resultDistance.findIndex(
      (distance) => distance.id === item.id
    );

    if (index === -1) {
      setResultDistance([...resultDistance, { id: item.id }]);
    } else {
      const updatedResultDistance = [...resultDistance];
      updatedResultDistance.splice(index, 1);
      setResultDistance(updatedResultDistance);
    }
  };

  const fetchImageAsBinary = async (url) => {
    setIsLoading(true);
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");
      setIsLoading(false);
      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
      setIsLoading(false);
    }
  };

  const getFile = async () => {
    const isBinary = determineDataType(orgImg);
    setIsLoading(true);
    if (isBinary !== "binary") {
      try {
        const imageFile = await fetchImageAsFile(
          `${process.env.REACT_APP_BASE_URL}/proxy?url=${orgImg}`,
          "123.jpg"
        );

        setOrgImg(imageFile);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    } else {
      setOrgImg(orgImg);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (orgImg) {
      getFile();
    }
  }, [orgImg]);

  const OnSubmit = async (data) => {
    if (selectDateType === CONSTANT.REPEATING_DAY && !repeatingDays) {
      startTimeWrapperRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      Toster(t("Please select repeating day"), "error");
      setIsLoading(false);
      return;
    }

    if (selectDateType === CONSTANT.ACTIVE && !startDate) {
      startTimeWrapperRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      Toster("Please select date", "error");
      setIsLoading(false);
    }
    if (
      (selectDateType === CONSTANT.END_DATE ||
        selectDateType === CONSTANT.REPEATING_DAY) &&
      (!startDate || !endDate)
    ) {
      startTimeWrapperRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      Toster(
        !startDate ? "Please select start date" : "Please select end date",
        "error"
      );
      setIsLoading(false);
    }

    const PostData = new FormData();

    if (idForUpdate) {
      PostData.append("eventId", idForUpdate);
    }
    PostData.append("title", data.TITLE);
    PostData.append("dateType", selectDateType);
    PostData.append("startDate", cleanData(correctFormat(startDate)));
    PostData.append(
      "endDate",
      selectDateType == CONSTANT.END_DATE ||
        selectDateType == CONSTANT.REPEATING_DAY
        ? cleanData(correctFormat(endDate))
        : ""
    );

    PostData.append(
      "startTime",
      selectDateType !== CONSTANT.REPEATING_DAY
        ? formatDateToTime(startTime)
        : ""
    );
    PostData.append(
      "endTime",
      selectDateType == CONSTANT.END_DATE ? formatDateToTime(endTime) : ""
    );

    PostData.append(
      "repeatingDays",
      selectDateType == CONSTANT.REPEATING_DAY ? repeatingDays : ""
    );
    PostData.append(
      "repeatingTime",
      selectDateType == CONSTANT.REPEATING_DAY
        ? formatDateToTime(repeatingTime)
        : ""
    );

    PostData.append("isEventLocation", isEventLocation ? CONSTANT.ACTIVE : 0);
    PostData.append("locationName", isEventLocation ? data.LOCATION_NAME : "");
    PostData.append(
      "googleAddress",
      data.GOOGLE_ADDRESS ? data.GOOGLE_ADDRESS : ""
    );
    PostData.append("latitude", sourceGeometry?.lat ? sourceGeometry?.lat : "");
    PostData.append(
      "longitude",
      sourceGeometry?.lng ? sourceGeometry?.lng : ""
    );

    PostData.append("eventInfo", data.BRAND_DETAIL);

    if (isCropper) {
      const imageFile = await fetchImageAsBinary(previewMembershipImg);
      PostData.append("eventImg", imageFile);
    } else {
      if (imageSrc) {
        const imageFile = imageSrc
          ? await fetchImageAsBinary(
              `${process.env.REACT_APP_BASE_URL}/proxy?url=${imageSrc}`
            )
          : "";
        PostData.append("eventImg", imageFile);
      } else {
        PostData.append("eventImg", "");
      }
    }
    PostData.append("removeEventImg", imageSrc ? 0 : 1);
    PostData.append("eventOriginalImg", imageSrc ? orgImg : "");

    PostData.append("isButtonAdded", isBtnAdded ? CONSTANT.ACTIVE : 0);
    PostData.append("btnLbl", isBtnAdded ? data.BUTTON_LABEL : "");
    PostData.append("btnLink", isBtnAdded ? data.BUTTON_Link : "");

    PostData.append("isResultAdded", isResultAdded ? CONSTANT.ACTIVE : 0);
    PostData.append(
      "resultDistances",
      isResultAdded ? JSON.stringify(resultDistance) : ""
    );

    PostData.append("isActive", isActive ? CONSTANT.ACTIVE : 0);

    const resultCondition =
      !isResultAdded || (isResultAdded && resultDistance?.length !== 0);

    const dateTypeCondition =
      selectDateType !== CONSTANT.REPEATING_DAY ||
      (selectDateType === CONSTANT.REPEATING_DAY &&
        repeatingDays &&
        startDate &&
        endDate);

    const selectedDateCondition =
      (selectDateType === CONSTANT.ACTIVE && startDate) ||
      (selectDateType === CONSTANT.END_DATE && startDate && endDate) ||
      selectDateType === CONSTANT.REPEATING_DAY;

    if (resultCondition && dateTypeCondition && selectedDateCondition) {
      setIsLoading(true);
      TrainzaApi.AddEvent(isAuthenticated, PostData).then((response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            if (idForUpdate) {
              Toster(t("EVENT_UPDATED_SUCCESSFULLY"), "success");
              navigate("/events");
            } else {
              Toster(t("EVENT_ADDED_SUCCESSFULLY"), "success");
              if (event_id) {
                navigate("/events");
              } else {
                setShowtab(CONSTANT.ACTIVE);
              }
            }
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };

  const handleGetEventDetail = () => {
    setIsLoading(true);
    const response = TrainzaApi.GetEventDetail(isAuthenticated, event_id);

    response.then((response) => {
      const eventInfo = response?.data?.eventInfo;
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setIsResultAddedForResult(eventInfo?.isResultAdded);
        setOrgImg(eventInfo?.originalImage);
        setOrgImgBinary(eventInfo?.originalImage);
        setNotificationList(response?.data?.eventNotification);
        setEventName(eventInfo?.title);
        setNotificationMsg(eventInfo?.title);
        setValue("TITLE", eventInfo?.title);
        setSelectDateType(eventInfo?.dateType);

        if (eventInfo?.startTime) {
          const defaultStartTime = new Date(
            `Mon Jun 10 2024 ${eventInfo?.startTime} GMT+0530 (India Standard Time)`
          );
          setStartTime(defaultStartTime);
          setValue("START_TIME", defaultStartTime);
        }

        if (eventInfo?.endTime) {
          const defaultEndTime = new Date(
            `Mon Jun 10 2024 ${eventInfo?.endTime} GMT+0530 (India Standard Time)`
          );
          setEndTime(defaultEndTime);
          setValue("END_TIME", defaultEndTime);
        }

        if (eventInfo?.eventStartDate) {
          const dateString = eventInfo?.eventStartDate;
          const parts = dateString?.split("-");
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // months are 0-indexed in JavaScript
          const day = parseInt(parts[2], 10);

          const startDate = new Date(year, month, day + 1);

          // Format the date to YYYY-MM-DD
          const formattedStartDate = startDate?.toISOString().split("T")[0];

          setStartDate(formattedStartDate);

          setEndDate(eventInfo?.eventEndDate);
        }

        if (eventInfo?.repeatingDays) {
          setRepeatingDays(Number(eventInfo?.repeatingDays));
        }

        if (eventInfo?.repeatingTime) {
          const defaultRepeatingTime = new Date(
            `Mon Jun 10 2024 ${eventInfo?.repeatingTime} GMT+0530 (India Standard Time)`
          );
          setRepeatingTime(defaultRepeatingTime);
          setValue("REPEATING_TIME", defaultRepeatingTime);
        }

        setIsEventLocation(eventInfo?.isEventLocation);
        setValue("LOCATION_NAME", eventInfo?.locationName);
        setValue("GOOGLE_ADDRESS", eventInfo?.googleAddress);
        setSourceGeometry({
          address: eventInfo?.googleAddress,
          lat: eventInfo?.latitude,
          lng: eventInfo?.longitude,
        });
        setPosition({
          lat: eventInfo?.latitude,
          lng: eventInfo?.longitude,
        });
        setValue("BRAND_DETAIL", eventInfo?.eventInfo);
        setShowCrop(false);
        setPreviewMembershipImg(eventInfo?.image);
        setImageSrc(eventInfo?.image);
        setIsCropper(false);
        setIsBtnAdded(eventInfo?.isButtonAdded);
        setValue("BUTTON_LABEL", eventInfo?.buttonLable);
        setValue("BUTTON_Link", eventInfo?.buttonLink);
        setIsResultAdded(eventInfo?.isResultAdded);

        const results = eventInfo?.distances?.map((item) => ({
          id: item.id,
        }));
        setResultDistance(results);
        setIsActive(eventInfo?.status);
      }
    });
  };

  useEffect(() => {
    if (event_id) {
      handleGetEventDetail();
    }
  }, [event_id, distanceList]);

  const ScrollRef = useRef(null);

  const handleMakeDuplicate = () => {
    ScrollRef.current?.scrollIntoView({ behavior: "smooth" });
    setIdForUpdate();
    setValue("TITLE", `${getValues("TITLE")} copy`);
    setSelectDateType(CONSTANT.ACTIVE);
    setStartDate();
    setStartTime();
    setValue("START_TIME", "");
    setEndDate();
    setEndTime();
    setValue("END_TIME", "");
    setRepeatingDays();
    setRepeatingTime();
  };

  useEffect(() => {
    setIdForUpdate(event_id);
  }, [event_id]);

  var tileDisabledForStartDate = ({ date, view }) => {
    const minDate = new Date().setHours(0, 0, 0, 0);

    return date < minDate;
  };
  const start_Date = new Date(startDate);
  start_Date.setDate(start_Date.getDate() - CONSTANT.ACTIVE); // Subtract 1 day from startDate

  const tileDisabledForEndDate = ({ date }) => {
    return date <= start_Date;
  };

  function HandleChangeStatus(activeStatus, id) {
    const PostData = new FormData();

    PostData.append(
      "status",
      activeStatus ? CONSTANT?.ACTIVE : CONSTANT?.INACTIVE
    );

    if (idForUpdate) {
      setIsLoading(true);

      TrainzaApi.ChangeEventStatus(isAuthenticated, id, PostData).then(
        (response) => {
          setIsLoading(false);

          const ResponseCode = STATUS_MSG[response?.data?.code];

          if (
            response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            response?.data?.code === STATUS_CODE.SESSION_EXPIRED
          ) {
            dispatch(userLogoutClear());
            Toster(t("SESSION_EXPIRED"), "error");
          } else {
            if (response?.code === STATUS_CODE.SUCCESS) {
              Toster(t("STATUS_CHANGED"), "success");
            } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
              Toster(response?.data?.message, "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          }
        }
      );
    }
  }

  // useEffect(() => {
  //   if (startDate > endDate) {
  //     setEndDate(startDate);
  //   }
  // }, [startDate, endDate]);

  const predefautDate = new Date(JSON.stringify(startDate));

  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  const formattedDateStartDate = predefautDate.toLocaleString("en-US", options);

  const handleDeleteEvent = () => {
    const response = TrainzaApi.DeleteEvent(isAuthenticated, event_id);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("EVENT_DELETED"), "success");
          navigate("/events");
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  function handleSendNotification() {
    const PostData = new FormData();

    PostData.append("message", notificationMsg);

    if (notificationMsg && notificationMsg?.length < 51) {
      setIsLoading(true);
      TrainzaApi.SendEventNotification(
        isAuthenticated,
        idForUpdate,
        PostData
      ).then((response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            // Toster(t("NOTIFICATION_SEND_SUCCESSFULLY"), "success");
            setShowModal(true);
            handleGetEventDetail();
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  }

  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  const maxTime = new Date();
  maxTime.setHours(23);
  maxTime.setMinutes(59);
  maxTime.setSeconds(0);
  maxTime.setMilliseconds(0);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const startTimeWrapperRef = useRef(null);
  const deatailRef = useRef(null);

  useEffect(() => {
    if (formState.errors.START_TIME || formState.errors.END_TIME) {
      startTimeWrapperRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (
      selectDateType === CONSTANT.REPEATING_DAY &&
      formState.errors.REPEATING_TIME
    ) {
      startTimeWrapperRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [
    formState.errors.START_TIME,
    formState.errors.END_TIME,
    formState.errors.REPEATING_TIME,
  ]);

  useEffect(() => {
    if (
      !formState.errors.START_TIME &&
      !formState.errors.END_TIME &&
      formState.errors.BRAND_DETAIL
    ) {
      deatailRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.BRAND_DETAIL]);

  const filterOutId = 3;
  const filteredDateType = idForUpdate
    ? DATE_TYPE.filter((item) => item.id !== filterOutId)
    : DATE_TYPE;

  return (
    <div className={styles.addSection} ref={ScrollRef}>
      {isLoading && <Loader />}
      <div className={styles.addeventBox}>
        <div
          className={styles.newEventBox}
          style={{
            background: addOrRead ? "#404040" : "#ff4300",
            border: addOrRead ? "1px solid #404040" : "1px solid #ff4300",
          }}
        >
          {idForUpdate ? (
            <p>{capitalizeFirstLetter(eventTitle)}</p>
          ) : (
            <p>
              {t("NEW_EVENT")} <span>- {t("ONCE_SAVED_YOU_CAN_ADD")}</span>
            </p>
          )}
        </div>
      </div>

      <Form onSubmit={handleSubmit(OnSubmit)}>
        <div className={styles.eventTitle}>
          <Form.Group className={styles.inputIcon}>
            <Form.Label className={styles.clublabelText}>
              {t("EVENT_TITLE")}
            </Form.Label>
            <Form.Control
              className={styles.FormInput}
              type="text"
              placeholder={t("ENTER_EVENT_TITLE")}
              {...register("TITLE", {
                ...ValidationSchema.TITLE,
                onChange: (e) => {
                  handleKeyPress(e);
                },
              })}
              isInvalid={formState.errors.TITLE}
              autoFocus={true}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.TITLE?.message)}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className={styles.checkDisplay}>
          {filteredDateType?.map((item, index) => {
            return (
              <Form.Group key={index}>
                <Form.Check
                  className="selectCheckBox"
                  type="checkbox"
                  label={item.datetype}
                  name={item.datetype}
                  checked={selectDateType === item.id}
                  onChange={() => {
                    setSelectDateType(item.id);
                  }}
                />
              </Form.Group>
            );
          })}
        </div>

        <div ref={startTimeWrapperRef}>
          {selectDateType === CONSTANT.ACTIVE ? (
            <div className={styles.selectDate}>
              <p>
                {t("DATE_AND_TIME_SELECT")} -{" "}
                <span>{t("SINGLE_EVENT_DATE_AND_TIME")}</span>
              </p>
              <div className={styles.showDate}>
                <div
                  className={styles.dateSelection}
                  onClick={() => {
                    handleShowDate();
                    setWhichDate(CONSTANT.ACTIVE);
                  }}
                >
                  <img src={Calender} alt="calender"></img>
                  {showDate ? (
                    <p>{t("SELECT_DATE")}</p>
                  ) : (
                    <p>
                      {startDate ? formatDate(startDate) : t("SELECT_DATE")}
                    </p>
                  )}
                </div>

                <div className={styles.addEventDate}>
                  <DatePicker
                    {...register("START_TIME", ValidationSchema.START_TIME)}
                    selected={startTime}
                    onChange={(time) => {
                      setStartTime(time);
                      setValue("START_TIME", time);
                    }}
                    placeholderText={t("SELECT_TIME")}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={CONSTANT.TIME_INTERVAL_ONE}
                    dateFormat={CONSTANT.TIME_FORMAT}
                      timeFormat="HH:mm aa"
                    className={styles.timedatePicker}
                    popperPlacement="top-start"
                    autoComplete={false}
                    onFocus={(e) => (e.target.readOnly = true)}
                    minTime={
                      correctFormat(startDate) === getCurrentDate()
                        ? new Date()
                        : ""
                    }
                    maxTime={
                      correctFormat(startDate) === getCurrentDate()
                        ? maxTime
                        : ""
                    }
                    isInvalid={formState.errors.START_TIME}
                    // autoFocus={true}
                  />
                  <IoMdTime />
                  <div className="validateError">
                    {!startTime && t(formState.errors.START_TIME?.message)}
                  </div>
                </div>
              </div>
            </div>
          ) : selectDateType === CONSTANT.END_DATE ? (
            <div className={styles.selectDate}>
              <p>
                {t("START_DATE_AND_TIME")} -{" "}
                <span>{t("SELECT_EVENT_DATE_TIME")}</span>
              </p>

              <div className={styles.showDate}>
                <div
                  className={styles.dateSelection}
                  onClick={() => {
                    handleShowDate();
                    setWhichDate(CONSTANT.ACTIVE);
                  }}
                >
                  <img src={Calender} alt="calender"></img>
                  {showDate ? (
                    <p>{t("SELECT_DATE")}</p>
                  ) : (
                    <p>
                      {startDate ? formatDate(startDate) : t("SELECT_DATE")}
                    </p>
                  )}
                </div>

                <div className={styles.addEventDate}>
                  <DatePicker
                    {...register("START_TIME", ValidationSchema.START_TIME)}
                    selected={startTime}
                    onChange={(time) => {
                      setStartTime(time);
                      setValue("START_TIME", time);
                    }}
                    placeholderText={t("SELECT_TIME")}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={CONSTANT.TIME_INTERVAL_ONE}
                    dateFormat={CONSTANT.TIME_FORMAT}
                    timeFormat="HH:mm aa"
                    className={styles.timedatePicker}
                    popperPlacement="top-start"
                    autoComplete={false}
                    onFocus={(e) => (e.target.readOnly = true)}
                    minTime={
                      correctFormat(startDate) === getCurrentDate()
                        ? new Date()
                        : ""
                    }
                    maxTime={
                      correctFormat(startDate) === getCurrentDate()
                        ? maxTime
                        : ""
                    }
                    isInvalid={formState.errors.START_TIME}
                    // autoFocus={true}
                  />
                  <IoMdTime />
                  <div className="validateError">
                    {!startTime && t(formState.errors.START_TIME?.message)}
                  </div>
                </div>
              </div>

              <p style={{ margin: "21px 0px 16px 0px" }}>
                {t("END_DATE_TIME")} -{" "}
                <span>{t("SELECT_EVENT_DATE_TIME")}</span>
              </p>

              <div className={styles.showDate}>
                <div
                  className={styles.dateSelection}
                  onClick={() => {
                    handleShowDate();
                    setWhichDate(CONSTANT.END_DATE);
                  }}
                >
                  <img src={Calender} alt="calender"></img>
                  {showDate ? (
                    <p>{t("SELECT_DATE")}</p>
                  ) : (
                    <p>
                      {endDate
                        ? isValidDate(endDate)
                          ? formatDate(endDate)
                          : t("SELECT_DATE")
                        : t("SELECT_DATE")}
                    </p>
                  )}
                </div>

                <div className={styles.addEventDate}>
                  <DatePicker
                    {...register("END_TIME", ValidationSchema.END_TIME)}
                    selected={endTime}
                    onChange={(time) => {
                      setEndTime(time);
                      setValue("END_TIME", time);
                    }}
                    placeholderText={t("SELECT_TIME")}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={CONSTANT.TIME_INTERVAL_ONE}
                    dateFormat={CONSTANT.TIME_FORMAT}
                    timeFormat="HH:mm aa"
                    className={styles.timedatePicker}
                    onFocus={(e) => (e.target.readOnly = true)}
                    minTime={
                      correctFormat(endDate) === getCurrentDate()
                        ? new Date()
                        : ""
                    }
                    maxTime={
                      correctFormat(endDate) === getCurrentDate() ? maxTime : ""
                    }
                  />
                  <IoMdTime />
                  <div className="validateError">
                    {!endTime && t(formState.errors.END_TIME?.message)}
                  </div>
                </div>
              </div>
            </div>
          ) : selectDateType === CONSTANT.REPEATING_DAY ? (
            !idForUpdate ? (
              <div className={styles.selectDate}>
                <p>
                  {t("REPEATING_DAY")} -{" "}
                  <span>{t("SELECT_DAY_THAT_EVENT_REPEAT")}</span>
                </p>

                <div className={styles.checkDisplay2}>
                  {REPEATING_DAYS?.map((item, index) => {
                    return (
                      <Form.Group key={index} style={{ width: "70px" }}>
                        <Form.Check
                          className="selectCheckBox"
                          type="checkbox"
                          id={"checkboxdays"}
                          label={item.day}
                          name={item.day}
                          checked={repeatingDays === item.id}
                          onChange={() => {
                            setRepeatingDays(item.id);
                          }}
                        />
                      </Form.Group>
                    );
                  })}
                </div>
                {/* <div className="validateError">
                  {selectDateType === CONSTANT.REPEATING_DAY &&
                    !repeatingDays &&
                    t("Please select repeating day")}
                </div> */}
                <p style={{ margin: "16px 0px 16px 0px" }}>
                  {t("REPEATING_TIME")} -{" "}
                  <span>{t("SET_EVENT_REPEAT_ON")}</span>
                </p>

                <div className={styles.showDate}>
                  <div className={styles.addEventDate}>
                    <DatePicker
                      {...register(
                        "REPEATING_TIME",
                        ValidationSchema.REPEATING_TIME
                      )}
                      selected={repeatingTime}
                      onChange={(time) => {
                        setRepeatingTime(time);
                        setValue("REPEATING_TIME", time);
                      }}
                      placeholderText={t("SELECT_TIME")}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={CONSTANT.TIME_INTERVAL_ONE}
                      dateFormat={CONSTANT.TIME_FORMAT}
                        timeFormat="HH:mm aa"
                      className={styles.timedatePicker}
                      onFocus={(e) => (e.target.readOnly = true)}
                    />
                    <IoMdTime />
                    <div className="validateError">
                      {!repeatingTime &&
                        t(formState.errors.REPEATING_TIME?.message)}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.selectDate}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <p>{t("Start Date")}</p>

                    <div className={styles.showDate}>
                      <div
                        className={styles.dateSelection}
                        onClick={() => {
                          handleShowDate();
                          setWhichDate(CONSTANT.ACTIVE);
                        }}
                      >
                        <img src={Calender} alt="calender"></img>
                        {showDate ? (
                          <p>{t("SELECT_DATE")}</p>
                        ) : (
                          <p>
                            {startDate
                              ? formatDate(startDate)
                              : t("SELECT_DATE")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <p>{t("End Date")}</p>
                    <div className={styles.showDate}>
                      <div
                        className={styles.dateSelection}
                        onClick={() => {
                          handleShowDate();
                          setWhichDate(CONSTANT.END_DATE);
                        }}
                      >
                        <img src={Calender} alt="calender"></img>
                        {showDate ? (
                          <p>{t("SELECT_DATE")}</p>
                        ) : (
                          <p>
                            {endDate
                              ? isValidDate(endDate)
                                ? formatDate(endDate)
                                : t("SELECT_DATE")
                              : t("SELECT_DATE")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>

        <div className={styles.eventLOcation}>
          <div className="phycicalChek">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={isEventLocation}
                onChange={() => {
                  setIsEventLocation(!isEventLocation);
                }}
              />
              <label class="form-check-label" htmlFor="flexCheckDefault">
                {t("EVENT_LOCATION")}
              </label>
            </div>
          </div>

          {isEventLocation ? (
            <Form.Group className={styles.inputIcon}>
              <Form.Label className={styles.labelText}>
                <p>
                  {t("LOCATION_NAME")} -{" "}
                  <span>{t("NAME_OF_LOCATION_REQUIRED")}</span>
                </p>
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                type="text"
                placeholder="Enter the Name of Location"
                {...register("LOCATION_NAME", {
                  ...ValidationSchema.LOCATION_NAME,
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                })}
                isInvalid={!!formState.errors.LOCATION_NAME}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.LOCATION_NAME?.message)}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            ""
          )}

          <Form.Group className={styles.inputIcon}>
            <Form.Label className={styles.labelText}>
              <p>
                {t("GOOGLE_ADDRESS")} -
                <span>{t("INCLUDE_LINK_TO_LOCATION")}</span>
              </p>
            </Form.Label>
            <Autocomplete
              placeApiKey={placeApiKey}
              onLoad={(auto) => {
                autocomplete.current = auto;
              }}
              onPlaceChanged={(place) => {
                handlePlaceSelected(place, "sourceLocation");
              }}
            >
              <input
                className={styles.FormInput}
                {...register("GOOGLE_ADDRESS")}
                type="text"
                placeholder="Type in street address or leave blank"
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  handleKeyPress(e);
                }}
              />
            </Autocomplete>
          </Form.Group>

          <div className={styles.googleMap} style={{ marginTop: "20px" }}>
            {position ? (
              <GoogleMapComp
                position={position}
                zoom={CONSTANT.ZOOM_LEVEL}
                setPosition={setPosition}
                onMarkerDragEnd={onMarkerDragEnd}
              ></GoogleMapComp>
            ) : (
              ""
            )}
            {position ? (
              <p className={styles.dragLocation}>
                {t("DRAG_PIN_TO_CORRENT_LOCATION")}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={styles.infoLabel} ref={deatailRef}>
          <div className="editorBox">
            <h2>{t("EVENT_INFO")}</h2>
            <TextEditor
              placeholder="Details of Event"
              value={value}
              onChange={handleChange}
              onBlur={onBlur}
              ref={ref}
              error={error}
              isInvalid={formState.errors.BRAND_DETAIL}
              autoFocus={true}
            />
          </div>
        </div>

        <div className={styles.eventImage}>
          <h4>{t("EVENT_IMG")}</h4>
          <p>{t("OPTIONAL_FEATURE_IMG")}</p>
          <div className={styles.uploadBox}>
            <div className={styles.profileSectionOption}>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />

              <CustomButton
                variant="footerBtnCustom"
                type="button"
                onClick={handleButtonClick}
              >
                <p>
                  <span>{t("SELECT_PHOTO")}</span>
                </p>
              </CustomButton>
            </div>
            <div className={styles.imageBtn}>
              <input
                type="file"
                id="file-input"
                accept=".jpg, .jpeg, .gif, .png, .webp"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {previewMembershipImg && (
                <div className={styles.previewMembershipImgBox}>
                  <img
                    src={orgImgBinary}
                    alt="previewMembershipImg"
                    className={styles.previewImg}
                  ></img>
                  <img
                    src={CrossImg}
                    alt="CrossImg"
                    className={styles.removeImg}
                    onClick={() => {
                      setPreviewMembershipImg(null);
                      setImageSrc(null);
                      setIsCropper(false);
                      setOrgImgBinary(null);
                      fileInputRef.current.value = "";
                    }}
                  ></img>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.adBtn}>
          <div className="phycicalChek">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={isBtnAdded}
                onChange={() => {
                  setIsBtnAdded(!isBtnAdded);
                }}
              />
              <label class="form-check-label" htmlFor="flexCheckDefault">
                {t("ADD_BUTTON")}
              </label>
            </div>
          </div>
          <p>{t("ADD_OPTIONAL_BUTTON_TO_LINK")}</p>

          {isBtnAdded ? (
            <React.Fragment>
              <Form.Group className={styles.inputIcon}>
                <Form.Label className={styles.labelText}>
                  {t("BUTTON_LABEL")}
                </Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  type="text"
                  placeholder={t("ADD_BUTTON_LABEL")}
                  {...register("BUTTON_LABEL", {
                    ...ValidationSchema.BUTTON_LABEL,
                    onChange: (e) => {
                      handleKeyPress(e);
                    },
                  })}
                  isInvalid={!!formState.errors.BUTTON_LABEL}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formState.errors.BUTTON_LABEL?.message)}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className={styles.inputIcon}>
                <Form.Label className={styles.labelText}>Link</Form.Label>
                <Form.Control
                  className={styles.FormInput}
                  type="text"
                  placeholder={t("ADD_BUTTON_LINK")}
                  {...register("BUTTON_Link", {
                    ...ValidationSchema.BUTTON_Link,
                    onChange: (e) => {
                      handleKeyPress(e);
                    },
                  })}
                  isInvalid={!!formState.errors.BUTTON_Link}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formState.errors.BUTTON_Link?.message)}
                </Form.Control.Feedback>
              </Form.Group>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>

        <div className={styles.includeResult}>
          <div className="phycicalChek">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                checked={isResultAdded}
                onChange={() => {
                  setIsResultAdded(!isResultAdded);
                  if (resultDistance) {
                    setResultDistance([]);
                  }
                }}
              />
              <label class="form-check-label" htmlFor="flexCheckDefault">
                {t("INCLUDE_RESULT")}
              </label>
            </div>
          </div>
          <p>{t("ALLOW_MEMBER_TO_SUBMIT_RESULT")}</p>
        </div>

        {isResultAdded ? (
          <div className={styles.distanceCheck}>
            <div className={styles.selectCheck}>
              <Row>
                {distanceList?.map((item, index) => {
                  var isChecked = resultDistance.some(
                    (distance) => distance.id === item.id
                  );
                  return (
                    <Col lg={3} key={index}>
                      <Form.Group>
                        <Form.Check
                          className="selectDistanceCheckbox"
                          type="checkbox"
                          id={item.id}
                          label={`${item?.distance}KM`}
                          name={`KM`}
                          checked={isChecked}
                          onChange={() => {
                            handleCheckboxChange(item);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
              <div className="validateError">
                {resultDistance.length === 0 && t("SELECT_RESULT_DISTANCE")}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className={styles.devider}></div>

        <div
          className={styles.pushNotification}
          style={{ opacity: idForUpdate && isActive ? "1" : "50%" }}
        >
          <h1>{t("PUSH_NOTIFICATION")}</h1>
          <p>{t("SEND_PUSH_NOTIFICATION")}</p>
          <Form.Group className={styles.notifyText}>
            <Form.Label>{t("MSG_LIMIT_TO_32")}</Form.Label>
            <Form.Control
              type="text"
              disabled={idForUpdate && isActive ? false : true}
              value={notificationMsg}
              onChange={(e) => {
                if (e.target.value !== " ") {
                  setNotificationMsg(e.target.value);
                }
              }}
            />
            {idForUpdate && (
              <p style={{ color: "red" }}>
                {!notificationMsg
                  ? t("NOTIFICATION_MSG")
                  : notificationMsg?.length > 50
                  ? t("MESSAGE_MAX_50_CH")
                  : ""}
              </p>
            )}
          </Form.Group>
          <button
            className={styles.notifyButton}
            type="button"
            onClick={() => {
              if (idForUpdate && isActive) {
                handleSendNotification();
              }
            }}
          >
            <img src={bell} alt="bell"></img>
            <p>{t("SEND_PUSH_NOTIFICATION_SEND")}</p>
          </button>
        </div>

        <div className={styles.devider}></div>

        {notificationList?.map((item, index) => {
          return (
            <div className={styles.notificationList} key={index}>
              <p>
                Sent {`>`}{" "}
                <span>
                  {formatDate(item.createdAt)} at {formatTime(item.createdAt)}{" "}
                </span>{" "}
                <strong
                  className={styles.notificationListPtag}
                  style={{ width: "290px" }}
                >
                  {" "}
                  {`>`} {item.message}
                </strong>
              </p>
              {/* <span>
                {formatDate(item.createdAt)} at {formatTime(item.createdAt)}{" "}
              </span> */}

              {/* <p
                className={styles.notificationListPtag}
                style={{ width: "290px" }}
              >
                {" "}
                {`>`} {item.message}
              </p> */}
            </div>
          );
        })}

        {notificationList?.length !== 0 ? (
          <div className={styles.devider}></div>
        ) : (
          ""
        )}

        <div className={styles.activeEventSection}>
          <div className={styles.switchOn}>
            <h3>{t("ACTIVE_INACTIVE_EVENT")}</h3>
            <p>{t("ON_OFF_AS_REQUIRED")}</p>
          </div>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className={styles.switchButton}
            checked={isActive}
            onChange={() => {
              setIsActive(!isActive);
              HandleChangeStatus(!isActive, idForUpdate);
            }}
          />
        </div>

        <div className={styles.eventBtn}>
          <div className={styles.cancel}>
            <CustomButton
              variant="cancelBtn"
              type="button"
              onClick={() => {
                if (addOrRead) {
                  // setAddOrRead(false);
                  navigate("/events");
                } else {
                  if (event_id) {
                    navigate("/events");
                  } else {
                    setShowtab(1);
                  }
                }
              }}
            >
              {t("CENCEL")}
            </CustomButton>

            {idForUpdate ? (
              <CustomButton
                variant="cancelBtn"
                // opacity={CONSTANT.OPACITY_RATIO}
                type="button"
                onClick={handleDelete}
              >
                {t("DELETE")}
              </CustomButton>
            ) : (
              ""
            )}

            {idForUpdate ? (
              <CustomButton
                variant="cancelBtn"
                type="button"
                onClick={() => {
                  handleMakeDuplicate();
                }}
              >
                {t("DUPLICATE")}
              </CustomButton>
            ) : (
              ""
            )}
          </div>
          <div className={styles.save}>
            <CustomButton variant="footerBtnCustom" type="submit">
              <p>
                <span>{t("SAVE")}</span>
              </p>
            </CustomButton>
          </div>
        </div>
      </Form>
      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewMembershipImg}
        aspectRatio={CONSTANT.ASPECT_RATIO_IN_BRAND_INFO}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
      />

      <EventCalender
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        tileDisabled={
          whichDate !== CONSTANT.SELECT_DATE && tileDisabledForEndDate
        }
        selectedDate={whichDate === CONSTANT.SELECT_DATE ? startDate : endDate}
        dateToDiable={
          whichDate === CONSTANT.SELECT_DATE
            ? new Date()
            : new Date(formattedDateStartDate)
        }
        eventData={
          whichDate === CONSTANT.SELECT_DATE && CONSTANT.ALLOW_PREVIOUS
        }
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        startTime={startTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteEvent}
        isLoading={isLoading}
      />

      <PushNotificationModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

export default AddEvent;
