import React, { useEffect, useState } from "react";
import styles from "./AddGroup.module.css";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteResult from "../Members/MemberDetail/DeleteResult";

import { useForm } from "react-hook-form";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import TrainzaApi from "../../Helpers/Api";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Loader from "../../Common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import { CustomInputField } from "../../ReactBootstrap/CustomInputField/CustomInputField";
function EditGroup({
  predefaultData,
  setEditIndex,
  handleGetGroupList,
  handleDeleteGroup,
  show,
  handleClose,
  grpList,
  setGrpList,
}) {
  const { register, handleSubmit, formState, setValue, reset } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );

  const [isLoading, setIsLoading] = useState(false);

  const [grpId, setGrpId] = useState();
  const [totalMember, setTotalMember] = useState();

  useEffect(() => {
    setGrpId(predefaultData?.id);
    setValue("GROUP_NAME", predefaultData?.name);
    setValue("SUBTITLE", predefaultData?.subtitle);

    setValue("ABOUT_INFO", predefaultData?.aboutInfo);
    setTotalMember(predefaultData?.totalMembers);
  }, [predefaultData]);

  function OnSubmit(data) {
    const updatedObject = {
      id: grpId,
      name: data.GROUP_NAME,
      subtitle: data.SUBTITLE,
      aboutInfo: data.ABOUT_INFO,
      totalMembers: totalMember,
    };

    const PostData = new FormData();

    PostData.append("groupId", grpId);
    PostData.append("name", data.GROUP_NAME);
    PostData.append("subtitle", data.SUBTITLE);
    PostData.append("aboutInfo", data.ABOUT_INFO);

    setIsLoading(true);
    TrainzaApi.addGroup(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("GROUP_UPDATED_SUCCESSFULLY"), "success");
          setEditIndex();

          const updatedData = grpList.map((item) => {
            if (item.id === grpId) {
              return updatedObject;
            }
            return item;
          });

          setGrpList(updatedData);
          reset();
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.MainBox}>
        <div className={styles.headingBox}>
          <p>
            {t("EDIT")}
            <span> {t("TRAINING_GRP")}</span>
          </p>
        </div>
        <Form onSubmit={handleSubmit(OnSubmit)}>
          <div className={styles.inputArea}>
            <CustomInputField
              styleName="inputTextForAddGroup"
              type="text"
              PlaceHolder={t("INPUT_GRP_NAME")}
              RegisterName={"GROUP_NAME"}
              register={register}
              formState={formState}
            >
              <p>{t("GRP_NAME")}</p>
            </CustomInputField>

            <CustomInputField
              styleName="inputTextForAddGroup"
              type="text"
              PlaceHolder={t("INPUT_SUBTITLE")}
              RegisterName={"SUBTITLE"}
              register={register}
              formState={formState}
            >
              <p>{t("SUBTITLE_GRP_TRAINER_OPTIONAL")}</p>
            </CustomInputField>

            <CustomInputField
              styleName="inputTextForAddGroup"
              as="textarea"
              type="text"
              PlaceHolder={t("OPTONAL_DESCRIPTION")}
              RegisterName={"ABOUT_INFO"}
              register={register}
              formState={formState}
            >
              <p>{t("ABOUT_INFO")}</p>
            </CustomInputField>
          </div>

          <div className={styles.btnArea}>
            <div className={styles.cancelAndDeleteBtn}>
              <CustomButton
                variant={"cancelBtn"}
                type="button"
                onClick={() => {
                  setEditIndex();
                }}
              >
                {t("CENCEL")}
              </CustomButton>

              <CustomButton
                variant={"cancelBtn"}
                type="button"
                onClick={() => {
                  handleClose();
                }}
              >
                <RiDeleteBin6Line size="20" style={{ marginRight: "5px" }} />
                {t("DELETE")}
              </CustomButton>
            </div>
            <CustomButton variant="footerBtnCustom" type="submit">
              <p>
                {t("SAVE")} <span>{t("GROUP")}</span>
              </p>
            </CustomButton>
          </div>
        </Form>
      </div>
      <DeleteResult
        show={show}
        handleClose={handleClose}
        handleDeleteGroup={handleDeleteGroup}
        isLoading={isLoading}
      />
    </div>
  );
}

export default EditGroup;
