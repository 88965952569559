import Modal from "react-bootstrap/Modal";
import styles from "./AddEvent.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";

function PushNotificationModal({ showModal, setShowModal }) {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                {t("PUSH_NOTIFICATION")} <span>{t("SENT")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>
              A push notification <span>has been sent</span> to all members.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            background: "#F5F4F4",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div className={styles.btnSection}>
            <CustomButton
              variant={"cancelBtn"}
              type="button"
              onClick={() => setShowModal(false)}
            >
              {t("CLOSE")}
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PushNotificationModal;
