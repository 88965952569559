import Modal from "react-bootstrap/Modal";
import styles from "../Members/LimitOrDeleteModal.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../ReactBootstrap/CustomTabButton";
import Toster from "../../Common/Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import TrainzaApi from "../../Helpers/Api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Loader from "../../Common/Loader/Loader";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useState } from "react";

function DeleteRoute({
  show,
  handleClose,
  RouteId,
  setShowDelete,
  setShowtab,
  isEdit,
  handleGetRoute,
  sorting,
  setSearchKeyword
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  // Delete route api
  const handleDeleteRoute = () => {
    const response = TrainzaApi.DeleteRoutesList(isAuthenticated, RouteId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("SURE_DELETE_ROUTE"), "success");
          setSearchKeyword("")
          if (isEdit == true) {
            setShowtab(1);
          } else {
            handleGetRoute(1, "", sorting);
          }
    
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                {t("CONFIRM")} <span>{t("DELETE")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>
              {t("YOU_ARE_SURE")} <span>{t("SURE_ROUTE_DELETE")}</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F5F4F4" }}>
          <div className={styles.btnSection}>
            <CustomButton
              variant={"cancelBtn"}
              type="button"
              onClick={handleClose}
            >
              {t("CANCEL")}
            </CustomButton>

            <CustomButton
              variant="footerBtnCustom"
              type="button"
              onClick={handleDeleteRoute}
            >
              <p>
                {t("CONFIRM")} <span>{t("DELETE")}</span>
              </p>
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteRoute;
