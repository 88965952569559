import React from "react";
import styles from "./WaitingComp.module.css";
import loader from "../../Assests/Images/loader-orange.gif";
function WaitingComp() {
  return (
    <div className={styles.MainDiv}>
      <img src={loader} alt="loading" />
      <p>Processing, please wait...</p>
    </div>
  );
}

export default WaitingComp;
