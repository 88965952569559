import styles from "./Login.module.css";
import logoImage from "../Assests/Images/logo.png";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useEffect, useState } from "react";
import { ValidationSchema } from "../Utils/ValidationSchema";
import GoogleLogo from "../Assests/Images/GoogleLogo.svg";
import Shape from "../Assests/Images/Shape.svg";
import { useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import {
  setAppLogo,
  setLoginCredential,
  userLogin,
} from "../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../Utils/StatusCode";
import { STATUS_MSG } from "../Utils/StatusMsg";
import Loader from "../Common/Loader/Loader";
import Toster from "../Common/Toster/Toster";
import {
  CustomInputField,
  CustomPasswordInputFieldForSignUp,
} from "../ReactBootstrap/CustomInputField/CustomInputField";
import GoogleLogin from "./SocialLogin/GoogleLogin";
import TwitterLogin from "./SocialLogin/TwitterLogin";

function Login() {
  const { register, handleSubmit, reset, formState, setValue, clearErrors } =
    useForm({
      mode: "onChange",
    });

  const predefault_loginEmail = useSelector(
    (state) => state.user.login_email_brand
  );
  const predefault_loginPassword = useSelector(
    (state) => state.user.login_password_brand
  );
  const predefault_remember = useSelector(
    (state) => state.user.remember_me_brand
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [shown, setShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemember, setIsRemember] = useState(false);

  const onSubmit = async (formdata) => {
    setIsLoading(true);

    const PostData = new FormData();
    PostData.append("email", formdata.EMAIL);
    PostData.append("password", formdata.PASSWORD);

    dispatch(userLogin(PostData)).then((res) => {
      setIsLoading(false);

      var response = res.payload;
      const ResponseCode = STATUS_MSG[response?.data.code];

      if (response?.code === STATUS_CODE.SUCCESS) {
        navigate("/dashboard");
        reset();

        dispatch(
          setAppLogo({
            logo: response?.data?.appLogo,
          })
        );

        if (isRemember) {
          dispatch(
            setLoginCredential({
              email: formdata.EMAIL,
              password: formdata.PASSWORD,
              remember: isRemember,
            })
          );
        } else {
          dispatch(
            setLoginCredential({
              email: "",
              password: "",
              remember: false,
            })
          );
        }
      } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  useEffect(() => {
    if (predefault_remember) {
      setIsRemember(predefault_remember);
      setValue("EMAIL", predefault_loginEmail);
      setValue("PASSWORD", predefault_loginPassword);
    }
  }, []);

  return (
    <div className={styles.bgSection}>
      {isLoading && <Loader />}
      <div className={styles.formSection}>
        <div className={styles.logoImg}>
          <img src={logoImage}></img>
        </div>

        <div className={styles.loginForm}>
          <div className={styles.heading}>
            <p>
              {t("LOG")}
              <span>{t("IN")}</span>{" "}
            </p>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* <Form.Group className={styles.loginInput}>
              <Form.Control
                type="text"
                placeholder={t("EMAIL")}
                autoFocus={true}
                {...register("EMAIL", ValidationSchema.EMAIL)}
                isInvalid={!!formState.errors.EMAIL}
                onChange={(e) => {
                  setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                    shouldValidate: true,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.EMAIL?.message)}
              </Form.Control.Feedback>

              <MdOutlineMailOutline />
            </Form.Group> */}

            <CustomInputField
              styleName="signUpTabInput"
              type="type"
              PlaceHolder={t("EMAIL")}
              RegisterName="EMAIL"
              register={register}
              formState={formState}
              onChange={(e) => {
                if (formState.errors.EMAIL) {
                  clearErrors("EMAIL");
                }
              }}
              icon={<MdOutlineMailOutline style={{ top: "34px" }} />}
            ></CustomInputField>

            {/* <Form.Group className={styles.loginInput}>
              <MdLockOutline />

              <Form.Control
                type={shown ? "text" : "password"}
                placeholder={t("YOUR_PASSWORD")}
                autoFocus={true}
                {...register("PASSWORD", ValidationSchema.PASSWORD)}
                isInvalid={!!formState.errors.PASSWORD}
                onChange={(e) => {
                  setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
                    shouldValidate: true,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.PASSWORD?.message)}
              </Form.Control.Feedback>

              <div className={styles.lockIcon}>
                {shown ? (
                  <FaEye color="#727272" onClick={() => setShown(!shown)} />
                ) : (
                  <FaEyeSlash
                    color="#727272"
                    onClick={() => setShown(!shown)}
                  />
                )}
              </div>
            </Form.Group> */}

            <CustomPasswordInputFieldForSignUp
              styleName="signUpTabInput2"
              PlaceHolder={t("YOUR_PASSWORD")}
              RegisterName="PASSWORD"
              register={register}
              icon={<MdLockOutline style={{ top: "10px" }} />}
              formState={formState}
              onChange={(e) => {
                setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
              }}
              isVisible={shown}
              setIsVisible={setShown}
              lockIconStyle="lockIcon"
              position={10}
            ></CustomPasswordInputFieldForSignUp>

            {/* REMEMBER ME */}
            <div className="remeberChek123">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isRemember}
                  id="flexCheckDefault"
                  onChange={() => {
                    setIsRemember(!isRemember);
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t("REGISTER_ME")}
                </label>
              </div>
              <p onClick={() => navigate("/forgot-password")}>
                {t("FORGET_PASSWORD_")}
              </p>
            </div>

            <div className={styles.loginBtn}>
              <button>
                <p>{t("LOGIN_")}</p>
              </button>
            </div>
          </Form>
          <div className={styles.signUpWith}>
            <p>{t("OR_LOGIN_WITH")}</p>
          </div>
          <div className={styles.signInOption}>
            <div className={styles.option}>
              {/* <img
                src={GoogleLogo}
                alt="GoogleLogo"
                width="22px"
                height="22px"
              ></img> */}

              <GoogleLogin />
            </div>
            {/* <div className={styles.option}>
              <FaFacebookF size="20" />
            </div> */}
            <div className={styles.option}>
              {/* <img src={Shape} alt="Shape" width="22px" height="22px"></img> */}

              <TwitterLogin twitterLoginFrom={"login"} />
            </div>
          </div>
          <div className={styles.alreadySignin}>
            <p>
              {t("NOT_RESGISTERED")}{" "}
              <span onClick={() => navigate("/sign-up")}>{t("SIGN_UP__")}</span>
            </p>

            {/* <p>{t("Already registered? Sign In")}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
