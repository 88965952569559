import React, { useEffect, useRef, useState } from "react";
import styles from "./EventListByCalender.module.css";
import CompanyLogo from "../../../Assests/Images/CompanyLogo.png";
import Cross from "../../../Assests/Images/Cross.svg";
import Edit from "../../../Assests/Images/Edit.svg";
import TrainzaLogo from "../../../Assests/Images/TrainzaLogo.svg";
import EventThumbnail from "../../../Assests/Images/EventThumbnail.png";
import { FaCheck } from "react-icons/fa6";
import {
  CustomButton,
  CustomImgButton,
} from "../../../ReactBootstrap/CustomTabButton";
import { useTranslation } from "react-i18next";
import { FaGreaterThan, FaLessThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import CalenderComp from "../../Members/MemberDetail/CalenderComp";
import { CONSTANT } from "../../../Utils/Constant";
import {
  DAYS_OF_WEEK,
  MONTH_OF_YEAR,
  REPEATING_DAYS,
} from "../../../Utils/MockData";
import { MONTH_CODE } from "../../../Utils/MonthCode";
import TrainzaApi from "../../../Helpers/Api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  formatDate,
} from "../../../Utils/CommonFunction";
import Toster from "../../../Common/Toster/Toster";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import Loader from "../../../Common/Loader/Loader";
import DeleteResult from "../../Members/MemberDetail/DeleteResult";
import SuperAdminEvent from "./SuperAdminEvent";
function EventListByCalender({ setShowtab }) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const previousRef = useRef();
  const nextRef = useRef();
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [eventList, setEventList] = useState([]);

  const [eventListByAdmin, setEventListByAdmin] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [totalCount, setTotalCount] = useState();

  const [showDelete, setShowDelete] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState();

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };

  const currentDate = new Date();

  useEffect(() => {
    setWeekday(DAYS_OF_WEEK[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(MONTH_OF_YEAR[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  useEffect(() => {
    var daySelected = day;

    if (day.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }
    const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;

    setSelectedDate(rightFormatOfDate);

    if (selectedDate) {
      handleGetEvent(CONSTANT.PAGE_ONE, rightFormatOfDate);
    }
  }, [day, year, month, weekday]);

  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const parts = paragraph?.split(new RegExp(`(${keyWord})`, "gi"));

      return parts?.map((part, index) =>
        part?.toLowerCase() === keyWord?.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "500",
              fontSize: "15px",
              letterSpacing: "0.3px",
              background: "#CEF56E",
              margin: "0px",
              color: "#000000",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <p style={{ margin: "0px" }}>{renderHighlightedParagraph()}</p>;
  }

  const handleGetEvent = (page, date) => {
    setIsLoading(true);
    const response = TrainzaApi.GetEventByDate(isAuthenticated, page, date);

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setEventListByAdmin(response?.data?.superAdminList);

        if (page == 1) {
          setEventList(response?.data?.eventList);
        } else {
          setEventList([...eventList, ...response?.data?.eventList]);
        }
        setPageNumber(page + 1);

        setIsLoadMore(response?.data?.loadMore);
        setTotalCount(response?.data?.count);
      }
    });
  };

  const handleDeleteGroup = () => {
    const response = TrainzaApi.DeleteEvent(isAuthenticated, deleteIndex);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("EVENT_DELETED"), "success");
          handleGetEvent(CONSTANT.PAGE_ONE, selectedDate);
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div className={styles.iconHolder} onClick={callgoToPreviousMonth}>
            <FaLessThan size="15" color="#000000" />
          </div>
          <div className={styles.scheduleDate}>
            <p>
              <span>{weekday}</span> {day} {month} {year}
            </p>
          </div>
          <div className={styles.iconHolder} onClick={callgoToNextMonth}>
            <FaGreaterThan size="15" color="#000000" />
          </div>
        </div>
        <MdOutlineCalendarMonth
          size="24"
          color="#FFFFFF"
          onClick={handleShowDate}
        />
      </div>
      {eventList?.map((item, index) => {
        return (
          <div
            className={styles.eventListBox}
            key={index}
            style={{
              background:
                item?.status === CONSTANT.INACTIVE
                  ? CONSTANT.INACTIVE_COLOR
                  : "",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={item.eventImg ? item.eventImg : EventThumbnail}
                alt="EventThumbnail"
                className={styles.imageSection}
                // onClick={() => {
                //   navigate("/events/event-detail", {
                //     state: {
                //       event_id: item.id,
                //       event_title: item.title,
                //       event_img: item.eventImg,
                //     },
                //   });
                // }}
                onClick={() => {
                  navigate("/events/event-detail", {
                    state: {
                      event_id: item.id,
                      event_title: item.title,
                      event_img: item.eventImg,
                      is_update: true,
                    },
                  });
                }}
              ></img>
              <div className={styles.detail}>
                <p
                  onClick={() => {
                    navigate("/events/event-detail", {
                      state: {
                        event_id: item.id,
                        event_title: item.title,
                        event_img: item.eventImg,
                        is_update: true,
                      },
                    });
                  }}
                >
                  {
                    <HighlightKeyInParagraph
                      keyWord={""}
                      paragraph={capitalizeFirstLetter(item?.title)}
                    />
                  }
                </p>
                {item.distance?.length !== 0 ? (
                  <h1>
                    {item.distance?.map((dis, index) => {
                      return (
                        <React.Fragment key={index}>
                          {dis.distance}
                          <span>{t("KM")}</span>{" "}
                          {item.distance?.length - 1 !== index ? "|" : ""}{" "}
                        </React.Fragment>
                      );
                    })}
                  </h1>
                ) : (
                  <h1>----</h1>
                )}

                <h2>
                  {" "}
                  {item.startDate
                    ? formatDate(item.startDate)
                    : REPEATING_DAYS[item.repeatingDays - 1].day}{" "}
                  {item.endDate ? `- ${formatDate(item.endDate)}` : ""}
                </h2>
              </div>
            </div>

            <div className={styles.editCross}>
              <CustomImgButton
                variant="primary"
                src={Cross} // Replace with your icon path
                alt="cross"
                onClick={() => {
                  handleDelete();
                  setDeleteIndex(item?.id);
                }}
              ></CustomImgButton>

              <CustomImgButton
                variant="primary"
                src={Edit} // Replace with your icon path
                alt="edit"
                // onClick={() => {
                //   navigate("/events/events-update", { state: { id: item.id } });
                // }}
                onClick={() => {
                  navigate("/events/event-detail", {
                    state: {
                      event_id: item.id,
                      event_title: item.title,
                      event_img: item.eventImg,
                      is_update: true,
                    },
                  });
                }}
              ></CustomImgButton>
            </div>
          </div>
        );
      })}
      {!isLoadMore && eventList?.length === 0 ? (
        <div className={styles.noDataFound}>
          <p>
            <span>{t("NO_EVENT_ON")} </span>
            {weekday} {day} {month} {year}
          </p>
        </div>
      ) : (
        ""
      )}
      <div className={styles.inviteormore}>
        <div className={styles.membersCount}>
          <CustomButton
            variant="footerBtnCustom"
            onClick={() => {
              setShowtab(3);
            }}
          >
            <p>
              {t("ADD")} <span>{t("EVENT")}</span>
            </p>
          </CustomButton>

          <p>
            {totalCount}{" "}
            <span>{totalCount > 1 ? t("Events") : t("Event")}</span>
          </p>
          <CustomButton
            variant="footerBtnCustom"
            opacity={isLoadMore ? "1" : CONSTANT.OPACITY_RATIO}
            onClick={() => {
              if (isLoadMore) {
                handleGetEvent(pageNumber, selectedDate);
              }
            }}
          >
            <p>
              {t("LOAD")} <span>{t("MORE")}</span>
            </p>
          </CustomButton>
        </div>
      </div>
      {/* <div className={styles.adviceBox}>
        <p>
          {t("READY_MADE")} <img src={TrainzaLogo} alt="logo"></img>{" "}
          {t("TRAINZA_EVENTS")}
          {""}
        </p>
        <h2>{t("COPY_EVENT")}</h2>
      </div>

      <div className={styles.eventListBoxCopy}>
        <div style={{ display: "flex" }}>
          <img
            src={CompanyLogo}
            alt="UserImg"
            className={styles.imageSection}
          ></img>
          <div className={styles.detail}>
            <p>CLUB TIME TRIALS</p>
            <h1>
              4<span>KM</span> | 8<span>KM</span>
            </h1>
            <h2>12 June 2024</h2>
          </div>
        </div>
      </div>

      <div className={styles.copySection}>
        <CustomButton variant="footerBtnCustom">
          <p>
            {t("COPY")} <span>{t("EVENT")}</span>
          </p>
        </CustomButton>
        <p>{t("COPY_THIS_EVENT_TO_CALENDER")}</p>
      </div> */}
      <SuperAdminEvent
        eventListByAdmin={eventListByAdmin}
        handleGetEvent={handleGetEvent}
        selectedDate={selectedDate}
      />

      <CalenderComp
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
        eventData={CONSTANT.ALLOW_PREVIOUS}
        selectedDate={selectedDate}
      />
      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteGroup}
        isLoading={isLoading}
      />
    </div>
  );
}

export default EventListByCalender;
