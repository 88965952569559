import React, { useCallback, useEffect, useState } from "react";
import styles from "./TrainingLayout.module.css";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../Assests/Images/PlusIcon.svg";
import Training from "./TrainingTab/Training";
import Workout from "./WorkOutTab/Workout";
import CustomTabButton, {
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import TrainingTypes from "./TrainingTab/TrainingTypes";
import debounce from "debounce";
import { useLocation, useNavigate } from "react-router-dom";
import AddWorkout from "./WorkOutTab/AddWorkout";
import AddWorkoutType from "./TrainingTab/AddWorkoutType";
import AddTrainingDateTime from "./TrainingTab/AddTrainingDateTime";

function TrainingLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isWorkout = location?.state?.isWorkout;

  const [showtab, setShowtab] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [addTraning, setAddTraning] = useState(false);
  const [showAddWorkoutType, setShowAddWorkoutType] = useState(false);
  const [trainingTypeId, setTrainingTypeId] = useState("");
  const [workOutType, setWorkOutType] = useState("");
  const [workOutId, setWorkOutId] = useState("");

  const [trainingObj, setTrainingObj] = useState();
  const [traningId, setTraningId] = useState("");
  const tabTypes = location?.state?.types;
  const workoutOut = location?.state?.workOutTab;

  const [idForUpdate, setIdForUpdate] = useState("");

    // State to store the current search input
    const [searchInput, setSearchInput] = useState(""); 
    // State to store the search keyword that triggers the search operation
    const [searchKeyword, setSearchKeyword] = useState(""); 

  //===== function for handle search data=======
 const handleSearch = useCallback(
  debounce((keyword) => {
    setSearchKeyword(keyword); // Update the search keyword after debounce
  }, 500), []
);

// Handle the input change immediately
const handleChange = (event) => {
  const input = event.target.value;
  setSearchInput(input); // Update the input state immediately
  handleSearch(input);   // Trigger the debounced search
};


  useEffect(() => {
    if (tabTypes === true) {
      setShowtab(3);
    } else {
      setShowtab(1);
    }
  }, [tabTypes]);

  useEffect(() => {
    if (tabTypes) {
      setShowtab(3);
    } else if (workoutOut) {
      setShowtab(2);
    } else {
      setShowtab(1);
    }
  }, [tabTypes, workoutOut]);

   // Reset states on tab change
   useEffect(() => {
    setSearchInput("");  // Reset input field
    setSearchKeyword(""); // Reset search keyword
    setShowAdd(false);
    setShowAddWorkoutType(false);
    setWorkOutId("");
    setTraningId("");
    setAddTraning(false);
  }, [showtab]);
  

  //==== function for open add form on plus icon click=====
  const handleAddButtonClick = () => {
    if (showtab === 1) {
      setAddTraning(true);
    } else if (showtab === 2) {
      setShowAdd(true);
    } else {
      setShowAddWorkoutType(true);
    }
  };

  useEffect(() => {
    if (isWorkout) {
      setShowtab(2);
      setShowAdd(false);
    }
  }, [isWorkout]);
  return (
    <div>
      {showtab === 1 && addTraning ? (
        <p className={styles.heading}> {t("ADD_TRAINING_TITLE")}</p>
      ) : showtab === 1 && !addTraning ? (
        <p className={styles.heading}>{t("TRAININGS")}</p>
      ) : (
        ""
      )}

      {showtab === 2 ? (
        <p className={styles.heading}>{t("TRAINING_WORKOUT")}</p>
      ) : (
        ""
      )}

      {showtab === 3 ? (
        <p className={styles.heading}>{t("TRAINING_TYPE")}</p>
      ) : (
        ""
      )}

      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => setShowtab(1)}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("TRAINING")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(2)}
            variant={showtab === 2 ? "primary" : "secondary"}
          >
            {t("WORKOUTS")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(3)}
            variant={showtab === 3 ? "primary" : "secondary"}
          >
            {t("TYPES")}
          </CustomTabButton>
          {!showAdd && !showAddWorkoutType && !addTraning && (
            <CustomImgButton
              variant="primary"
              src={PlusIcon}
              alt="PlusIcon"
              onClick={handleAddButtonClick}
            ></CustomImgButton>
          )}
        </div>

        {!showAdd && !showAddWorkoutType && !addTraning && (
        <input
          type="text"
          className={styles.search}
          placeholder={t("SEARCH_")}
          value={searchInput} 
          onChange={handleChange}  
        />
      )}

      </div>
      <div className={styles.devider}></div>
      {showtab === 1 ? (
        addTraning ? (
          <AddTrainingDateTime
            setAddTraning={setAddTraning}
            searchKeyword={searchKeyword}
            traningId={traningId}
            setTraningId={setTraningId}
            workOutId={workOutId}
            setWorkOutId={setWorkOutId}
            trainingObj={trainingObj}
            setTrainingObj={setTrainingObj}
            idForUpdate={idForUpdate}
            setIdForUpdate={setIdForUpdate}
          />
        ) : (
          <Training
            setAddTraning={setAddTraning}
            addTraning={addTraning}
            searchKeyword={searchKeyword}
            traningId={traningId}
            setTraningId={setTraningId}
            setWorkOutId={setWorkOutId}
            setIdForUpdate={setIdForUpdate}
          />
        )
      ) : showtab === 2 ? (
        showAdd ? (
          <AddWorkout
            setShowAdd={setShowAdd}
            workOutId={workOutId}
            setWorkOutId={setWorkOutId}
          />
        ) : (
          <Workout
            setShowAdd={setShowAdd}
            searchKeyword={searchKeyword}
            setWorkOutId={setWorkOutId}
          />
        )
      ) : showtab === 3 ? (
        showAddWorkoutType ? (
          <AddWorkoutType
            setShowAddWorkoutType={setShowAddWorkoutType}
            trainingTypeId={trainingTypeId}
            setTrainingTypeId={setTrainingTypeId}
            workOutType={workOutType}
            setWorkOutType={setWorkOutType}
          />
        ) : (
          <TrainingTypes
            searchKeyword={searchKeyword}
            setShowAddWorkoutType={setShowAddWorkoutType}
            setTrainingTypeId={setTrainingTypeId}
            setWorkOutType={setWorkOutType}
          />
        )
      ) : (
        ""
      )}
    </div>
  );
}

export default TrainingLayout;
