import debounce from "debounce";
import React, { useCallback } from "react";
import { Form, FormControl } from "react-bootstrap";
import styles from "./SearchBar.module.css";
const SearchBar = ({ placeholder, setSearchKeyword, disable, opacity , searchKeyword }) => {
  const handleChange = useCallback(
    debounce((event) => {
      setSearchKeyword(event);
    }, 100),
    []
  );
  return (
    <Form inline>
      <FormControl
        type="text"
        placeholder={placeholder}
        className={styles.searchField}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disable}
        style={{ opacity: opacity }}
        value={searchKeyword}
      />
    </Form>
  );
};

export default SearchBar;
