import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./RoutesPage.module.css";
import PlusIcon from "../../Assests/Images/PlusIcon.svg";
import PlusFillIcon from "../../Assests/Images/PlusfillIcon.svg";
import Route from "./Route";
import CustomTabButton, {
  CustomImgButton,
} from "../../ReactBootstrap/CustomTabButton";
import SearchBar from "../../ReactBootstrap/SearchBar";
import AddRoutes from "./AddRoutes/AddRoutes";

function RoutesPage() {
  const { t } = useTranslation();
  const [showtab, setShowtab] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [currentEditData, setCurrentEditData] = useState(null);

  const handleOpenAdd = () => {
    setCurrentEditData(null);
    setShowtab(2);
  };

  const handleOpenEdit = (data) => {
    setCurrentEditData(data);
    setShowtab(2);
  };

  const handleClose = () => {
    setCurrentEditData(null);
    setShowtab(1);
  };

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 1 ? t("ROUTES") : t("ROUTES_ADD_NEW")}
      </p>
      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => {
              setShowtab(1);
              setCurrentEditData();
            }}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("ROUTE")}
          </CustomTabButton>
          {!currentEditData && (
            <CustomImgButton
              variant="primary"
              onClick={handleOpenAdd}
              src={showtab === 2 ? PlusFillIcon : PlusIcon}
              alt="PlusIcon"
            />
          )}
        </div>

        {showtab === 1 && (
          <SearchBar
            placeholder={t("SEARCH_")}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
            disable={isDataPresent}
            opacity={isDataPresent ? "40%" : ""}
          ></SearchBar>
        )}
      </div>
      <div className={styles.devider}></div>

      {/* =====TABS======== */}
      {showtab === 1 ? (
        <Route
          searchKeyword={searchKeyword}
          isDataPresent={isDataPresent}
          setIsDataPresent={setIsDataPresent}
          setShowtab={setShowtab}
          onEdit={handleOpenEdit}
          setSearchKeyword={setSearchKeyword}
        />
      ) : (
        <AddRoutes
          setShowtab={setShowtab}
          id={currentEditData}
          onClose={handleClose}
          setCurrentEditData={setCurrentEditData}
          setSearchKeyword={setSearchKeyword}
        />
      )}
    </div>
  );
}

export default RoutesPage;
