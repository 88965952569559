import React, { useState, useEffect, useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { SidebarJSON } from "./SidebarJSON";
import styles from "./Sidebar.module.css";
import CompanyLogo from "../../Assests/Images/CompanyLogo.png";
import { IoMdLogOut } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { UserContext } from "../../Context/UserContext";
import TrainzaApi from "../../Helpers/Api";
import Loader from "../Loader/Loader";

function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );

  const [isLoading, setIsLoading] = useState(false);

  const serverSideLogo = useSelector((state) => state.user.app_logo);

  const { setIsAnyChange } = useContext(UserContext);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
  }, []);

  var isExpand = windowSize > 991;

  const handleLogout = () => {
    setIsLoading(true);

    TrainzaApi.logoutUser(isAuthenticated);
    setIsLoading(false);
    dispatch(userLogoutClear());
    setIsAnyChange(false);
  };

  const handleLinkClick = () => {
    if (!isExpand) {
      setIsOffcanvasOpen(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.Sidebar}>
        <Navbar data-bs-theme="dark" expand={isExpand} className="NavBody">
          <div className="test">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setIsOffcanvasOpen(!isOffcanvasOpen)}
            />
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"expand"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"expand"}`}
            placement="start"
            style={{ width: "240px" }}
            show={isOffcanvasOpen}
            onHide={() => setIsOffcanvasOpen(false)}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body className={styles.CanvasBody}>
              <div className={styles.SideBarHeader}>
                <img
                  src={serverSideLogo ? serverSideLogo : CompanyLogo}
                  alt="CompanyLogo"
                ></img>
              </div>
              <div className={`${styles.NavItems} activeClass`}>
                {SidebarJSON.map((item, index) => {
                  return (
                    <NavLink
                      to={item.navigateTo}
                      className={styles.Navlink}
                      key={index}
                      onClick={handleLinkClick}
                    >
                      <div>{item.icon}</div>

                      {t(item.title)}
                    </NavLink>
                  );
                })}
                <NavLink
                  className={styles.NavlinkButton}
                  onClick={() => {
                    handleLinkClick();
                    handleLogout();
                  }}
                  to={"/login"}
                >
                  <div>
                    <IoMdLogOut size={20} className="mx-3" />
                  </div>

                  {"Logout"}
                </NavLink>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </div>
    </>
  );
}

export default Sidebar;
