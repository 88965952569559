import Modal from "react-bootstrap/Modal";
import styles from "../LimitOrDeleteModal.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";

function MemberLimit({ show, handleClose, limit }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                {t(`Member `)} <span>{t("Limit")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>
              <span>
                {t(`Your subscription has a limit of ${limit} ACTIVE members.`)}{" "}
              </span>
              {t(
                "To activate this member you first need to deactivate another member or increase your member subscription."
              )}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F5F4F4" }}>
          <div className={styles.btnSection}>
            <CustomButton
              variant={"cancelBtn"}
              type="button"
              onClick={handleClose}
            >
              {t("Close")}
            </CustomButton>

            {/* <CustomButton
              variant="footerBtnCustom"
              type="button"
              onClick={handleClose}
            >
              <p>
                {t("CONFIRM")} <span>{t("DELETE")}</span>
              </p>
            </CustomButton> */}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MemberLimit;
