import React, { useEffect, useMemo, useState } from "react";
import styles from "./AddWorkout.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus } from "react-icons/fa";
import SelectImg from "../../../Assests/Images/SelectImg.svg";
import { FaMountainSun } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { CiRoute } from "react-icons/ci";
import { RouteCustomSelect } from "../../../ReactBootstrap/CustomSelect/CustomSelect";
import { useForm } from "react-hook-form";
import TrainzaApi from "../../../Helpers/Api";
import { useSelector } from "react-redux";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import Toster from "../../../Common/Toster/Toster";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import AddRoutes from "../../Routes/AddRoutes/AddRoutes";
import { CONSTANT } from "../../../Utils/Constant";
import { capitalizeFirstLetter } from "../../../Utils/CommonFunction";
import DOMPurify from "dompurify";
import AddRouteManually from "./RouteManually/AddRouteManually";
import Loader from "../../../Common/Loader/Loader";

function RouteOptional({
  routeList,
  handleRoute,
  routeType,
  setRouteType,
  routeDetail,
  setRouteDetail,
  binaryRouteImg,
  setBinaryRouteImg,
  setIsAddManually,
  idForUpdate,
  setIdForUpdate,
  whichScreen,
  setWhichScreen,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const [addManually, setAddManually] = useState(false);

  const [routeId, setRouteId] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [isChange, setIsChange] = useState(true);

  useEffect(() => {
    setRouteId(routeType);
  }, [routeType]);

  useEffect(() => {
    if (routeId) {
      setRouteId(routeId);
    }
  }, [routeId]);

  // ========  function for handle validation======
  const { register, handleSubmit, formState, reset, setValue, control } =
    useForm({
      mode: "onChange",
    });

  //======= Route details api=======
  const handleGetRouteDetail = (id) => {
    const response = TrainzaApi.GetRoutesDetail(isAuthenticated, id);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setRouteDetail(response?.data);
        setBinaryRouteImg(response?.data?.image);
      }
    });
  };

  const handleGetRouteDetailOnPLusClick = (id) => {
    const response = TrainzaApi.GetRoutesDetail(isAuthenticated, id);
    response.then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setRouteDetail(response?.data);
        setBinaryRouteImg(response?.data?.image);
      }
    });
  };

  const handlePlusClick = () => {
    setWhichScreen(CONSTANT.GET_DETAIL);
  };

  // function for remove tag in detail
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  useEffect(() => {
    setValue("ROUTES_TYPES", routeId);
  }, [routeId]);

  useEffect(() => {
    if (idForUpdate && routeDetail?.name && isChange) {
      setWhichScreen(CONSTANT.GET_DETAIL);
    }
  }, [idForUpdate, routeDetail, isChange]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.workoutHeader}>
        <div className={styles.partName}>
          <p>3</p>
        </div>

        <h1>
          ROUTE
          <span> (OPTIONAL)</span>
        </h1>
      </div>

      {/* WITHOUT ADD MANUALLY */}
      {whichScreen === CONSTANT.SELECT_THROUGH_OPTION ? (
        <div className={styles.savedWorkoutBox} style={{ marginTop: "-10px" }}>
          <div className={styles.selectWorkout}>
            <RouteCustomSelect
              Label={
                <p>
                  {t("SAVED")} {""} {""}
                  <span>{t("ROUTE")}</span>
                </p>
              }
              defaultValue=""
              defaultKey="Select Saved Route"
              styleName="selectTraning"
              RegisterName={"ROUTES_TYPES"}
              register={register}
              formState={formState}
              onChangeHandler={(e) => {
                handleRoute(e);
                setRouteId(e.target.value);
                handleGetRouteDetail(e.target.value);
                setIsChange(false);
              }}
              arrayData={routeList}
              disabled={routeList?.length === 0}
            ></RouteCustomSelect>

            <div
              className={styles.addBtnBox}
              onClick={() => {
                if (routeId) {
                  handlePlusClick();
                  // setIsAddManually(true);
                }
              }}
              style={{ cursor: "pointer", opacity: routeId ? "" : "25%" }}
            >
              <div className={styles.iconHolder}>
                <FaPlus color="#ff4300" />
              </div>
            </div>

            <img src={SelectImg} alt="SelectImg"></img>
          </div>

          <div className={styles.workoutNoteThat}>
            <p>
              Quickly add in a <span>saved route above,</span> or{" "}
              <span>add manually below</span>
            </p>
          </div>

          <button
            type="button"
            onClick={() => {
              setAddManually(true);
              setWhichScreen(CONSTANT.ADD_MANUALLY);
              setRouteDetail([]);
              setIsAddManually(true);
            }}
          >
            <p>
              {t("ADD")} <span>{t("MANUALLY")}</span>
            </p>
          </button>
        </div>
      ) : whichScreen === CONSTANT.GET_DETAIL ? (
        <div style={{ marginTop: "-10px" }}>
          <div className={styles.getDetailBox}>
            <p className={styles.getDetailBoxPtag}>
              {capitalizeFirstLetter(routeDetail?.name)}
            </p>

            <h1 className={styles.distanceOfRoute}>
              {routeDetail?.distance}
              {routeDetail?.distanceUnit == 1 ? "KM" : "MI"}
            </h1>

            <div classNabackToAddme={styles.devider}></div>
            {routeDetail?.routeLink || routeDetail?.image ? (
              <div className={styles.devider}></div>
            ) : (
              ""
            )}

            <div
              className={styles.textEditorTextRoute}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(routeDetail?.details),
              }}
            ></div>

            <div className={styles.routeInfo}>
              {routeDetail?.routeLink ? (
                <div
                  className={styles.linkBox}
                  onClick={() => {
                    if (routeDetail?.routeLink) {
                      window.open(routeDetail?.routeLink);
                    }
                  }}
                >
                  <div className={styles.linkBoxIcon}>
                    <CiRoute color="#ffffff" size="25" />
                  </div>
                  <p>{t("ROUTE_")}</p>
                  <h1>{t("LINK_")}</h1>
                </div>
              ) : (
                ""
              )}

              {routeDetail?.image ? (
                <div
                  className={styles.linkBox}
                  onClick={() => {
                    if (routeDetail?.image) {
                      window.open(routeDetail?.image);
                    }
                  }}
                >
                  <div className={styles.linkBoxIcon}>
                    <FaMountainSun color="#ffffff" size="25" />
                  </div>
                  <p>{t("ROUTE_")}</p>
                  <h1>{t("INFO_")}</h1>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={styles.cancelDeleteSave}>
            <div className={styles.cancelSave}>
              <button
                onClick={() => {
                  setAddManually(false);
                  setRouteDetail();
                  setWhichScreen(CONSTANT.SELECT_THROUGH_OPTION);
                  setRouteId("");
                  setRouteType("");
                  setValue("ROUTES_TYPES", "");
                  setIsAddManually(false);
                }}
              >
                {t("REMOVE")}
              </button>
            </div>
            <button
              className={styles.saveWorkout}
              type="button"
              onClick={() => {
                setWhichScreen(CONSTANT.ADD_MANUALLY);
                setIsAddManually(true);
              }}
            >
              <p>
                {t("EDIT")} <span>{t("ROUTE")}</span>
              </p>
            </button>
          </div>
        </div>
      ) : whichScreen === CONSTANT.ADD_MANUALLY ? (
        <AddRouteManually
          onClose={() => setAddManually(false)}
          addManually={addManually}
          setAddManually={setAddManually}
          setRouteId={setRouteId}
          setRouteType={setRouteType}
          routeList={routeList}
          setWhichScreen={setWhichScreen}
          handleGetRouteDetail123={handleGetRouteDetail}
          routeDetail={routeDetail}
          setRouteDetail={setRouteDetail}
          binaryRouteImg={binaryRouteImg}
          setBinaryRouteImg={setBinaryRouteImg}
          whichScreen={whichScreen}
          setIsAddManually={setIsAddManually}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default RouteOptional;
