import React, { useEffect, useRef, useState } from "react";
import styles from "./EventListByCalender.module.css";
import CompanyLogo from "../../../Assests/Images/CompanyLogo.png";
import Cross from "../../../Assests/Images/Cross.svg";
import Edit from "../../../Assests/Images/Edit.svg";
import TrainzaLogo from "../../../Assests/Images/TrainzaLogo.svg";
import EventThumbnail from "../../../Assests/Images/EventThumbnail.png";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import { useTranslation } from "react-i18next";
import TrainzaApi from "../../../Helpers/Api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toster from "../../../Common/Toster/Toster";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Loader from "../../../Common/Loader/Loader";
import { REPEATING_DAYS } from "../../../Utils/MockData";
import { formatDate } from "../../../Utils/CommonFunction";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
function SuperAdminEvent({ eventListByAdmin, handleGetEvent, selectedDate }) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  function handleCopyEvent(id) {
    setIsLoading(true);
    TrainzaApi.CopyEvent(isAuthenticated, id).then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("Event copied successfully"), "success");
          // setShowtab(1);
          handleGetEvent(1, selectedDate);
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }

  return (
    <div>
      {isLoading && <Loader />}

      <div className={styles.adviceBox}>
        <p>
          {t("READY_MADE")} <img src={TrainzaLogo} alt="logo"></img>{" "}
          {t("TRAINZA_EVENTS")}
          {""}
        </p>
        <h2>{t("COPY_EVENT")}</h2>
      </div>

      {eventListByAdmin?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className={styles.eventListBoxCopy}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={item.eventImg ? item.eventImg : EventThumbnail}
                  alt="UserImg"
                  className={styles.imageSection}
                ></img>
                <div className={styles.detail}>
                  <p>{item?.title}</p>
                  {item.distance?.length !== 0 ? (
                    <h1>
                      {item.distance?.map((dis, index) => {
                        return (
                          <React.Fragment key={index}>
                            {dis.distance}
                            <span>{t("KM")}</span>{" "}
                            {item.distance?.length - 1 !== index ? "|" : ""}{" "}
                          </React.Fragment>
                        );
                      })}
                    </h1>
                  ) : (
                    <h1>----</h1>
                  )}

                  <h2>
                    {" "}
                    {item.startDate
                      ? formatDate(item.startDate)
                      : REPEATING_DAYS[item.repeatingDays - 1].day}{" "}
                    {item.endDate ? `- ${formatDate(item.endDate)}` : ""}
                  </h2>
                </div>
              </div>
            </div>

            <div className={styles.copySection}>
              <CustomButton
                variant="footerBtnCustom"
                onClick={() => {
                  handleCopyEvent(item.id);
                }}
              >
                <p>
                  {t("COPY")} <span>{t("EVENT")}</span>
                </p>
              </CustomButton>
              <p>{t("COPY_THIS_EVENT_TO_CALENDER")}</p>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default SuperAdminEvent;
