import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./BrandInfo.module.css";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import PhoneInput from "react-phone-input-2";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useForm, useController } from "react-hook-form";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import { useTranslation } from "react-i18next";
import TrainzaApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import CrossImg from "../../../Assests/Images/Cross.svg";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../../Common/Toster/Toster";
import Loader from "../../../Common/Loader/Loader";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import {
  b64toBlob,
  capitalizeFirstLetter,
  cleanData,
  fetchImageAsFile,
  getRandom,
  removePlusSign,
} from "../../../Utils/CommonFunction";
import CropImg from "../../../Common/Crop/CropImg";
import mime from "mime";
import { CONSTANT } from "../../../Utils/Constant";
import { Autocomplete } from "@react-google-maps/api";
import { UserContext } from "../../../Context/UserContext";
import {
  CustomCheckBox,
  CustomInputField,
  CustomPhoneInputField,
} from "../../../ReactBootstrap/CustomInputField/CustomInputField";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import { GoogleMapComp } from "../../../GoogleMap/GoogleMapComp";
import { CustomSelect } from "../../../ReactBootstrap/CustomSelect/CustomSelect";
import { MAP_CENTER } from "../../../Utils/MockData";

function BrandInfo() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      BRAND_DETAIL: "",
    },
  });

  //=== higlight error box===
  const detailRef = useRef(null);

  useEffect(() => {
    if (!formState.errors.BRAND_NAME && formState.errors.BRAND_DETAIL) {
      detailRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formState.errors.BRAND_DETAIL]);

  const fileInputRef = useRef();
  const autocomplete = useRef(null);
  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

  const { t } = useTranslation();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultCountryId = "vbmOpe";

  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState("za");
  const [dialCode, setDialCode] = useState(27);
  const [isLoading, setIsLoading] = useState(true);

  const [whatsappNo, setWhatsappNo] = useState();
  const [whatsappCountryCode, setWhatsappCountryCode] = useState("za");
  const [whatsappDialCode, setWhatsappDialCode] = useState(27);

  const [isPhysicalAddress, setIsPhysicalAddress] = useState(0);
  const [isButtonAdded, setIsButtonAdded] = useState(0);

  const [sourceGeometry, setSourceGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const { setIsAnyChange, saveChanges, setSaveChanges } =
    useContext(UserContext);

  const [previewMembershipImg, setPreviewMembershipImg] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);

  const [countryMeta, setCountryMeta] = useState([]);
  const [stateMeta, setStateMeta] = useState([]);

  const [showCrop, setShowCrop] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("vbmOpe");
  const [selectCountry, setSelectCountry] = useState("");

  const [defaultState, setDefaultState] = useState("");

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const [position, setPosition] = useState(MAP_CENTER);

  const onMarkerDragEnd = (e) => {
    setIsAnyChange(true);
    setPosition({
      lat: e?.latLng?.lat(),
      lng: e?.latLng?.lng(),
    });
  };

  const handleCloseCrop = () => {
    setShowCrop(false);
    fileInputRef.current.value = "";
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setIsCropper(true);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewMembershipImg(fileUrl);
      setImageSrc(fileUrl);
      setIsAnyChange(true);
    } else {
      setPreviewMembershipImg(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const getCountryMetaData = () => {
    const response = TrainzaApi.countryMetaData();
    response.then((res) => {
      setCountryMeta(res?.data?.countryMeta);
    });
  };

  useEffect(() => {
    getCountryMetaData();

    getBrandDetail();
  }, []);

  useEffect(() => {
    getStateMetaData(defaultCountry);
  }, [defaultCountry]);

  useEffect(() => {
    setValue("COUNTRY", defaultCountry);
  }, [countryMeta]);

  useEffect(() => {
    if (selectCountry != "" && selectCountry != defaultCountry) {
      setValue("STATE", "");
    } else {
      setValue("STATE", defaultState);
    }
  }, [stateMeta, defaultState]);

  useEffect(() => {
    if (isCropper == true) {
      setShowCrop(true);
    }
  }, [imageSrc]);

  const getStateMetaData = (id) => {
    const response = TrainzaApi.stateMetaData(id);
    response.then((res) => {
      setStateMeta(res?.data?.stateMeta);
    });
  };

  const getBrandDetail = () => {
    const response = TrainzaApi.brandDetail(isAuthenticated);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        const brandInfo = response?.data?.brandInfo;
        if (brandInfo) {
          if (brandInfo.countryMeta) {
            setDefaultCountry(brandInfo?.countryMeta);
            setValue("COUNTRY", brandInfo?.countryMeta);
            setDefaultState(brandInfo?.stateMeta);
          } else {
            setDefaultCountry(defaultCountryId);
          }

          setValue("BRAND_NAME", brandInfo?.brandName);
          setUserName(brandInfo?.brandName);
          setValue("BRAND_DETAIL", brandInfo?.details);
          if (brandInfo?.phoneDialCode) {
            setDialCode(brandInfo?.phoneDialCode);
          }
          setPhone(brandInfo?.phoneNumber);

          if (brandInfo?.whatsappDialCode) {
            setWhatsappDialCode(brandInfo?.whatsappDialCode);
          }

          setWhatsappNo(brandInfo?.whatsappNumber);

          if (brandInfo?.contactEmail) {
            setValue("CONTACT_EMAIL", brandInfo?.contactEmail);
          }
          if (brandInfo?.webAddress) {
            setValue("WEBSITE_ADDRESS", brandInfo?.webAddress);
          }

          setValue("TOWN_CITY", brandInfo?.town);
          setIsPhysicalAddress(brandInfo?.isPhysicalAddress);
          setValue("PHYSICAL_ADDRESS", brandInfo?.address);
          setValue("GOOGLE_ADDRESS", brandInfo?.googleAddress);
          if (brandInfo?.latitude) {
            setPosition({
              lat: brandInfo?.latitude,
              lng: brandInfo?.longitude,
            });
          } else {
            setPosition(MAP_CENTER);
          }

          setSourceGeometry({
            address: brandInfo?.googleAddress,
            lat: brandInfo?.latitude,
            lng: brandInfo?.longitude,
          });

          setPreviewMembershipImg(brandInfo?.membershipPageImg);
          setImageSrc(brandInfo?.membershipPageImg);
          setIsButtonAdded(brandInfo?.isBtnAdded);
          setValue("BUTTON_LABEL", brandInfo?.btnLbl);
          setValue("BUTTON_Link", brandInfo?.btnLink);
        }
      }
    });
  };

  const fetchImageAsBinary = async (url) => {
    try {
      const imageFile = await fetchImageAsFile(`${url}`, "myImage.jpg");

      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const OnSubmit = async (data) => {
    const PostData = new FormData();
    PostData.append("brandName", cleanData(getValues("BRAND_NAME")));
    PostData.append("details", cleanData(getValues("BRAND_DETAIL")));

    PostData.append("phoneDialCode", phone ? removePlusSign(dialCode) : "");
    PostData.append("phoneCountryCode", phone ? phoneCountryCode : "");
    PostData.append("phoneNumber", cleanData(phone));

    PostData.append(
      "whatsappDialCode",
      whatsappNo ? removePlusSign(whatsappDialCode) : ""
    );
    PostData.append(
      "whatsappCountryCode",
      whatsappNo ? whatsappCountryCode : ""
    );
    PostData.append("whatsappNumber", cleanData(whatsappNo));

    PostData.append("contactEmail", cleanData(getValues("CONTACT_EMAIL")));
    PostData.append("webAddress", cleanData(getValues("WEBSITE_ADDRESS")));
    PostData.append("countryMeta", getValues("COUNTRY"));
    PostData.append("stateMeta", getValues("STATE"));
    PostData.append("town", getValues("TOWN_CITY"));

    if (
      isPhysicalAddress == 0 ||
      isPhysicalAddress == null ||
      isPhysicalAddress == undefined
    ) {
      PostData.append("isPhysicalAddress", 0);
      PostData.append("address", "");
    } else {
      PostData.append("isPhysicalAddress", 1);
      PostData.append("address", getValues("PHYSICAL_ADDRESS"));
    }

    PostData.append("googleAddress", cleanData(sourceGeometry.address));

    PostData.append("latitude", cleanData(sourceGeometry.lat));
    PostData.append("longitude", cleanData(sourceGeometry.lng));

    if (previewMembershipImg) {
      var path = previewMembershipImg;
      const startsWithHtt = path.startsWith("htt");
      if (startsWithHtt) {
        path = `${process.env.REACT_APP_BASE_URL}/proxy?url=${previewMembershipImg}`;
      } else {
        path = previewMembershipImg;
      }
      const imageFile = previewMembershipImg
        ? await fetchImageAsBinary(path)
        : "";
      PostData.append("membershipPageImg", imageFile);
    } else {
      PostData.append("membershipPageImg", "");
    }

    if (isButtonAdded == 0) {
      PostData.append("isBtnAdded", 0);
      PostData.append("btnLbl", "");
      PostData.append("btnLink", "");
    } else {
      PostData.append("isBtnAdded", 1);
      PostData.append("btnLbl", getValues("BUTTON_LABEL"));
      PostData.append("btnLink", getValues("BUTTON_Link"));
    }

    if (
      (phone == undefined || phone.length == 0 || phone?.length > 4) &&
      (whatsappNo == undefined ||
        whatsappNo.length == 0 ||
        whatsappNo?.length > 4)
    ) {
      setIsLoading(true);
      setIsCropper(false);
      TrainzaApi.brandInfo(isAuthenticated, PostData).then((response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/sign-up");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            Toster(t("BRAND_INFO_ADDED"), "success");
            getBrandDetail();
            setIsAnyChange(false);
            // document.getElementById("imageInput").value = "";
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
            setSaveChanges(false);
          } else {
            Toster(t(ResponseCode), "error");
            setSaveChanges(false);
          }
        }
      });
    } else {
      setSaveChanges(false);
    }
  };

  useEffect(() => {
    if (saveChanges) {
      OnSubmit();
    }
  }, [saveModalOpen]);

  const handlePlaceSelected = (place, type) => {
    if (type == "sourceLocation") {
      setIsAnyChange(true);
      setSourceGeometry({
        address: autocomplete?.current?.getPlace()?.formatted_address,
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
      setPosition({
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
      const selectedPlace = autocomplete.current?.getPlace();
      if (selectedPlace) {
        handlePlaceSelected(selectedPlace);
      }
    }
  };

  const fetchLocationName = async (lat, lng) => {
    const geocodingApiKey = placeApiKey; // Use your Google API key here
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${geocodingApiKey}`;

    const response = await fetch(geocodingUrl);
    const data = await response?.json();

    if (data.results && data.results.length > 0) {
      const formattedAddress = data.results[0].formatted_address;
      return formattedAddress;
    } else {
      return null; // No results found
    }
  };

  useEffect(() => {
    const fetchInitialLocationName = async () => {
      const locationName = await fetchLocationName(
        position?.lat,
        position?.lng
      );
      if (locationName) {
        // Set the source geometry with the default coordinates and location name
        setSourceGeometry({
          address: locationName,
          lat: position?.lat,
          lng: position?.lng,
        });
        setValue("GOOGLE_ADDRESS", locationName);
      }
    };

    fetchInitialLocationName();
  }, [position?.lat, position?.lng]);

  const handleCountryChange = (e) => {
    setSelectCountry(e.target.value);
    setValue("COUNTRY", e.target.value);
    setIsAnyChange(true);
    getStateMetaData(e.target.value);
    setValue("STATE", "");
  };

  const handleStateChange = (e) => {
    setValue("STATE", e.target.value);
    setIsAnyChange(true);
  };

  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  useEffect(() => {
    setPhone(phone?.replace(/-/g, ""));
  }, [phone]);
  useEffect(() => {
    setWhatsappNo(whatsappNo?.replace(/-/g, ""));
  }, [whatsappNo]);
  // handle brand text editor
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "BRAND_DETAIL",
    control,
    rules: {
      required: {
        value: true,
        message: t("BRAND_EDITOR_ERROR"),
      },
      validate: (value) => {
        const strippedValue = stripHtmlTags(value);
        return strippedValue !== "" || t("BRAND_EDITOR_ERROR");
      },
    },
  });

  const stripHtmlTags = (input) => input.replace(/(<([^>]+)>)/gi, "").trim();

  // function for handle text editor
  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((el) => {
      el.style.color = ""; // Remove any color styling
    });

    return doc.body.innerHTML;
  };

  const handleChange = (value, delta, source, editor) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );

    if (source === "user") {
      const cleanContent = cleanPastedContent(sanitizedValue);
      onChange(cleanContent);
    } else {
      onChange(sanitizedValue);
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.brandHead}>
        <p>{capitalizeFirstLetter(userName)}</p>
      </div>

      <div className={styles.devider}></div>

      <p className={styles.brandLabel}>
        {t("BRAND")} <span>{t("INFO_")}</span>
      </p>

      <p className={styles.brandDescription}>
        {t("THIS_INFO_WILL_DISPLAYED_IN_APP")}
      </p>

      <div className={styles.devider}></div>
      <Form onSubmit={handleSubmit(OnSubmit)}>
        <CustomInputField
          styleName="inputTextForBrandInfo"
          type="text"
          PlaceHolder={t("ENTER_NAME_OF_BRAND_CLUB")}
          RegisterName={"BRAND_NAME"}
          register={register}
          formState={formState}
          onChange={(e) => {
            if (formState.errors.BRAND_NAME) {
              clearErrors("BRAND_NAME");
            }
            setIsAnyChange(true);
            setUserName(e.target.value);
            setValue("BRAND_NAME", e.target.value);
            handleKeyPress(e);
          }}
        >
          <p>
            {t("BRAND_CLUB_")}
            <span> {t("NAME")} </span>
          </p>
        </CustomInputField>

        <div className={styles.devider}></div>

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              {t("BRAND_CLUB_")} <span>{t("DETAILS")}</span>
            </p>
          </Form.Label>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={t("DETAIL_OF_YOUR_BRAND_CLUB_MEMBERSHIP")}
            className="brandTextEditor"
            ref={ref}
            modules={modules}
            formats={formats}
          />
          <div className="validateError">
            {error && <p className="error-message">{error.message}</p>}
          </div>
        </Form.Group>

        <CustomPhoneInputField
          styleName="inputTextForBrandInfo"
          dialCode={dialCode}
          phone={phone}
          setIsAnyChange={setIsAnyChange}
          setDialCode={setDialCode}
          setPhoneCountryCode={setPhoneCountryCode}
          setPhone={setPhone}
          dropdownIcon="selectCountry"
          errorText="MOBILE_NO_MUST_BE_ATLEAST_5_DIGIT"
          Label={
            <p>
              <span>{t("PHONE_NO")} - </span>
              {t("OPTIONAL_PHONE_NO_BUTTON")}
            </p>
          }
        >
          <p>
            <span>{t("PHONE_NO")} - </span>
            {t("OPTIONAL_PHONE_NO_BUTTON")}
          </p>
        </CustomPhoneInputField>

        <CustomPhoneInputField
          styleName="inputTextForBrandInfo"
          dialCode={whatsappDialCode}
          phone={whatsappNo}
          setIsAnyChange={setIsAnyChange}
          setDialCode={setWhatsappDialCode}
          setPhoneCountryCode={setWhatsappCountryCode}
          setPhone={setWhatsappNo}
          dropdownIcon="selectCountry2"
          errorText="WHATSAPP_NO_MUST_BE_ATLEAST_5_DIGIT"
          Label={
            <p>
              <span>{t("WHATSAPP_NO")} - </span>
              {t("Optional WhatsApp contact button")}
            </p>
          }
        >
          <p>
            <span>{t("WHATSAPP_NO")} -</span> {t("OPTIONAL_WHATSAPP_NO_BUTTON")}
          </p>
        </CustomPhoneInputField>

        <CustomInputField
          styleName="inputTextForBrandInfo"
          type="text"
          PlaceHolder={t("CONTACT_EMAIL_ADDRESS")}
          RegisterName={"CONTACT_EMAIL"}
          register={register}
          formState={formState}
          onChange={() => {
            setIsAnyChange(true);
          }}
        >
          <p>
            <span> {t("CONTACT_EMAIL")} - </span>
            {t("OPTIONAL_CONTACT_EMAIL_ADDRESS")}
          </p>
        </CustomInputField>

        <CustomInputField
          styleName="inputTextForBrandInfo"
          type="text"
          PlaceHolder={t("HTTP")}
          RegisterName={"WEBSITE_ADDRESS"}
          register={register}
          formState={formState}
          onChange={() => {
            setIsAnyChange(true);
          }}
        >
          <p>
            <span> {t("WEBSITE_ADDRESS")} - </span>
            {t("OPTIONAL_WEBSITE_ADDRESS_WITH_BUTTON")}
          </p>
        </CustomInputField>

        <div className={styles.devider}></div>

        <div className={styles.locationRequired}>
          <p>
            {t("LOCATION")} <span>({t("REQUIRED__")})</span>
          </p>
        </div>

        <CustomSelect
          Label={
            <p>
              {t("COUNTRY")} ({t("REQUIRED_")})
            </p>
          }
          styleName="selectForBrand"
          RegisterName={"COUNTRY"}
          register={register}
          formState={formState}
          onChangeHandler={handleCountryChange}
          defaultKey={CONSTANT.DEFAULT_COUNTRY}
          arrayData={countryMeta}
          disabled={false}
          defaultValue="vbmOpe"
        ></CustomSelect>

        <CustomSelect
          Label={
            <p>
              {t("STATE_PRIVINCE")} ({t("REQUIRED_")})
            </p>
          }
          styleName="selectForBrand"
          RegisterName={"STATE"}
          register={register}
          formState={formState}
          onChangeHandler={handleStateChange}
          defaultKey="Select State/Province"
          arrayData={stateMeta}
          disabled={stateMeta?.length === 0}
          defaultValue=""
        ></CustomSelect>

        <CustomInputField
          styleName="selectInput"
          type="text"
          PlaceHolder="Input Your City/Town"
          RegisterName="TOWN_CITY"
          register={register}
          formState={formState}
          onChange={() => {
            setIsAnyChange(true);
          }}
        >
          {" "}
          <p>
            {t("TOWN_CITY")} ({t("REQUIRED_")})
          </p>
        </CustomInputField>

        <div className={styles.devider}></div>

        <div className={styles.physicalAddress}>
          <CustomCheckBox
            checked={isPhysicalAddress}
            styleName={styles.checkBoxArea}
            label={
              <p>
                {t("PHYSICAL_ADDRESS")} <span>({t("OPTIONAL__")})</span>
              </p>
            }
            setIsAnyChange={setIsAnyChange}
            setIsStateChange={setIsPhysicalAddress}
          ></CustomCheckBox>
        </div>

        {isPhysicalAddress ? (
          <CustomInputField
            styleName="inputTextForBrandInfo"
            type="text"
            PlaceHolder={t("ENTER_LOCATION_NAME")}
            RegisterName={"PHYSICAL_ADDRESS"}
            register={register}
            formState={formState}
            onChange={() => {
              setIsAnyChange(true);
            }}
          >
            <p>
              <span style={{ fontWeight: "500" }}>{t("ADDRESS_NAME")} -</span>{" "}
              {t("NAME_OF_LOCATION_REQUIRED")}
            </p>
          </CustomInputField>
        ) : (
          ""
        )}

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              <span style={{ fontWeight: "500" }}>
                {t("GOOGLE_ADDRESS")} -{" "}
              </span>
              {t("INCLUDE_LINK_TO_LOCATION")}
            </p>
          </Form.Label>

          <Autocomplete
            placeApiKey={placeApiKey}
            onLoad={(auto) => {
              autocomplete.current = auto;
            }}
            onPlaceChanged={(place) => {
              handlePlaceSelected(place, "sourceLocation");
            }}
          >
            <input
              type="text"
              placeholder={t("TYPE_ADDRESS_OR_LEAVE_BLANK")}
              {...register("GOOGLE_ADDRESS", ValidationSchema.GOOGLE_ADDRESS)}
              isInvalid={!!formState.errors.GOOGLE_ADDRESS}
              onKeyDown={handleKeyDown}
            />
          </Autocomplete>

          <Form.Control.Feedback type="invalid">
            {t(formState.errors.GOOGLE_ADDRESS?.message)}
          </Form.Control.Feedback>
        </Form.Group>

        {/* GOOGLE MAP */}
        {position ? (
          <GoogleMapComp
            position={position}
            zoom={16}
            setPosition={setPosition}
            onMarkerDragEnd={onMarkerDragEnd}
          ></GoogleMapComp>
        ) : (
          ""
        )}

        <p className={styles.mapDescription}>
          {t("DRAG_PIN_TO_CORRENT_LOCATION")}
        </p>

        {/* =============== */}

        <div className={styles.devider}></div>

        <div className={styles.selectImgSection}>
          <p>{t("MEMBERSHIP_PAGE_IMG")}</p>
          <h1>{t("ADD_OPTIONAL_FEATURE_IMG")}</h1>

          <CustomButton
            variant="footerBtnCustom"
            type="button"
            onClick={handleButtonClick}
          >
            <p>
              {t("SELECT")} <span>{t("IMAGE")}</span>
            </p>
          </CustomButton>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }} 
            accept=".jpg, .jpeg, .gif, .png, .webp" 
            onChange={handleFileChange}
          />
        </div>

        {previewMembershipImg && (
          <div className={styles.previewMembershipImgBox}>
            <img
              src={previewMembershipImg}
              alt="previewMembershipImg"
              className={styles.previewImg}
            ></img>
            <img
              src={CrossImg}
              alt="CrossImg"
              className={styles.removeImg}
              onClick={() => {
                setPreviewMembershipImg(null);
                setImageSrc(null);
                setIsCropper(false);
                fileInputRef.current.value = "";
              }}
            ></img>
          </div>
        )}

        <div className={styles.devider}></div>
        <div className={styles.physicalAddress} style={{ display: "block" }}>
          <CustomCheckBox
            checked={isButtonAdded}
            styleName={styles.addButton}
            label={t("ADD_BUTTON")}
            setIsAnyChange={setIsAnyChange}
            setIsStateChange={setIsButtonAdded}
          ></CustomCheckBox>
          <p>{t("ADD_OPTIONAL_BUTTON_TO_LINK")}</p>
        </div>

        {isButtonAdded ? (
          <React.Fragment>
            <CustomInputField
              styleName="inputTextForBrandInfo"
              type="text"
              PlaceHolder={t("ADD_BUTTON_LABEL")}
              RegisterName={"BUTTON_LABEL"}
              register={register}
              formState={formState}
              onChange={() => {
                setIsAnyChange(true);
              }}
            >
              <p>
                <span style={{ fontWeight: "500" }}>{t("BUTTON_LABEL")}</span>
              </p>
            </CustomInputField>

            <CustomInputField
              styleName="inputTextForBrandInfo"
              type="text"
              PlaceHolder={t("ADD_BUTTON_LINK")}
              RegisterName={"BUTTON_Link"}
              register={register}
              formState={formState}
              onChange={() => {
                setIsAnyChange(true);
              }}
            >
              <p>
                <span style={{ fontWeight: "500" }}>{t("LINK")}</span>
              </p>
            </CustomInputField>
          </React.Fragment>
        ) : (
          ""
        )}
        <div className={styles.saveChanges}>
          <CustomButton variant="footerBtnCustom" type="submit">
            <p>
              {t("Save")} <span>{t("Changes")}</span>
            </p>
          </CustomButton>{" "}
        </div>
      </Form>

      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewMembershipImg}
        aspectRatio={CONSTANT.ASPECT_RATIO_IN_BRAND_INFO}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
      />
    </div>
  );
}

export default BrandInfo;
