import React, { useEffect, useState } from "react";
import styles from "./EventDetailLayout.module.css";
import { useTranslation } from "react-i18next";
import CustomTabButton from "../../../../ReactBootstrap/CustomTabButton";
import Gallery from "../Gallery/EventGallery";
import { useLocation } from "react-router-dom";
import Poll from "../Poll/Poll";
import EventResultList from "../Result/EventResultList";
import EventDetailPage from "../EventDetailPage/EventDetailPage";
import AddEvent from "../../AddEvent/AddEvent";

function EventDetailLayout() {
  const { t } = useTranslation();

  const location = useLocation();

  const eventId = location?.state?.event_id;
  const eventTitle = location?.state?.event_title;
  const eventImage = location?.state?.event_img;

  const is_update = location?.state?.is_update;

  const [addOrRead, setAddOrRead] = useState(false);

  const [showtab, setShowtab] = useState(1);

  const [isResultAdded, setIsResultAdded] = useState();

  useEffect(() => {
    if (is_update) {
      setAddOrRead(true);
    }
  }, []);

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 2
          ? t("RACES_EVENT_GALLERY")
          : showtab == 3
          ? t("RACES_EVENTS_POLL")
          : showtab == 4
          ? t("RACES_EVENTS_RESULTS")
          : t("RACES_EVENTS_DETAILS")}
      </p>

      <div className={styles.devider}></div>

      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <CustomTabButton
            onClick={() => setShowtab(1)}
            variant={showtab === 1 ? "primary" : "secondary"}
          >
            {t("DETAILS")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(2)}
            variant={showtab === 2 ? "primary" : "secondary"}
          >
            {t("GALLERY")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(3)}
            variant={showtab === 3 ? "primary" : "secondary"}
          >
            {t("POLL")}
          </CustomTabButton>

          <CustomTabButton
            onClick={() => setShowtab(4)}
            variant={showtab === 4 ? "primary" : "secondary"}
          >
            {t("RESULTS_")}
          </CustomTabButton>
        </div>
      </div>

      <div className={styles.devider}></div>

      {showtab === 1 ? (
        <AddEvent
          eventId={eventId}
          addOrRead={addOrRead}
          setAddOrRead={setAddOrRead}
          eventTitle={eventTitle}
          setIsResultAddedForResult={setIsResultAdded}
        />
      ) : showtab === 2 ? (
        <Gallery
          eventId={eventId}
          eventTitle={eventTitle}
          eventImage={eventImage}
        />
      ) : showtab === 3 ? (
        <Poll
          eventId={eventId}
          eventTitle={eventTitle}
          eventImage={eventImage}
        />
      ) : showtab === 4 ? (
        <EventResultList
          eventId={eventId}
          eventTitle={eventTitle}
          eventImage={eventImage}
          setShowtab={setShowtab}
          isResultAdded={isResultAdded}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default EventDetailLayout;
