import React, { useEffect, useState } from "react";
import styles from "./MemberDetail.module.css";
import PersonImg from "../../../Assests/Images/Person.svg";
import UserImg from "../../../Assests/Images/ProfileDemo.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import TrainzaApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../../Common/Toster/Toster";
import { useNavigate } from "react-router-dom";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Loader from "../../../Common/Loader/Loader";
import {
  capitalizeFirstLetter,
  formatDate,
  formatPhoneNumber,
  manipulatePlus,
} from "../../../Utils/CommonFunction";
import { CONSTANT } from "../../../Utils/Constant";
function MemberDetail({
  member_id,
  setIsMemberUpdate,
  setUserName,
  setProfileImg,
  setIsMemberValid,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [memberDetail, setMemberDetail] = useState();
  const [personalBest, setPersonalBest] = useState([]);
  const [trainingGrp, setTrainingGrp] = useState([]);
  const [memberMaping, setMemberMaping] = useState([]);

  const handleGetMemberDetail = () => {
    const response = TrainzaApi.GetMemberDetail(isAuthenticated, member_id);

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (
          response?.data?.code == STATUS_CODE.INVALID_MEMBER_ID ||
          response?.data?.clubMemberMapping?.length < 1
        ) {
          setIsMemberValid(false);
        } else if (
          response?.data?.code == STATUS_CODE.MEMBERSHIP_NO_LONGER_EXIST
        ) {
          Toster(t("MEMBERSHIP_NO_LONGER_EXIST"), "error");
          navigate("/members");
        } else {
          setMemberDetail(response?.data?.memberInfo);
          setProfileImg(response?.data?.memberInfo?.profilePicture);
          setPersonalBest(response?.data?.personalBests);
          setTrainingGrp(response?.data?.trainingGroupsData);
          setMemberMaping(response?.data?.clubMemberMapping);

          setUserName(
            `${response?.data?.memberInfo?.firstName} ${response?.data?.memberInfo?.lastName}`
          );
        }
      }
    });
  };

  useEffect(() => {
    handleGetMemberDetail();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className={styles.detailHeading}>
            <img
              src={
                memberDetail?.profilePicture
                  ? memberDetail?.profilePicture
                  : PersonImg
              }
              alt="personImg"
            ></img>
            <p>
              {capitalizeFirstLetter(memberDetail?.firstName)}{" "}
              {capitalizeFirstLetter(
                memberDetail?.lastName ? memberDetail?.lastName : ""
              )}
            </p>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.detailSubHeading}>
            <p>
              Member <span>Details</span>
            </p>
          </div>

          <div className={styles.info}>
            <p>{t("FIRST_NAME")}</p>
            <h1>{capitalizeFirstLetter(memberDetail?.firstName)}</h1>
          </div>

          <div className={styles.info}>
            <p>{t("SURNAME_LAST_NAME")}</p>
            <h1>
              {capitalizeFirstLetter(
                memberDetail?.lastName ? memberDetail?.lastName : ""
              )}
            </h1>
          </div>

          <div className={styles.info}>
            <p>{t("EMAIL_PUBLIC")}</p>
            <h1>{memberDetail?.email}</h1>
          </div>
          <div className={styles.info}>
            <p>{t("CONTACT_NO")}</p>
            <h1>
              {memberDetail?.phoneNumber
                ? `${manipulatePlus(
                    memberDetail?.phoneDialCode
                  )} - ${formatPhoneNumber(memberDetail?.phoneNumber)}`
                : t("N/A")}
            </h1>
          </div>

          <div className={styles.info}>
            <p>{t("DOB_REQUIRED")}</p>
            <h1>
              {memberDetail?.dateOfBirth
                ? formatDate(memberDetail?.dateOfBirth)
                : t("N/A")}
            </h1>
          </div>

          <div className={styles.info}>
            <p>{t("GENDER_REQUIRED")}</p>
            <h1>
              {memberDetail?.gender === 1
                ? "Male"
                : memberDetail?.gender === 2
                ? "Female"
                : "N/A"}
            </h1>
          </div>

          <div className={styles.info}>
            <p>{t("HEIGHT_NOT_PUBLIC_FOR_COACH")}</p>
            <h1>
              {memberDetail?.heightDescription
                ? memberDetail?.heightDescription
                : t("N/A")}
            </h1>
          </div>

          <div className={styles.info}>
            <p>{t("WEIGHT_NOT_PUBLIC_FOR_COACH")}</p>
            <h1>
              {memberDetail?.weightDescription
                ? memberDetail?.weightDescription
                : t("N/A")}
            </h1>
          </div>

          <div className={styles.info}>
            <p>{t("COUNTRY")}</p>
            <h1>{memberDetail?.country}</h1>
          </div>

          <div className={styles.info}>
            <p>{t("STATE_PRIVINCE")}</p>
            <h1>{memberDetail?.state}</h1>
          </div>

          <div className={styles.info}>
            <p>{t("TOWN_CITY")}</p>
            <h1>{memberDetail?.town}</h1>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.detailSubHeading}>
            <p>
              {t("PROFILE")} <span>{t("PICTURE")}</span>
            </p>
          </div>

          <div className={styles.profilePic}>
            <img
              src={
                memberDetail?.profilePicture
                  ? memberDetail?.profilePicture
                  : UserImg
              }
              alt="UserImg"
            ></img>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.detailSubHeading}>
            <p>
              {t("PERSONAL")} <span>{t("BESTS")}</span>
            </p>
          </div>

          {personalBest?.map((item, index) => {
            return (
              <div className={styles.personalBestBox} key={index}>
                <p>
                  {item?.distance}
                  {t("KM")} <span>{item?.bestTime}</span>
                </p>
              </div>
            );
          })}
          {personalBest?.length == 0 && (
            <div className={styles.info}>
              <h1>{t("N/A")}</h1>
            </div>
          )}

          <div className={styles.devider}></div>

          <div className={styles.detailSubHeading}>
            <p>
              {t("TRAINING")} <span>{t("GROUPS_")}</span>
            </p>
          </div>

          {trainingGrp?.map((item, index) => {
            return (
              <div className="acceptTerms" key={index}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={true}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                    style={{
                      fontFamily: "Manrope",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#000000",
                    }}
                  >
                    {item?.name}
                  </label>
                </div>
              </div>
            );
          })}
          {trainingGrp?.length == 0 && (
            <div className={styles.info}>
              <h1>{t("N/A")}</h1>
            </div>
          )}

          <div className={styles.devider}></div>

          <div className={styles.detailSubHeading}>
            <p>
              {t("MEMBERSHIP")} <span>{t("INFO")}</span>
            </p>
          </div>

          <div className={styles.info}>
            <p>{t("MEMBERSHIP_VALID_UNTIL")}</p>
            <h1>{formatDate(memberMaping[0]?.membershipValidUntil)}</h1>
          </div>

          <div className={styles.info}>
            <h1>{t("ACTIVE_MEMBERSHIP")}</h1>
            <h1>
              {memberMaping[0]?.isMembershipActive === CONSTANT.ACTIVE
                ? "Active"
                : "Inactive"}{" "}
            </h1>
          </div>

          <div className={styles.info}>
            <h1>{t("CAN_VIEW_OTHER_CLUB_MEMBER")}</h1>
            <h1>
              {" "}
              {memberMaping[0]?.canViewOtherMembers === CONSTANT.ACTIVE
                ? "Active"
                : "Inactive"}{" "}
            </h1>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.editSection}>
            <button type="button" onClick={() => setIsMemberUpdate(1)}>
              {t("EDIT")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MemberDetail;
