import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import styles from "./CustomInputField.module.css";

import PhoneInput from "react-phone-input-2";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MASKING } from "../../Utils/PhoneMasking";

export const CustomInputField = ({
  styleName,
  type,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  children,
  onChange,
  icon,
  as,
  clearErrors,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{children}</Form.Label>
      <Form.Control
        as={as}
        type={type}
        placeholder={PlaceHolder}
        {...register(RegisterName, {
          ...ValidationSchema[RegisterName],
          onChange: onChange,
        })}
        isInvalid={!!formState.errors[RegisterName]}
        // onChange={onChange}
        autoComplete="off"
      />

      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
      {icon}
    </Form.Group>
  );
};

export const CustomPhoneInputField = ({
  styleName,
  dialCode,
  phone,
  setIsAnyChange,
  setDialCode,
  setPhoneCountryCode,
  setPhone,
  dropdownIcon,
  errorText,
  Label,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <PhoneInput
        country="za"
        className="brandPhoneInput"
        countryCodeEditable={false}
        defaultMask={MASKING[dialCode]}
        value={`${dialCode} ${phone}`}
        onChange={(value, country) => {
          setIsAnyChange(true);
          let dialCode = country.dialCode;
          let phone = value.slice(dialCode.length, value.length);
          setDialCode(dialCode);
          setPhoneCountryCode(country.countryCode);
          setPhone(phone);
        }}

        inputExtraProps={{
          style: {
            position: "relative",
          },
        }}
        // dropdownClass={styles.customDropdown}
        dropdownClass={
          dropdownIcon === "selectCountry2"
            ? styles.customDropdownWhatsApp
            : styles.customDropdown
        }
      />

      <div className="validateError">
        {phone?.length > 0 && phone?.length <= 4 && t(errorText)}
      </div>

      <div className={styles[dropdownIcon]}>
        <RiArrowDropDownLine color="#8B8A8A" size="30" />
      </div>
    </Form.Group>
  );
};

export const CustomCheckBox = ({
  checked,
  styleName,
  label,
  setIsAnyChange,
  setIsStateChange,
}) => {
  return (
    <Form.Check
      checked={checked}
      className={styleName}
      type={"checkbox"}
      label={label}
      onChange={() => {
        setIsAnyChange(true);
        setIsStateChange(!checked);
      }}
    />
  );
};

export const TraningCustomCheckBox = ({
  checked,
  styleName,
  label,
  setIsStateChange,
}) => {
  return (
    <Form.Check
      checked={checked}
      className={styles[styleName]}
      type={"checkbox"}
      label={label}
      onChange={() => {
        setIsStateChange(!checked);
      }}
    />
  );
};

export const CustomColorInputField = ({
  RegisterName,
  register,
  formState,
  onChange,
}) => {
  return (
    <div className={styles.inputBox}>
      <input
        type="text"
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChange}
      ></input>
    </div>
  );
};

export const CustomPasswordInputField = ({
  styleName,
  Label,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  onChange,
  isVisible,
  setIsVisible,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{Label}</Form.Label>
      <Form.Control
        type={isVisible ? "text" : "password"}
        placeholder={PlaceHolder}
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChange}
      />
      <Form.Control.Feedback type="invalid">
        {t(formState.errors.PASSWORD?.message)}
      </Form.Control.Feedback>
      {isVisible ? (
        <FaEye
          color="#727272"
          onClick={() => {
            setIsVisible(false);
          }}
        />
      ) : (
        <FaEyeSlash
          color="#727272"
          onClick={() => {
            setIsVisible(true);
          }}
        />
      )}
    </Form.Group>
  );
};

export const CustomPasswordInputFieldForSignUp = ({
  styleName,
  Label,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  onChange,
  icon,
  isVisible,
  setIsVisible,
  lockIconStyle,
  position,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group className={styles[styleName]}>
      {Label && <Form.Label>{Label}</Form.Label>}

      {icon}

      <Form.Control
        type={isVisible ? "text" : "password"}
        placeholder={PlaceHolder}
        autoFocus={true}
        {...register(RegisterName, ValidationSchema[RegisterName])}
        isInvalid={!!formState.errors[RegisterName]}
        onChange={onChange}
      />
      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>

      <div className={styles[lockIconStyle]}>
        {isVisible ? (
          <FaEye
            color="#727272"
            style={{ top: position }}
            onClick={() => setIsVisible(!isVisible)}
          />
        ) : (
          <FaEyeSlash
            color="#727272"
            style={{ top: position }}
            onClick={() => setIsVisible(!isVisible)}
          />
        )}
      </div>
    </Form.Group>
  );
};


export const CustomInputFieldLogbook = ({
  styleName,
  type,
  PlaceHolder,
  RegisterName,
  register,
  formState,
  children,
  onChange,
  icon,
  as,
  clearErrors,
  onKeyDown
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={styles[styleName]}>
      <Form.Label>{children}</Form.Label>
      <Form.Control
        as={as}
        type={type}
        placeholder={PlaceHolder}
        {...register(RegisterName, {
          ...ValidationSchema[RegisterName],
          // onChange: onChange,
        })}
        onChange={onChange}
        onKeyDown={onKeyDown} 
        isInvalid={!!formState.errors[RegisterName]}
        // onChange={onChange}
        autoComplete="off"
      />

      <Form.Control.Feedback type="invalid">
        {t(formState.errors[RegisterName]?.message)}
      </Form.Control.Feedback>
      {icon}
    </Form.Group>
  );
};

