import React from "react";
import styles from "./NewsDetail.module.css";
import { capitalizeFirstLetter } from "../../../../Utils/CommonFunction";
import AddNews from "../../AddNews/AddNews";
function NewsDetail({ eventId, eventTitle, eventImage }) {
  return (
    <div>
      <div className={styles.detailHeading}>
        <p>{capitalizeFirstLetter(eventTitle)}</p>
      </div>

      <AddNews eventId={eventId} />
    </div>
  );
}

export default NewsDetail;
