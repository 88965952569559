import React, { useEffect, useState } from "react";
import styles from "./Workout.module.css";
import CrossImg from "../../../Assests/Images/Cross.svg";
import EditImg from "../../../Assests/Images/Edit.svg";
import TrainzaApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Common/Toster/Toster";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import DeleteWorkoutTypes from "../TrainingTab/DeleteWorkoutType";
import Loader from "../../../Common/Loader/Loader";
import { CONSTANT } from "../../../Utils/Constant";

function Workout({ searchKeyword, setShowAdd, setWorkOutId }) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [workoutList, setWorkoutList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteWorkout, setShowDeleteWorkout] = useState(false);
  const [workoutListId, setWorkoutListId] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const handleWorkoutList = (page, search, skipPaging = 0) => {
    const response = TrainzaApi.getWorkoutList(
      isAuthenticated,
      page,
      search,
      (skipPaging = skipPaging)
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (page === 1) {
          setWorkoutList(response?.data?.workoutList);
        } else {
          setWorkoutList((prevList) => [
            ...prevList,
            ...response?.data?.workoutList,
          ]);
        }
        setPageNo(page + 1);
        setIsLoadMore(response?.data?.loadMore);
        setTotalCount(response?.data?.count);
      }
    });
  };

  useEffect(() => {
    if (searchKeyword) {
      setPageNo(1);
      handleWorkoutList(1, searchKeyword, 0);
    } else {
      handleWorkoutList(1, "", 0);
    }
  }, [searchKeyword]);

  const handleDeleteWorkout = () => {
    const response = TrainzaApi.deleteWorkoutList(
      isAuthenticated,
      workoutListId
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code == STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code == STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("WORKOUT_DELETE_SUCCESSFULLY"), "success");
        // const updatedList = workoutList.filter(
        //   (item) => item.id !== workoutListId
        // );
        // setWorkoutList(updatedList);
        handleWorkoutList(1, "", 0);
        setShowDeleteWorkout(false);
      } else if (response?.data.code == STATUS_CODE.VALIDATION_ERROR) {
        Toster(
          t(
            "THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDeleteWorkout(false);
      } else if (
        response?.data.code ==
        STATUS_CODE.THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION
      ) {
        Toster(
          t(
            "THE_WORKOUT_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDeleteWorkout(false);
      } else {
        setShowDeleteWorkout(false);
      }
    });
  };

  const handleWorkoutTypeSelect = (id) => {
    setWorkoutListId(id);
  };

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && searchKeyword && workoutList.length === 0 && (
        <div className={styles.noDataFound}>
          <p>
            {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
          </p>
        </div>
      )}
      {workoutList &&
        workoutList?.map((item, index) => {
          const backgroundColor = item.status === 1 ? "#ffffff" : "#8080801c";
          return (
            <div
              className={styles.workoutSection}
              key={index}
              style={{ background: backgroundColor }}
              onClick={() => handleWorkoutTypeSelect(item.id)}
            >
              <p>{item.title}</p>
              <div className={styles.iconHolder}>
                <img
                  src={CrossImg}
                  alt="CrossImg"
                  onClick={() => setShowDeleteWorkout(true)}
                ></img>
                <img
                  src={EditImg}
                  alt="EditImg"
                  onClick={() => {
                    setShowAdd(true);
                    setWorkOutId(item.id);
                  }}
                ></img>
              </div>
            </div>
          );
        })}

      {!isLoading && !searchKeyword && workoutList?.length === 0 && (
        <div className={styles.noRecordFound}>
          <p>
            {t("NO_DATA_FOUND")} -{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowAdd(true);
                setWorkOutId("");
              }}
            >
              {" "}
              {t("ADD_WORKOUT")}{" "}
            </span>
          </p>
        </div>
      )}

      <div className={styles.btnBox}>
        <CustomButton
          variant="footerBtnCustom"
          onClick={() => {
            if (isLoadMore) {
              handleWorkoutList(pageNo, searchKeyword);
            }
          }}
          opacity={isLoadMore ? "" : CONSTANT.OPACITY_RATIO}
        >
          <p>
            {t("LOAD")} <span>{t("MORE")}</span>
          </p>
        </CustomButton>

        <p>
          {totalCount}{" "}
          <span>
            {workoutList?.length > 1 ? t("Saved Workouts") : t("Saved Workout")}{" "}
          </span>
        </p>
        <button
          onClick={() => {
            setShowAdd(true);
            setWorkOutId("");
          }}
        >
          <p>
            {t("ADD")} <span>{t("WORKOUT")}</span>
          </p>
        </button>
      </div>
      <DeleteWorkoutTypes
        show={showDeleteWorkout}
        setShowDelete={setShowDeleteWorkout}
        handleWorkoutDelete={handleDeleteWorkout}
      />
    </div>
  );
}

export default Workout;
